/* eslint-disable no-unused-vars */
import React from 'react';
import {
  ComposedChart,
  Line,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  Cell,
  LabelList,
} from 'recharts';

interface ChartComposedProps {
  isMulti?: boolean;
  data: any[];
  source?: any;
  brandcount?: number;
}

export default function DataComposedChart(props: ChartComposedProps) {
  const { isMulti = false, data, source, brandcount } = props;

  function formatNumberWithEllipsis(number: any) {
    const numberStr = number.toString();
    if (numberStr.length > 8) {
      return numberStr.substring(0, 8) + '...';
    }
    return numberStr;
  }

  const maxValue = Math.max(...data.map((item) => item.value || 0));

  return data && data.length > 0 ? (
    <ComposedChart
      width={550}
      height={280}
      data={data}
      margin={{
        top: 20,
        right: 20,
        bottom: 40,
        left: 0,
      }}
    >
      <XAxis
        dataKey="name"
        scale="band"
        tickFormatter={formatNumberWithEllipsis}
        tick={{ fill: '#4B465C', fontSize: 12, textAnchor: 'middle' }}
      />
      <YAxis
        domain={[0, 100]}
        ticks={[0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100]}
        dataKey="percentage"
        tickFormatter={(value) => `${value}%`}
        axisLine={{ stroke: 'transparent' }}
      />
      <Legend />
      <Tooltip
        content={({ active, payload }) => {
          if (active && payload && payload.length) {
            const data = payload[0].payload;
            return (
              <div
                style={{
                  backgroundColor: '#fff',
                  padding: '8px',
                  border: '1px solid #ccc',
                }}
              >
                <p
                  style={{ margin: 0, fontWeight: 'bold' }}
                >{`Sale: ${data.value}`}</p>
              </div>
            );
          }
          return null;
        }}
      />
      <Bar dataKey="percentage" barSize={22}>
        {data.map((entry, index) => (
          <Cell
            key={`cell-${index}`}
            fill={entry.value == maxValue ? '#F9AB01' : '#4973A4'}
          />
        ))}
        <LabelList
          dataKey="percentage"
          position="top"
          formatter={(value: number) => `${value.toFixed(2)}%`}
          offset={20}
          fill="#4B465C"
        />
      </Bar>
      <Line
        type="monotone"
        dataKey="percentage"
        stroke="#F9AB01"
        strokeWidth={3}
      />
    </ComposedChart>
  ) : (
    <div className="no-data-graph">No data to display</div>
  );
}
