/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
// import { useNavigate } from 'react-router-dom';
// import CategoryActions from '../../redux/middleware/category';
import useClickListener from '../../helper/outsideClickListener';
// import ResponseDialog from '../ResopnseDialog';
import { Input, Spin } from 'antd';
// import CategoryModal from '../categoryModal';
// import Utils from '../../redux/utils';
import './style.scss';
import { useSelector } from 'react-redux';
import VariationActions from '../../redux/middleware/variation';
import VariationCreateModal from '../variationCreateModal';
import { classicNameResolver } from 'typescript';

type Props = {
  text: string;
  handleChangeText: any;
  blocked?: boolean;
  variationList: any[];
  fetchVariationType?: any;
  className?: string;
  update?: boolean;
  blockedForEdit?: boolean;
  selectedVariationRecipe?: string;
  selectedVariationName?: string;
};

const VariationDropown = (props: Props) => {
  let {
    blocked,
    variationList,
    fetchVariationType,
    className,
    update,
    blockedForEdit,
    selectedVariationRecipe,
    selectedVariationName,
  } = props;
  const [showListViewOne, setShowListViewOne] = useState(false);
  const [variationData, setVariationData] = useState<any>(variationList);
  const { ref, isComponentVisible, setIsComponentVisible } =
    useClickListener(true);
  const [open, setOpen] = useState(false);
  const [loader] = useState(false);
  const [searchResults, setSearchResults] = useState<any[]>(variationList);
  // const [parentCategory] = useState<any>();
  const [variations, setVariations] = useState<any[]>([]);
  const [searchQuery, setSearchQuery] = useState<any>(props?.text);
  const { currentBrand } = useSelector((state: any) => state.Auth);
  const fetchVariations = async () => {
    // const company = await Utils.getCurrentCompany();

    const VariationType: any = await VariationActions.getVariationType();

    setVariations(VariationType.data.items);
  };

  useEffect(() => {
    fetchVariations();
  }, [currentBrand]);

  useEffect(() => {
    setSearchResults(variationList);
    setVariationData(variationList);
  }, [variationList]);

  useEffect(() => {
    if (props?.text) setSearchQuery(props?.text);
  }, [props?.text]);

  const handleClick = () => {
    setIsComponentVisible(true);
    setShowListViewOne(true);
  };

  const handleChange = (e: any) => {
    // props?.handleChangeText(e.target.value);
    setSearchQuery(e.target.value);
    let results: any[];
    if (e.target.value !== '') {
      setShowListViewOne(false);

      results = variationData.filter((item: any) => {
        return item?.name
          ?.toLowerCase()
          .includes(e.target.value?.toString().toLowerCase());
      });
      setSearchResults(results);
    } else {
      setShowListViewOne(true);
      setSearchResults([]);
      props?.handleChangeText('');
    }
  };

  // const handleCreateCategory = async (text: any) => {
  //   const company = await Utils.getCurrentCompany();
  //   const brand = await Utils.getCurrentBrand();
  //   const params = {
  //     company_id: company?.id,
  //     parent_category_id: parentCategory,
  //     sub_category: text,
  //     brand_id: brand?.brand_id,
  //   };
  //   const data = await MenuCategoryActions.addSubCategories(params);
  //   if (data?.success) {
  //     setShowListViewOne(true);
  //     fetchVariations();
  //   }
  // };
  const handlemodal = (toggle: boolean, data: any) => {
    setOpen(toggle);
    handleSelectOption(data);
    fetchVariationType();
  };
  const handleSelectOption = (ingredient: any) => {
    props?.handleChangeText(ingredient);
    setSearchQuery(ingredient?.sub_category);
    setIsComponentVisible(false);
  };

  return (
    <div className="sub-wizard-form-group" ref={ref}>
      {open ? (
        <VariationCreateModal
          handleDialog={handlemodal}
          open={open}
          name={searchQuery}
          list={variations}
        />
      ) : null}
      <Input
        type="text"
        placeholder="Input Variation here"
        onClick={handleClick}
        onChange={(e) => handleChange(e)}
        disabled={blocked || (update && blockedForEdit)}
        value={
          update && blockedForEdit
            ? selectedVariationName || searchQuery
            : searchQuery
        }
      />
      {isComponentVisible ? (
        <ul className="variation-dropdown-list">
          {showListViewOne ? (
            <li>
              <ul className="subRecipe-subMenu">
                <li>
                  <h4 className="subRecipe-dropdownPlaceholder">
                    Select an option or create one
                  </h4>
                </li>
                <Spin spinning={loader} size="large">
                  {variationData?.map((category: any, index: number) => {
                    return (
                      <li
                        key={index}
                        onClick={() => handleSelectOption(category)}
                      >
                        <p>{category?.name} </p>
                      </li>
                    );
                  })}
                </Spin>
              </ul>
            </li>
          ) : (
            <li>
              <ul className="subMenu">
                <li className="subRecipe-dropdown-create-category">
                  <button
                    onClick={() => {
                      // handleCreateCategory(searchQuery);
                      setOpen(true);
                    }}
                  >
                    {`Create ${searchQuery} to variation`}
                  </button>
                </li>
                {searchResults?.map((searchResult: any, index) => {
                  return (
                    <li
                      key={index}
                      onClick={() => handleSelectOption(searchResult)}
                    >
                      <p>{searchResult?.name}</p>
                    </li>
                  );
                })}
              </ul>
            </li>
          )}
        </ul>
      ) : null}
    </div>
  );
};

export default VariationDropown;
