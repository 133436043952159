/* eslint-disable no-unused-vars */
import React from 'react';
import { Chart, registerables } from 'chart.js';
import { Scatter } from 'react-chartjs-2';

Chart.register(...registerables);

interface MenuItem {
  name: string;
  value: number;
  quadrant: number;
}

interface MenuEngineeringChartProps {
  data: MenuItem[];
  currency?: string;
}

const ScatterChart: React.FC<MenuEngineeringChartProps> = ({
  data,
  currency,
}) => {
  const maxValue = Math.max(...data.map((item) => item.value)) * 1.1;

  const chartData = {
    datasets: [
      {
        label: 'Star',
        data: data
          .filter((item) => item.quadrant === 1)
          .map((item) => ({ x: item.value, y: item.value, name: item.name })),
        backgroundColor: '#F9AB01',
      },
      {
        label: 'Puzzle',
        data: data
          .filter((item) => item.quadrant === 2)
          .map((item) => ({ x: item.value, y: item.value, name: item.name })),
        backgroundColor: '#4973A4',
      },
      {
        label: 'Plow Horse',
        data: data
          .filter((item) => item.quadrant === 3)
          .map((item) => ({ x: item.value, y: item.value, name: item.name })),
        backgroundColor: '#D6D6D6',
      },
      {
        label: 'Dog',
        data: data
          .filter((item) => item.quadrant === 4)
          .map((item) => ({ x: item.value, y: item.value, name: item.name })),
        backgroundColor: '#000000',
      },
    ],
  };

  const options = {
    scales: {
      x: {
        type: 'linear' as const,
        position: 'bottom' as const,
        title: {
          display: true,
          color: '#4B465C',
          text: 'Low Popularity',
        },
        ticks: {
          color: '#4B465C',
        },
        min: 0,
        max: maxValue,
      },
      y: {
        type: 'linear' as const,
        title: {
          display: true,
          color: '#4B465C',
          text: 'Low Profit',
        },
        ticks: {
          color: '#4B465C',
        },
        min: 0,
        max: maxValue,
      },
    },
    plugins: {
      tooltip: {
        backgroundColor: '#FFFFFF',
        bodyColor: '#000000',
        titleColor: '#000000',
        bodyFont: {
          size: 13,
        },
        callbacks: {
          label: (context: any) => {
            const data = context.dataset.data[context.dataIndex];
            return [`${data.name}`, `Profit - ${data.x} ${currency}`];
          },
        },
      },
    },
  };

  return (
    <div style={{ height: '550px' }}>
      <Scatter data={chartData} options={options} />
    </div>
  );
};

export default ScatterChart;
