/* eslint-disable no-unused-vars */
import React from 'react';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  LabelList,
  Cell,
} from 'recharts';

interface ChartBarVerticalProps {
  dataKey1?: string | any;
  dataKey2?: string | any;
  data: any[];
}

const ChartBarVertical = (props: ChartBarVerticalProps) => {
  const { data, dataKey1, dataKey2 } = props;

  function formatNumberWithEllipsis(number: any) {
    const numberStr = number.toString();
    if (numberStr.length > 16) {
      return numberStr.substring(0, 16) + '...';
    }
    return numberStr;
  }

  // const CustomLabel = (props: any) => {
  //   const { x, y, width, height, value } = props;
  //   if (value === 0) return null;

  //   const offsetX = width < 20 ? -10 : width / 2;
  //   const offsetY = height < 20 ? -10 : height / 2;
  //   return (
  //     <text
  //       x={x + offsetX}
  //       y={y + offsetY}
  //       fill="grey"
  //       textAnchor="middle"
  //       dominantBaseline="middle"
  //     >
  //       {value}
  //     </text>
  //   );
  // };

  const filteredData = data
    .filter((entry) => entry.recipe_name !== null)
    .map((entry) => ({
      recipe_name: entry.recipe_name,
      prep_time: Number(entry.prep_time),
    }));

  const maxPrepTime = Math.max(...filteredData.map((d) => d.prep_time));

  return filteredData && filteredData.length > 0 ? (
    <BarChart
      width={530}
      height={210}
      data={filteredData}
      layout="vertical"
      margin={{ top: 5, right: 30, left: 0, bottom: 5 }}
    >
      <XAxis type="number" dataKey="prep_time" tick={{ fill: '#4B465C' }} />
      <YAxis
        type="category"
        dataKey="recipe_name"
        tickFormatter={formatNumberWithEllipsis}
        tick={{
          fill: '#4B465C',
          fontSize: 12,
        }}
        stroke="transparent"
        width={140}
      />
      <Tooltip formatter={(value) => [`${value} min`, 'Prep Time']} />
      <Legend />
      <Bar dataKey="prep_time" fill="#F9AB01">
        {filteredData.map((entry, index) => (
          <Cell
            key={`cell-${index}`}
            fill={entry.prep_time === maxPrepTime ? '#F9AB01' : '#4973A4'}
          />
        ))}
        <LabelList
          dataKey="prep_time"
          position="right"
          fill="#4B465C"
          offset={5}
          formatter={(value: any) => `${value} min`}
          style={{
            fontSize: 11,
          }}
        />
      </Bar>
    </BarChart>
  ) : (
    <div className="no-data-graph">No data to display</div>
  );
};

export default ChartBarVertical;
