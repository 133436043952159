// export const baseURL = 'https://admin.sadaynaal.com';
// export const baseURL = 'https://fastapido.sadaynaal.com';
export const baseURL = 'https://admin.chefadmin.com';

// export const foodics_integration_url =
//   'https://console-sandbox.foodics.com/authorize'; // For portal

export const foodics_integration_url = 'https://console.foodics.com/authorize'; // For live

// export const stripe_integration_key =
//   'pk_test_51NV9cNGmIpyYNEMV0IbwVxLvdILMQhCIsU9MwZ3Jv9LhvSesHVewLqG67AD8b1YOBojbQRihPvDmEhEkS5ULtYw300Ru5Ueu5d'; // For portal

export const stripe_integration_key =
  'pk_live_51MtSMPEEorQv8b5cx4AzvbAYhvIFYICmGJcDzjLnq5iH5nxzFdj1D6mQyR1e6wdhwHCo9PJG85AI5bkY4kwqqwB100mzSEKW8Q'; // For live
