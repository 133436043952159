/* eslint-disable no-unused-vars */
import React from 'react';
import { Bubble } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  LinearScale,
  PointElement,
  Tooltip,
  Legend,
  ChartOptions,
} from 'chart.js';

ChartJS.register(LinearScale, PointElement, Tooltip, Legend);

interface ChartBubbleProps {
  isMulti?: boolean;
  data: any[];
  source?: any;
  brandcount?: number;
  currency?: string;
}

const BubbleChart: React.FC<ChartBubbleProps> = ({ data, currency }) => {
  const uniqueNames = Array.from(new Set(data.flat().map((item) => item.name)));

  const flattenedData = data.flat();
  const maxValue = Math.max(...flattenedData.map((item) => item.value));

  const monthToNumber = (month: string) => {
    const months = [
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'May',
      'Jun',
      'Jul',
      'Aug',
      'Sep',
      'Oct',
      'Nov',
      'Dec',
    ];
    return months.indexOf(month);
  };

  const nameToNumber = (name: string) => uniqueNames.indexOf(name);

  const filteredData = data.map((itemArray) => {
    const sortedData = itemArray
      .sort((a: any, b: any) => b.value - a.value)
      .slice(0, 5);
    return sortedData;
  });

  const chartData = {
    datasets: filteredData.map((itemArray) => ({
      label: itemArray[0].name,
      data: itemArray.map((item: any) => ({
        x: monthToNumber(item.month),
        y: nameToNumber(item.name),
        r: item.value === 0 ? 1 : Math.sqrt(item.value) / 2,
        isHighest: item.value === maxValue,
      })),
      backgroundColor: itemArray.map((item: any) =>
        item.value === maxValue ? '#F9AB01' : '#DBDADE'
      ),
      hoverBackgroundColor: '#F9AB01',
    })),
  };

  // Chart options
  const options: ChartOptions<'bubble'> = {
    maintainAspectRatio: false,
    plugins: {
      legend: { display: false },
      tooltip: {
        bodyColor: '#000000',
        backgroundColor: '#FFFFFF',
        borderColor: '#E0E0E0',
        borderWidth: 1,
        callbacks: {
          label: (context) => {
            const dataPoint = context.raw as {
              x: number;
              y: number;
              r: number;
            };
            const value = dataPoint.r === 1 ? 0 : Math.pow(dataPoint.r * 2, 2);
            const formattedValue = value.toFixed(2);
            return `${
              uniqueNames[dataPoint.y]
            }: ${currency} ${formattedValue} (${
              [
                'Jan',
                'Feb',
                'Mar',
                'Apr',
                'May',
                'Jun',
                'Jul',
                'Aug',
                'Sep',
                'Oct',
                'Nov',
                'Dec',
              ][dataPoint.x]
            })`;
          },
        },
      },
    },
    scales: {
      x: {
        type: 'linear',
        position: 'bottom',
        title: { display: true },
        min: 0,
        max: 11,
        ticks: {
          callback: (tickValue) =>
            [
              'Jan',
              'Feb',
              'Mar',
              'Apr',
              'May',
              'Jun',
              'Jul',
              'Aug',
              'Sep',
              'Oct',
              'Nov',
              'Dec',
            ][tickValue as number],
          stepSize: 1,
        },
        grid: { display: false },
      },
      y: {
        type: 'linear',
        title: { display: true },
        min: -1,
        max: uniqueNames.length,
        ticks: {
          callback: (tickValue) => uniqueNames[tickValue as number] || '',
          stepSize: 1,
        },
        grid: {
          display: true,
          color: '#E0E0E0',
        },
      },
    },
  };

  return (
    <div style={{ height: '320px' }}>
      <Bubble data={chartData} options={options} />
    </div>
  );
};

export default BubbleChart;
