/* eslint-disable no-unused-vars */
/* Menu Icons */
import DashboardIcon from '../../assets/icons/sidebar/Dashboard.svg';
import MenuIcon from '../../assets/icons/sidebar/Menu.svg';
import MenuItemsIcon from '../../assets/icons/sidebar/MenuItems.svg';
import ModifiersIcon from '../../assets/icons/sidebar/Modifiers.svg';
import CombosIcon from '../../assets/icons/sidebar/Combos.svg';
import RecipeIcon from '../../assets/icons/sidebar/Recipes.svg';
import SubRecipeIcon from '../../assets/icons/sidebar/SubRecipes.svg';
import InventoryListIcon from '../../assets/icons/sidebar/InventoryList.svg';
import PurchaseOrdersIcon from '../../assets/icons/sidebar/PurchaseOrders.svg';
import InternalTransferIcon from '../../assets/icons/sidebar/InternalTransfer.svg';
import CashPurchaseIcon from '../../assets/icons/sidebar/CashPurchase.svg';
import OrderRequestIcon from '../../assets/icons/sidebar/OrderRequest.svg';
import WastageLogIcon from '../../assets/icons/sidebar/WastageLog.svg';
import StocktakingIcon from '../../assets/icons/sidebar/Stocktaking.svg';
import InventoryIcon from '../../assets/icons/sidebar/Inventory.svg';
import RandDMarketlistIcon from '../../assets/icons/sidebar/R&DMarketlist.svg';
import RandDMatchingIcon from '../../assets/icons/sidebar/R&DMatching.svg';
import RandDRecipeIcon from '../../assets/icons/sidebar/R&DRecipe.svg';
import SettingsIcon from '../../assets/icons/sidebar/Setting.svg';
import BrandIcon from '../../assets/icons/sidebar/Categories.svg';
import BranchIcon from '../../assets/icons/sidebar/Branch.svg';
import BuyingUnitIcon from '../../assets/icons/sidebar/BuyingUnit.svg';
import CategoriesIcon from '../../assets/icons/sidebar/Categories.svg';
import CompanyIcon from '../../assets/icons/sidebar/Company.svg';
import FeedbackIcon from '../../assets/icons/sidebar/Feedback.svg';
import LeadGenerationIcon from '../../assets/icons/sidebar/LeadGeneration.svg';
import ScreensIcon from '../../assets/icons/sidebar/Screens.svg';
import SizesIcon from '../../assets/icons/sidebar/Sizes.svg';
import OrderTypesIcon from '../../assets/icons/sidebar/OrderTypes.svg';
import CookingMethodsIcon from '../../assets/icons/sidebar/CookingMethods.svg';
import SignUpsIcon from '../../assets/icons/sidebar/SignUps.svg';
import SubscriptionsIcon from '../../assets/icons/sidebar/Subscriptions.svg';
import SupplierListIcon from '../../assets/icons/sidebar/SupplierList.svg';
import UserAccessIcon from '../../assets/icons/sidebar/UserAccess.svg';
import VariationsIcon from '../../assets/icons/sidebar/Variations.svg';
import UsersIcon from '../../assets/icons/sidebar/Users.svg';

import RBIcon from '../../assets/icons/sidebar/RBIcon.jpg';
import UserAccess from '../../pages/userAccess';

const appRoute = 'app/';

// export const Menu = [
//   {
//     id: 1,
//     routeName: 'Dashboard',
//     text: ``,
//     icon: DashboardIcon,
//     hasChild: true,
//     childRouteName: [
//       {
//         id: 2,
//         routeName: 'Dashboard',
//         path: `${appRoute}Dashboard`,
//         text: ``,
//         hasChild: false,
//       },
//     ],
//   },
//   {
//     id: 3,
//     routeName: 'Menu',
//     childRouteName: [
//       {
//         id: 4,
//         routeName: 'Menu',
//         path: `${appRoute}menu`,
//         text: ``,
//         hasChild: false,
//       },
//       {
//         id: 5,
//         routeName: 'Manage Menu',
//         path: `${appRoute}manageMenu`,
//         text: ``,
//         hasChild: false,
//       },
//       {
//         id: 6,
//         routeName: 'Combos',
//         path: `${appRoute}combos`,
//         text: ``,
//         hasChild: false,
//       },
//       {
//         id: 8,
//         routeName: 'Modifiers',
//         path: `${appRoute}modifiers`,
//         text: ``,
//         hasChild: false,
//       },
//     ],
//     text: ``,
//     icon: MenuIcon,
//     hasChild: true,
//   },
//   {
//     id: 43,
//     routeName: 'R&D',
//     text: ``,
//     icon: ReportIcon,
//     childRouteName: [
//       {
//         id: 44,
//         routeName: 'R&D Recipe List',
//         path: `${appRoute}rd-recipe`,
//         text: ``,
//         hasChild: false,
//       },
//       {
//         id: 44,
//         routeName: 'R&D SubRecipe List',
//         path: `${appRoute}rd-subrecipe`,
//         text: ``,
//         hasChild: false,
//       },
//       {
//         id: 44,
//         routeName: 'R&D MarketList',
//         path: `${appRoute}rd-marketlist`,
//         text: ``,
//         hasChild: false,
//       },
//       {
//         id: 44,
//         routeName: 'R&D Matching',
//         path: `${appRoute}ingredientlist`,
//         text: ``,
//         hasChild: false,
//       },
//     ],
//   },
//   {
//     id: 9,
//     routeName: 'Recipe',
//     childRouteName: [
//       {
//         id: 10,
//         routeName: 'Recipe Builder',
//         path: `${appRoute}recipeBuilder`,
//         text: ``,
//         hasChild: false,
//       },
//       {
//         id: 11,
//         routeName: 'Recipe',
//         path: `${appRoute}recipes`,
//         text: ``,
//         hasChild: false,
//       },
//       {
//         id: 12,
//         routeName: 'Upload Recipes',
//         path: `${appRoute}uploadRecipes`,
//         text: ``,
//         hasChild: false,
//       },
//       {
//         id: 13,
//         routeName: 'Upload Images',
//         path: `${appRoute}uploadimage`,
//         text: ``,
//         hasChild: false,
//       },
//       /* {
//         id: 12,
//         routeName: 'Bulk Upload Recipe Builder',
//         path: `${appRoute}uploadRecipes`,
//         text: ``,
//         hasChild: false,
//       },
//       {
//         id: 13,
//         routeName: 'Upload Recipe Builder Images',
//         path: `${appRoute}uploadimage`,
//         text: ``,
//         hasChild: false,
//       }, */
//     ],
//     icon: RecipeIcon,
//     text: ``,
//     hasChild: true,
//   },
//   {
//     id: 14,
//     routeName: 'Sub Recipe',
//     childRouteName: [
//       {
//         id: 15,
//         routeName: 'Sub Recipe',
//         path: `${appRoute}subrecipe`,
//         text: ``,
//         hasChild: false,
//       },
//       {
//         id: 16,
//         routeName: 'Upload Sub Recipe',
//         path: `${appRoute}uploadbulkSubRecipes`,
//         text: ``,
//         hasChild: false,
//       },
//       {
//         id: 17,
//         routeName: 'Sub Recipe Images',
//         path: `${appRoute}uploadSubRecipes`,
//         text: ``,
//         hasChild: false,
//       },
//     ],
//     text: ``,
//     icon: SubRecipeIcon,
//     hasChild: true,
//   },
//   {
//     id: 18,
//     routeName: 'Production',
//     childRouteName: [
//       {
//         id: 19,
//         routeName: 'Production Recipe',
//         path: `${appRoute}productionrecipe`,
//         text: ``,
//         hasChild: false,
//       },
//       {
//         id: 20,
//         routeName: 'Production Inventory',
//         path: `${appRoute}productionInventory`,
//         text: ``,
//         hasChild: false,
//       },
//       {
//         id: 21,
//         routeName: 'Consumptions',
//         path: `${appRoute}productionConsumption`,
//         text: ``,
//         hasChild: false,
//       },
//     ],
//     text: ``,
//     icon: ProductionIcon,
//     hasChild: true,
//   },
//   {
//     id: 22,
//     routeName: 'Supply',
//     text: ``,
//     icon: SupplyIcon,
//     hasChild: true,
//     childRouteName: [
//       {
//         id: 23,
//         routeName: 'Supply',
//         path: `${appRoute}supply`,
//         text: ``,
//         hasChild: false,
//       },
//       {
//         id: 24,
//         routeName: 'Inventory Transfer',
//         path: `${appRoute}inventorytransfer`,
//         text: ``,
//         hasChild: false,
//       },
//       {
//         id: 25,
//         routeName: 'Requisitions',
//         path: `${appRoute}requisitions`,
//         text: ``,
//         hasChild: false,
//       },
//       {
//         id: 26,
//         routeName: 'Purchase Orders',
//         path: `${appRoute}purchaseorders`,
//         text: ``,
//         hasChild: false,
//       },
//       {
//         id: 27,
//         routeName: 'Purchase Transactions',
//         path: `${appRoute}purchasetransactions`,
//         text: ``,
//         hasChild: false,
//       },
//     ],
//   },
//   {
//     id: 28,
//     routeName: 'Inventory',
//     text: ``,
//     icon: InventoryIcon,
//     hasChild: true,
//     childRouteName: [
//       {
//         id: 29,
//         routeName: 'Inventory',
//         path: `${appRoute}inventory`,
//         text: ``,
//         hasChild: false,
//       },
//       {
//         id: 30,
//         routeName: 'Add Stock',
//         path: `${appRoute}addStock`,
//         text: ``,
//         hasChild: false,
//       },
//       {
//         id: 31,
//         routeName: 'Stock Count',
//         path: `${appRoute}stockCount`,
//         text: ``,
//         hasChild: false,
//       },
//     ],
//   },
//   {
//     id: 32,
//     routeName: 'Wastage',
//     text: ``,
//     icon: WastageIcon,
//     hasChild: true,
//     childRouteName: [
//       {
//         id: 2,
//         routeName: 'Wastage',
//         path: `${appRoute}wastage`,
//         text: ``,
//         hasChild: false,
//       },
//     ],
//   },
//   {
//     id: 33,
//     routeName: 'Market List',
//     text: ``,
//     icon: InventoryIcon,
//     hasChild: true,
//     childRouteName: [
//       {
//         id: 2,
//         routeName: 'Market List',
//         path: `${appRoute}marketList`,
//         text: ``,
//         hasChild: false,
//       },
//       {
//         id: 42,
//         routeName: 'Upload Market List',
//         path: `${appRoute}uploadMarketList`,
//         text: ``,
//         hasChild: false,
//       },
//     ],
//   },
//   {
//     id: 34,
//     routeName: 'Reports',
//     text: ``,
//     icon: ReportIcon,
//     childRouteName: [
//       {
//         id: 2,
//         routeName: 'Reports',
//         path: `${appRoute}reports`,
//         text: ``,
//         hasChild: false,
//       },
//     ],
//   },
//   {
//     id: 35,
//     routeName: 'Settings',
//     text: ``,
//     icon: SettingsIcon,
//     hasChild: true,
//     childRouteName: [
//       {
//         id: 36,
//         routeName: 'Company',
//         path: `${appRoute}company`,
//         text: ``,
//         hasChild: false,
//       },
//       {
//         id: 37,
//         routeName: 'Branch',
//         path: `${appRoute}branches`,
//         text: ``,
//         hasChild: false,
//       },
//       {
//         id: 38,
//         routeName: 'Brands',
//         path: `${appRoute}brands`,
//         text: ``,
//         hasChild: false,
//       },
//       {
//         id: 39,
//         routeName: 'Users',
//         path: `${appRoute}users`,
//         text: ``,
//         hasChild: false,
//       },
//       {
//         id: 40,
//         routeName: 'Menu Category',
//         path: `${appRoute}menuCategory`,
//         text: ``,
//         hasChild: false,
//       },
//       {
//         id: 41,
//         routeName: 'Ingredient Category',
//         path: `${appRoute}ingredientCategory`,
//         text: ``,
//         hasChild: false,
//       },
//       {
//         id: 44,
//         routeName: 'R&D Ingredient Category',
//         path: `${appRoute}rd-ingredientCategory`,
//         text: ``,
//         hasChild: false,
//       },
//       {
//         id: 44,
//         routeName: 'R&D Menu Category',
//         path: `${appRoute}rd-menuCategory`,
//         text: ``,
//         hasChild: false,
//       },

//       {
//         id: 7,
//         routeName: 'Variations',
//         path: `${appRoute}variations`,
//         text: ``,
//         hasChild: false,
//       },
//       {
//         id: 42,
//         routeName: 'Feedback',
//         path: `${appRoute}feedback`,
//         text: ``,
//         hasChild: false,
//       },
//       {
//         id: 43,
//         routeName: 'Screens',
//         path: `${appRoute}screens`,
//         text: ``,
//         hasChild: false,
//       },
//       {
//         id: 44,
//         routeName: 'User Access',
//         path: `${appRoute}userAccess`,
//         text: ``,
//         hasChild: false,
//       },
//       {
//         id: 45,
//         routeName: 'Lead Generation',
//         path: `${appRoute}leadGeneration`,
//         text: ``,
//         hasChild: false,
//       },
//     ],
//   },
// ];

export const Menu = [
  {
    id: 1,
    routeName: 'Dashboard',
    routeNameAr: 'لوحة القيادة',
    text: ``,
    icon: DashboardIcon,
    path: `${appRoute}Dashboard`,
    hasChild: false,
    childRouteName: [
      {
        id: 2,
        routeName: 'Dashboard',
        routeNameAr: 'لوحة القيادة',
        path: `${appRoute}Dashboard`,
        text: ``,
        hasChild: false,
      },
    ],
  },
  {
    id: 3,
    routeName: 'Menu Manager',
    routeNameAr: 'قائمة طعام',
    childRouteName: [
      {
        id: 4,
        routeName: 'Menu Items',
        routeNameAr: 'قائمة القائمة',
        path: `${appRoute}menu`,
        icon: MenuItemsIcon,
        text: ``,
        hasChild: false,
      },
      // {
      //   id: 5,
      //   routeName: 'Manage Menu',
      //   routeNameAr: 'إدارة القائمة',
      //   path: `${appRoute}manageMenu`,
      //   text: ``,
      //   hasChild: false,
      // },
      {
        id: 6,
        routeName: 'Combos',
        routeNameAr: 'المجموعات',
        path: `${appRoute}combos`,
        icon: CombosIcon,
        text: ``,
        hasChild: false,
      },
      {
        id: 8,
        routeName: 'Modifier Groups',
        routeNameAr: 'الصفات التعريفية',
        path: `${appRoute}modifiers`,
        icon: ModifiersIcon,
        text: ``,
        hasChild: false,
      },
    ],
    text: ``,
    icon: MenuIcon,
    hasChild: true,
  },
  {
    id: 14,
    routeName: 'Recipe Manager',
    routeNameAr: 'وصفة فرعية',
    childRouteName: [
      {
        id: 11,
        routeName: 'Recipe',
        routeNameAr: 'وصفة',
        path: `${appRoute}recipes`,
        icon: RecipeIcon,
        text: ``,
        hasChild: false,
      },
      {
        id: 15,
        routeName: 'Sub Recipe',
        routeNameAr: 'وصفة فرعية',
        path: `${appRoute}subrecipe`,
        icon: SubRecipeIcon,
        text: ``,
        hasChild: false,
      },
    ],
    text: ``,
    icon: SubRecipeIcon,
    hasChild: true,
  },
  {
    id: 28,
    routeName: 'Inventory Manager',
    routeNameAr: 'جرد',
    text: ``,
    icon: InventoryIcon,
    hasChild: true,
    childRouteName: [
      {
        id: 29,
        routeName: 'Inventory List',
        routeNameAr: 'قائمة الجرد',
        path: `${appRoute}inventory`,
        icon: InventoryListIcon,
        text: ``,
        hasChild: false,
      },
      {
        id: 80,
        routeName: 'Purchase Orders',
        routeNameAr: 'طلبات الشراء',
        path: `${appRoute}purchaseOrder`,
        icon: PurchaseOrdersIcon,
        text: ``,
        hasChild: false,
      },
      {
        id: 20,
        routeName: 'Stock Request',
        routeNameAr: 'طلب الأسهم',
        path: `${appRoute}orderStock`,
        icon: OrderRequestIcon,
        text: ``,
        hasChild: false,
      },
      {
        id: 24,
        routeName: 'Internal Transfer',
        routeNameAr: 'تحويل المخزون',
        path: `${appRoute}inventorytransfer`,
        icon: InternalTransferIcon,
        text: ``,
        hasChild: false,
      },
      {
        id: 26,
        routeName: 'Cash Purchase',
        routeNameAr: 'شراء نقدا',
        path: `${appRoute}cashPurchase`,
        icon: CashPurchaseIcon,
        text: ``,
        hasChild: false,
      },
      {
        id: 31,
        routeName: 'Stocktaking',
        routeNameAr: 'أخذ الجرد',
        path: `${appRoute}stockTaking`,
        icon: StocktakingIcon,
        text: ``,
        hasChild: false,
      },
      {
        id: 2,
        routeName: 'Wastage Log',
        routeNameAr: 'قائمة الفاقد',
        path: `${appRoute}wastage`,
        icon: WastageLogIcon,
        text: ``,
        hasChild: false,
      },
    ],
  },
  // {
  //   id: 14,
  //   routeName: 'Sub Recipe',
  //   routeNameAr: 'وصفة فرعية',
  //   childRouteName: [
  //     {
  //       id: 15,
  //       routeName: 'Sub Recipe',
  //       routeNameAr: 'وصفة فرعية',
  //       path: `${appRoute}subrecipe`,
  //       text: ``,
  //       hasChild: false,
  //     },
  //     {
  //       id: 16,
  //       routeName: 'Upload Sub Recipe',
  //       path: `${appRoute}uploadbulkSubRecipes`,
  //       text: ``,
  //       hasChild: false,
  //     },
  //   ],
  //   text: ``,
  //   icon: SubRecipeIcon,
  //   hasChild: true,
  // },
  // {
  //   id: 9,
  //   routeName: 'Recipe',
  //   routeNameAr: 'وصفة',
  //   childRouteName: [
  //     {
  //       id: 10,
  //       routeName: 'Recipe Builder',
  //       path: `${appRoute}recipeBuilder`,
  //       text: ``,
  //       hasChild: false,
  //     },
  //     {
  //       id: 11,
  //       routeName: 'Recipe',
  //       routeNameAr: 'وصفة',
  //       path: `${appRoute}recipes`,
  //       text: ``,
  //       hasChild: false,
  //     },
  //     {
  //       id: 12,
  //       routeName: 'Upload Recipes',
  //       routeNameAr: 'تحميل الوصفات',
  //       path: `${appRoute}bulkUpload`,
  //       text: ``,
  //       hasChild: false,
  //     },
  //     {
  //       id: 13,
  //       routeName: 'Recipe Images',
  //       routeNameAr: 'وصفة الصور',
  //       path: `${appRoute}uploadimage`,
  //       text: ``,
  //       hasChild: false,
  //     },
  //      {
  //       id: 12,
  //       routeName: 'Bulk Upload Recipe Builder',
  //       path: `${appRoute}uploadRecipes`,
  //       text: ``,
  //       hasChild: false,
  //     },
  //     {
  //       id: 13,
  //       routeName: 'Upload Recipe Builder Images',
  //       path: `${appRoute}uploadimage`,
  //       text: ``,
  //       hasChild: false,
  //     },
  //   ],
  //   icon: RecipeIcon,
  //   text: ``,
  //   hasChild: true,
  // },
  // {
  //   id: 28,
  //   routeName: 'Inventory',
  //   routeNameAr: 'جرد',
  //   text: ``,
  //   icon: InventoryIcon,
  //   hasChild: true,
  //   childRouteName: [
  //     {
  //       id: 29,
  //       routeName: 'Inventory List',
  //       routeNameAr: 'قائمة الجرد',
  //       path: `${appRoute}inventory`,
  //       text: ``,
  //       hasChild: false,
  //     },
  //     {
  //       id: 31,
  //       routeName: 'Stock Taking',
  //       routeNameAr: 'أخذ الجرد',
  //       path: `${appRoute}stockTaking`,
  //       text: ``,
  //       hasChild: false,
  //     },
  //     {
  //       id: 46,
  //       routeName: 'Upload Ingredients',
  //       path: `${appRoute}uploadIngredients`,
  //       text: ``,
  //       hasChild: false,
  //     },
  //   ],
  // },
  // {
  //   id: 32,
  //   routeName: 'Wastage',
  //   routeNameAr: 'الهدر',
  //   text: ``,
  //   icon: WastageIcon,
  //   hasChild: true,
  //   childRouteName: [
  //     {
  //       id: 2,
  //       routeName: 'Wastage List',
  //       routeNameAr: 'قائمة الفاقد',
  //       path: `${appRoute}wastage`,
  //       text: ``,
  //       hasChild: false,
  //     },
  //   ],
  // },
  {
    id: 34,
    routeName: 'Reports',
    routeNameAr: 'التقارير',
    text: ``,
    icon: '',
    path: `${appRoute}reports`,
    hasChild: false,
    childRouteName: [
      {
        id: 2,
        routeName: 'Reports',
        routeNameAr: 'التقارير',
        path: `${appRoute}reports`,
        text: ``,
        hasChild: false,
      },
    ],
  },
  {
    id: 35,
    routeName: 'Settings',
    routeNameAr: 'إعدادات',
    text: ``,
    icon: SettingsIcon,
    hasChild: true,
    childRouteName: [
      {
        id: 36,
        routeName: 'Company',
        routeNameAr: 'شركة',
        path: `${appRoute}company`,
        icon: CompanyIcon,
        text: ``,
        hasChild: false,
      },
      {
        id: 38,
        routeName: 'Brand',
        routeNameAr: 'ماركة',
        path: `${appRoute}brands`,
        icon: BrandIcon,
        text: ``,
        hasChild: false,
      },

      {
        id: 37,
        routeName: 'Branch',
        routeNameAr: 'فرع',
        path: `${appRoute}branches`,
        icon: BranchIcon,
        text: ``,
        hasChild: false,
      },
      {
        id: 39,
        routeName: 'Users',
        routeNameAr: 'المستخدمون',
        path: `${appRoute}users`,
        icon: UsersIcon,
        text: ``,
        hasChild: false,
      },
      {
        id: 23,
        routeName: 'Supplier List',
        routeNameAr: 'لائحة المزودين',
        path: `${appRoute}supply`,
        icon: SupplierListIcon,
        text: ``,
        hasChild: false,
      },
      // {
      //   id: 40,
      //   routeName: 'Menu Category',
      //   path: `${appRoute}menuCategory`,
      //   text: ``,
      //   hasChild: false,
      // },
      // {
      //   id: 41,
      //   routeName: 'Ingredient Category',
      //   path: `${appRoute}ingredientCategory`,
      //   text: ``,
      //   hasChild: false,
      // },
      {
        id: 51,
        routeName: 'Categories',
        routeNameAr: 'فئات',
        path: `${appRoute}menuCategory`,
        icon: CategoriesIcon,
        text: ``,
        hasChild: false,
      },
      // {
      //   id: 44,
      //   routeName: 'R&D Categories',
      //   path: `${appRoute}rd-menuCategory`,
      //   text: ``,
      //   hasChild: false,
      // },

      // {
      //   id: 44,
      //   routeName: 'R&D Ingredient Category',
      //   path: `${appRoute}rd-ingredientCategory`,
      //   text: ``,
      //   hasChild: false,
      // },

      // {
      //   id: 7,
      //   routeName: 'Variations',
      //   routeNameAr: 'الاختلافات',
      //   path: `${appRoute}variations`,
      //   icon: VariationsIcon,
      //   text: ``,
      //   hasChild: false,
      // },
      {
        id: 42,
        routeName: 'Feedback',
        routeNameAr: 'تعليق',
        path: `${appRoute}feedback`,
        icon: FeedbackIcon,
        text: ``,
        hasChild: false,
      },
      {
        id: 45,
        routeName: 'Lead Generation',
        routeNameAr: 'تقود الجيل',
        path: `${appRoute}leadGeneration`,
        icon: LeadGenerationIcon,
        text: ``,
        hasChild: false,
      },
      {
        id: 44,
        routeName: 'User Access',
        routeNameAr: 'وصول المستخدم',
        path: `${appRoute}userAccess`,
        icon: UserAccessIcon,
        text: ``,
        hasChild: false,
      },
      {
        id: 52,
        routeName: 'Screens',
        routeNameAr: 'شاشات',
        path: `${appRoute}screens`,
        icon: ScreensIcon,
        text: ``,
        hasChild: false,
      },
      {
        id: 53,
        routeName: 'Buying Unit',
        routeNameAr: 'وحدة الشراء',
        path: `${appRoute}buyingUnit`,
        icon: BuyingUnitIcon,
        text: ``,
        hasChild: false,
      },
      {
        id: 54,
        routeName: 'Subscriptions',
        routeNameAr: '',
        path: `${appRoute}subscriptions`,
        icon: SubscriptionsIcon,
        text: ``,
        hasChild: false,
      },
      {
        id: 5,
        routeName: 'Sizes',
        routeNameAr: 'الأحجام',
        path: `${appRoute}sizes`,
        icon: SizesIcon,
        text: ``,
        hasChild: false,
      },
      {
        id: 89,
        routeName: 'Cooking Methods',
        routeNameAr: 'طرق الطبخ',
        path: `${appRoute}cookingMethods`,
        icon: CookingMethodsIcon,
        text: ``,
        hasChild: false,
      },
      {
        id: 90,
        routeName: 'Order Types',
        routeNameAr: 'أنواع الطلبات',
        path: `${appRoute}orderTypes`,
        icon: OrderTypesIcon,
        text: ``,
        hasChild: false,
      },
      {
        id: 72,
        routeName: 'Sign Ups',
        routeNameAr: '',
        path: `${appRoute}signups`,
        icon: SignUpsIcon,
        text: ``,
        hasChild: false,
      },
    ],
  },

  // {
  //   id: 33,
  //   routeName: 'Market List - Tab',
  //   text: ``,
  //   icon: InventoryIcon,
  //   hasChild: true,
  //   childRouteName: [
  //     {
  //       id: 2,
  //       routeName: 'Market List',
  //       path: `${appRoute}marketList`,
  //       text: ``,
  //       hasChild: false,
  //     },
  //     {
  //       id: 42,
  //       routeName: 'Upload Market List',
  //       path: `${appRoute}uploadMarketList`,
  //       text: ``,
  //       hasChild: false,
  //     },
  //   ],
  // },
  {
    id: 43,
    routeName: 'Engineering',
    routeNameAr: 'بحث وتطوير',
    text: ``,
    icon: RBIcon,
    hasChild: true,
    childRouteName: [
      {
        id: 47,
        routeName: 'R&D Recipe',
        routeNameAr: 'وصفة البحث والتطوير',
        path: `${appRoute}r&d-recipe`,
        icon: RandDRecipeIcon,
        text: ``,
        hasChild: false,
      },
      /* {
        id: 48,
        routeName: 'R&D Sub Recipe',
        routeNameAr: 'وصفة البحث والتطوير الفرعية',
        path: `${appRoute}r&d-subrecipe`,
        text: ``,
        hasChild: false,
      }, */
      {
        id: 49,
        routeName: 'R&D Market List',
        routeNameAr: 'قائمة سوق البحث والتطوير',
        path: `${appRoute}R&DmarketList`,
        icon: RandDMarketlistIcon,
        text: ``,
        hasChild: false,
      },
      {
        id: 50,
        routeName: 'R&D Matching',
        routeNameAr: 'البحث والتطوير المطابقة',
        path: `${appRoute}ingredientlist`,
        icon: RandDMatchingIcon,
        text: ``,
        hasChild: false,
      },
    ],
  },
  {
    id: 18,
    routeName: 'Production',
    routeNameAr: 'إنتاج',
    path: `${appRoute}productionPlan`,
    childRouteName: [
      {
        id: 19,
        routeName: 'Production Plan',
        routeNameAr: 'خطة الإنتاج',
        path: `${appRoute}productionPlan`,
        text: ``,
        hasChild: false,
      },
      // {
      //   id: 20,
      //   routeName: 'Production Inventory',
      //   routeNameAr: 'جرد الإنتاج',
      //   path: `${appRoute}productionInventory`,
      //   text: ``,
      //   hasChild: false,
      // },
      // {
      //   id: 21,
      //   routeName: 'Consumptions',
      //   routeNameAr: 'الاستهلاكات',
      //   path: `${appRoute}productionConsumption`,
      //   text: ``,
      //   hasChild: false,
      // },
    ],
    text: ``,
    icon: '',
    hasChild: false,
  },
  {
    id: 19,
    routeName: 'Help',
    routeNameAr: 'مساعدة',
    text: ``,
    icon: DashboardIcon,
    path: `${appRoute}Help`,
    hasChild: false,
    childRouteName: [
      {
        id: 2,
        routeName: 'Help',
        routeNameAr: 'مساعدة',
        path: `${appRoute}Help`,
        text: ``,
        hasChild: false,
      },
    ],
  },
];

export default Menu;
