/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import { PieChart, Pie, Sector, Cell, LabelList } from 'recharts';
import {
  handleFloat,
  handleFloatForSpecialCase,
} from '../../../helper/sortData';

const Colors = ['#d62728', '#a5a5a5', '#f9ab01'];

const ChartPie = (props: any) => {
  const { data, component, currency, source } = props;
  const [pieData, setPieData] = useState<{ name: string; value: number }[]>([]);
  const [totalKcal, setTotalKcal] = useState('');
  const [totalWastage, setTotalWastage] = useState('');

  const chartWidth =
    component === 'listView' || component === 'wizard' ? 500 : 410;
  const chartHeight =
    component === 'listView' || component === 'wizard' || component === 'menu'
      ? 270
      : 250;
  const chartInnerRadius =
    component === 'listView' || component === 'wizard' || component === 'menu'
      ? 50
      : 40;
  const chartOuterRadius =
    component === 'listView' || component === 'wizard' || component === 'menu'
      ? 80
      : 75;

  useEffect(() => {
    let TempData: { name: string; value: number }[] = [];
    if (component === 'wizard') {
      TempData = [
        {
          name: 'Carbs',
          value: data?.carb_per_unit
            ? Math.round(data.carb_per_unit * parseFloat(data.quantity))
            : 0,
        },
        {
          name: 'Fat',
          value: data?.fat_per_unit
            ? Math.round(data.fat_per_unit * parseFloat(data.quantity))
            : 0,
        },
        {
          name: 'Protein',
          value: data?.protein_per_unit
            ? Math.round(data.protein_per_unit * parseFloat(data.quantity))
            : 0,
        },
      ];
      const calculatedKcal =
        component === 'wizard'
          ? data?.calories_per_unit
            ? Math.round(data.calories_per_unit * parseFloat(data.quantity))
            : 0
          : data?.calorie
          ? Math.round(data.calorie)
          : 0;
      setTotalKcal(`${handleFloat(calculatedKcal)} kcal`);
    } else if (component === 'listView') {
      if (Array.isArray(data?.ingredients)) {
        const totalCarbs =
          data.ingredients.reduce(
            (total: any, ingredient: any) =>
              total +
              Math.round(
                ingredient.carb_per_unit * parseFloat(ingredient.quantity)
              ),
            0
          ) ||
          data.ingredients.reduce(
            (total: any, ingredient: any) =>
              total + Math.round(ingredient.carb),
            0
          );
        const totalFat =
          data.ingredients.reduce(
            (total: any, ingredient: any) =>
              total +
              Math.round(
                ingredient.fat_per_unit * parseFloat(ingredient.quantity)
              ),
            0
          ) ||
          data.ingredients.reduce(
            (total: any, ingredient: any) => total + Math.round(ingredient.fat),
            0
          );
        const totalProtein =
          data.ingredients.reduce(
            (total: any, ingredient: any) =>
              total +
              Math.round(
                ingredient.protein_per_unit * parseFloat(ingredient.quantity)
              ),
            0
          ) ||
          data.ingredients.reduce(
            (total: any, ingredient: any) =>
              total + Math.round(ingredient.protein),
            0
          );
        TempData = [
          { name: 'Carbs', value: totalCarbs },
          { name: 'Fat', value: totalFat },
          { name: 'Protein', value: totalProtein },
        ];
        const calculatedKcal =
          data.ingredients.reduce((total: any, ingredient: any) => {
            return (
              total +
              Math.round(
                ingredient.calories_per_unit * parseFloat(ingredient.quantity)
              )
            );
          }, 0) ||
          data.ingredients.reduce((total: any, ingredient: any) => {
            return total + Math.round(ingredient.calorie);
          }, 0);
        setTotalKcal(`${handleFloat(calculatedKcal)} kcal`);
      } else {
        TempData = [
          {
            name: 'Carbs',
            value: data?.carb ? Math.round(data.carb) : 0,
          },
          {
            name: 'Fat',
            value: data?.fat ? Math.round(data.fat) : 0,
          },
          {
            name: 'Protein',
            value: data?.protein ? Math.round(data.protein) : 0,
          },
        ];
        const calculatedKcal = data?.calorie ? Math.round(data.calorie) : 0;
        setTotalKcal(`${handleFloat(calculatedKcal)} kcal`);
      }
    } else if (component === 'menu') {
      TempData = [
        {
          name: 'Carbs',
          value: data?.carb ? Math.round(data.carb) : 0,
        },
        {
          name: 'Fat',
          value: data?.fat ? Math.round(data.fat) : 0,
        },
        {
          name: 'Protein',
          value: data?.protein ? Math.round(data.protein) : 0,
        },
      ];
      const calculatedKcal = data?.calorie ? Math.round(data.calorie) : 0;
      setTotalKcal(`${handleFloat(calculatedKcal)} kcal`);
    } else {
      TempData = Array.isArray(data)
        ? data.map((item) => ({
            name: item.name,
            value: parseFloat(item.value) || 0,
            percentage: parseFloat(item.percentage) || 0,
          }))
        : [];
      // TempData = data ? data : [];
      // setTotalWastage(data?.total || '');
    }
    setPieData(TempData);
  }, [data, component]);

  const renderActiveShape = (props: any) => {
    const {
      cx,
      cy,
      midAngle,
      innerRadius,
      outerRadius,
      startAngle,
      endAngle,
      fill,
      percentage,
      value,
      name,
    } = props;

    const RADIAN = Math.PI / 180;
    const sin = Math.sin(-RADIAN * midAngle);
    const cos = Math.cos(-RADIAN * midAngle);
    const sx = cx + (outerRadius + 10) * cos;
    const sy = cy + (outerRadius + 10) * sin;
    const mx = cx + (outerRadius + 30) * cos;
    const my = cy + (outerRadius + 30) * sin;
    const ex = mx + (cos >= 0 ? 1 : -1) * 22;
    const ey = my;
    const textAnchor = cos >= 0 ? 'start' : 'end';

    return (
      <g>
        <text x={cx} y={cy} dy={8} textAnchor="middle">
          {component === 'wizard' ||
          component === 'listView' ||
          component === 'menu'
            ? totalKcal
            : ''}
          {/* : `${currency} ${handleFloat(totalWastage)}`} */}
        </text>
        <Sector
          cx={cx}
          cy={cy}
          innerRadius={innerRadius}
          outerRadius={outerRadius}
          startAngle={startAngle}
          endAngle={endAngle}
          fill={fill}
        />
        <Sector
          cx={cx}
          cy={cy}
          startAngle={startAngle}
          endAngle={endAngle}
          innerRadius={outerRadius + 6}
          outerRadius={outerRadius + 10}
          fill={fill}
        />
        <path
          d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`}
          stroke={fill}
          fill="none"
        />
        <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" />
        <text
          x={ex + (cos >= 0 ? 1 : -1) * 12}
          y={ey}
          textAnchor={textAnchor}
          fill="#333"
        >
          {`${name}`}
        </text>
        <text
          x={ex + (cos >= 0 ? 1 : -1) * 12}
          y={ey}
          dy={18}
          textAnchor={textAnchor}
          fill="#999"
        >
          {component === 'listView' ||
          component === 'wizard' ||
          component === 'menu'
            ? `${handleFloat(value)} g`
            : `${percentage.toFixed(2)} %`}
        </text>
      </g>
    );
  };

  const [activeIndex, setActiveIndex] = useState(0);

  const onPieEnter = (_: any, index: any) => {
    setActiveIndex(index);
  };

  const isAllZero = pieData.every((entry: any) => entry.value === 0);

  return pieData.length > 0 && !isAllZero ? (
    <PieChart width={chartWidth} height={chartHeight}>
      <Pie
        activeIndex={activeIndex}
        activeShape={renderActiveShape}
        data={pieData}
        cx="50%"
        cy="50%"
        innerRadius={chartInnerRadius}
        outerRadius={chartOuterRadius}
        fill="#8884d8"
        dataKey="value"
        onMouseEnter={onPieEnter}
        // labelLine={false}
        // label
      >
        {pieData.map((entry, index) => {
          const dynamicColors =
            source === 'foodCostPercentage'
              ? ['#F5B11E', '#E5A11A', '#D59116', '#C58212', '#B5720E']
              : Colors;

          return (
            <Cell
              key={`cell-${index}`}
              fill={dynamicColors[index % dynamicColors.length]}
            />
          );
        })}
        {/* {(component === 'wizard' || component === 'listView') && (
          <LabelList
            position="left"
            fill="#000"
            formatter={(name: any) =>
              `${name} (g) `
            }
          />
        )} */}
      </Pie>
    </PieChart>
  ) : (
    <div className="no-data-pie">No data to display</div>
  );
};

export default ChartPie;
