import { getFormattedNumericValue } from './utils';

export const sortedData = (order: boolean, list: any, column: string) => {
  if (order) {
    return list.sort(function (a: any, b: any) {
      if (a[column] > b[column]) {
        return -1;
      }
      if (a[column] < b[column]) {
        return 1;
      }
      return 0;
    });
  } else {
    return list.sort(function (a: any, b: any) {
      if (a[column] < b[column]) {
        return -1;
      }
      if (a[column] > b[column]) {
        return 1;
      }
      return 0;
    });
  }
};

export const sortNumber = (order: boolean, list: any, column: string) => {
  if (order) return list.sort((a: any, b: any) => +a[column] - +b[column]);
  else return list.sort((a: any, b: any) => +b[column] - +a[column]);
};

export const decimalCount = (num: any) => {
  // Convert to String
  const numStr = String(num);
  // String Contains Decimal
  if (numStr.includes('.')) {
    return numStr.split('.')[1].length;
  }
  // String Does Not Contain Decimal
  return 0;
};

const handleValue = (value: any) => {
  const numStr = String(value);
  if (numStr?.includes('.')) {
    return numStr.split('.')[1].length;
  } else {
    return numStr.length;
  }
};
export const handleFloat = (value: any) => {
  let roundVal =
    Number(value) === value && value % 1 !== 0
      ? decimalCount(+value) > 2
        ? value?.toFixed(2)
        : value
      : value;

  return getFormattedNumericValue(Math.round(roundVal));
};

export const handleFloatForSpecialCase = (value: any) =>
  handleValue(value) > 2 ? Number(value)?.toFixed(2) : value;

export const formatNumberFloatValue = (value: any) => {
  if (isNaN(value)) {
    return value;
  }
  const formattedValue = parseFloat(value).toFixed(2);
  return formattedValue.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

export const formatNumber = (value: any) => {
  if (value != null) {
    return value.toLocaleString(undefined, { maximumFractionDigits: 2 });
  }
  return '';
};

export const formatNumberWithEllipsis = (number: any) => {
  const numberStr = number.toString();
  if (numberStr.length > 3) {
    return numberStr.substring(0, 3) + '...';
  }
  return numberStr;
};
