/* eslint-disable no-unused-vars */
import React, { useEffect, useState, useRef } from 'react';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
} from 'recharts';

interface stackedBarVerticalProps {
  data: any[];
  source?: string;
  brandcount?: number;
  currency?: string;
}

const StackedBarVertical = (props: stackedBarVerticalProps) => {
  const { data, source, brandcount, currency } = props;
  let transformedData = data;
  let ingredientKeys: string[] = [];

  if (!data || data.length === 0) {
    return <div className="no-data-graph">No data to display</div>;
  }

  if (source === 'recipeCosting') {
    transformedData = data.map((item) => {
      Object.keys(item.ingredients).forEach((key) => {
        if (!ingredientKeys.includes(key)) {
          ingredientKeys.push(key);
        }
      });
      return { name: item.name, ...item.ingredients };
    });
  }

  const CustomXAxisTick = (props: any) => {
    const { x, y, payload } = props;
    const textRef = useRef<SVGTextElement | null>(null);
    const [lines, setLines] = useState<string[]>([]);
    useEffect(() => {
      if (textRef.current) {
        const textElement = textRef.current;
        const text = payload.value;
        const maxWidth = 100;
        const words = text.split(' ');
        let currentLine = '';
        let calculatedLines: string[] = [];
        words.forEach((word: string) => {
          const testLine = currentLine ? `${currentLine} ${word}` : word;
          textElement.textContent = testLine;
          if (textElement.getComputedTextLength() > maxWidth) {
            calculatedLines.push(currentLine);
            currentLine = word;
          } else {
            currentLine = testLine;
          }
        });

        if (currentLine) {
          calculatedLines.push(currentLine);
        }
        setLines(calculatedLines);
      }
    }, [payload.value]);

    return (
      <g transform={`translate(${x},${y})`}>
        <text
          ref={textRef}
          style={{ visibility: 'hidden', position: 'absolute' }}
        >
          {payload.value}
        </text>
        {lines.map((line, index) => (
          <text
            key={index}
            x={0}
            y={0}
            dy={16 + index * 16}
            textAnchor="middle"
            fill="#4B465C"
            fontSize={11}
          >
            {line}
          </text>
        ))}
      </g>
    );
  };

  const formatYAxisLabel = (value: number) => {
    if (value >= 1000) {
      const formattedValue = value / 1000;
      if (formattedValue % 1 === 0) {
        return `${formattedValue}k`;
      } else {
        return `${formattedValue.toFixed(1)}k`;
      }
    }
    return value.toString();
  };

  const chartWidth = 560;
  const chartHeight = 250;

  const getColor = (index: number) => {
    const baseColors = ['#a5a5a5', '#f6a117', '#1f77b4'];
    const shadeFactor = 1 + (index % 5) * 0.15;
    const hexToRGB = (hex: string) => {
      const bigint = parseInt(hex.substring(1), 16);
      const r = (bigint >> 16) & 255;
      const g = (bigint >> 8) & 255;
      const b = bigint & 255;
      return [r, g, b];
    };
    const adjustBrightness = (hex: string, factor: number) => {
      const [r, g, b] = hexToRGB(hex);
      const newR = Math.min(230, Math.round(r * factor));
      const newG = Math.min(230, Math.round(g * factor));
      const newB = Math.min(230, Math.round(b * factor));
      return `rgb(${newR}, ${newG}, ${newB})`;
    };
    return adjustBrightness(
      baseColors[index % baseColors.length],
      Math.min(shadeFactor, 1.5)
    );
  };

  const CustomTooltip = ({ active, payload, label }: any) => {
    if (active && payload && payload.length) {
      const total = payload.reduce(
        (sum: number, entry: any) => sum + entry.value,
        0
      );
      return (
        <div
          style={{
            fontSize: '11px',
            padding: '10px',
            minWidth: '100px',
            backgroundColor: '#fff',
            border: '1px solid #ccc',
            borderRadius: '4px',
          }}
        >
          <p style={{ fontWeight: 'bold', marginBottom: '5px' }}>{label}</p>
          {payload.map((entry: any, index: number) => (
            <p key={`item-${index}`} style={{ color: entry.color }}>
              <strong>{entry.name}</strong>: {currency} {entry.value.toFixed(2)}
            </p>
          ))}
          <p style={{ fontWeight: 'bold', marginTop: '5px' }}>
            Total: {currency} {total.toFixed(2)}
          </p>
        </div>
      );
    }
    return null;
  };

  return data && data.length > 0 ? (
    <BarChart
      width={chartWidth}
      height={chartHeight}
      data={transformedData}
      margin={{ top: 5, right: 30, left: 0, bottom: 30 }}
    >
      <CartesianGrid horizontal={true} vertical={false} stroke="#E0E0E0" />
      <XAxis
        dataKey="name"
        type="category"
        tick={<CustomXAxisTick />}
        interval={0}
        height={60}
        textAnchor="end"
        angle={0}
      />
      <YAxis
        type="number"
        tick={{ fill: '#4B465C' }}
        tickFormatter={formatYAxisLabel}
        stroke="transparent"
      />
      <Tooltip
        content={<CustomTooltip />}
        formatter={(value: number) => `${currency} ${value.toFixed(2)}`}
        wrapperStyle={{
          zIndex: 1000,
          visibility: 'visible',
        }}
      />
      <Legend />
      {ingredientKeys.map((key, index) => (
        <Bar key={key} dataKey={key} stackId="stack" fill={getColor(index)} />
      ))}
    </BarChart>
  ) : (
    <div className="no-data-graph">No data to display</div>
  );
};

export default StackedBarVertical;
