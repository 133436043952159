/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import {
  Card,
  Col,
  Dropdown,
  Row,
  Space,
  Tooltip,
  Select,
  Divider,
  Switch,
  Spin,
} from 'antd';
import moment from 'moment';
import More from '../../assets/images/More.png';
import Fire from '../../assets/images/Fire.svg';
import Serving from '../../assets/images/Serving.svg';
import Clock from '../../assets/images/Clock.png';
import SubMenu from '../../assets/images/SubMenu.svg';
import Circle from '../../assets/images/Circle.png';
import Edit from '../../assets/images/Edit.svg';
import gram from '../../assets/images/gram.svg';
import Peanuts from '../../assets/images/peanuts.png';
// import approved from '../../assets/images/approved.png';
// import pending from '../../assets/images/pending.png';
// import rejected from '../../assets/images/rejected.png';
import subRecipeNotVerified from '../../assets/images/subRecipeNotVerified.png';
import subRecipeVerified from '../../assets/images/subRecipeVerified.png';
import ComboSubMenu from '../../assets/images/ComboSubMenu.png';
import './style.scss';
import Utils from '../../redux/utils';
import { handleFloat } from '../../helper/sortData';
import DonutChart from '../DonutChart';

type Props = {
  DropDownmenuProps: any;
  dataSource: any;
  handleOpen?: any;
  handleDialog: any;
  setSelectedMenu: any;
  component: string;
  handleToggle?: any;
  menuPropsNotVariation?: any;
  handledata: any;
  perm_status?: any;
  UTCtoLocal?: any;
  DropDownMenuWithoutApprovalProps?: any;
  processId?: any;
};

function GridView(props: Props) {
  const brand = Utils.getCurrentBrand();
  let {
    dataSource,
    DropDownmenuProps,
    handleOpen,
    setSelectedMenu,
    handleDialog,
    component,
    handleToggle,
    menuPropsNotVariation,
    handledata,
    perm_status,
    UTCtoLocal,
    DropDownMenuWithoutApprovalProps,
    processId,
  } = props;

  const [hide, setHide] = useState(-1);
  const [popUp, setPopUp] = useState<any>(null);
  const scrollPosition = React.useRef(0);

  useEffect(() => {
    const handleScroll = () => {
      setSelectedMenu(null);
    };
    if (dataSource) {
      window.addEventListener('scroll', handleScroll);
    }
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [dataSource, setSelectedMenu]);

  const handleSwitch = (e: any, index: any, data: any) => {
    if (e) {
      handleToggle(e, data, index);
    } else {
      handleToggle(e, data, index);
    }
  };

  const handleEdit = (data: any) => {
    scrollPosition.current = window.scrollY;
    handleDialog(true, data);
  };
  const handleChange = (
    data: any,
    index: number,
    list: any,
    variationIndex: number
  ) => {
    const dataArray: any = list.filter((val: any) => {
      return val.variation_id === data;
    });
    handledata(dataArray, index, variationIndex);
  };

  return (
    <div className="card-block">
      <Row className="grid-view" gutter={[12, 12]}>
        {dataSource?.map((data: any, index: number) => {
          return (
            <Col
              className="gutter-row"
              xxl={
                component == 'combos' ||
                component == 'manageMenu' ||
                component == 'menu'
                  ? 6
                  : 8
              }
              xl={8}
              lg={12}
              md={24}
              key={index}
            >
              <Card
                className="card"
                cover={
                  <div
                    className={
                      component !== 'manageMenu' && component !== 'combos'
                        ? 'cover'
                        : 'coverWithoutHover'
                    }
                    onMouseEnter={() => {
                      setHide(index);
                    }}
                    onMouseLeave={() => setHide(-1)}
                  >
                    <img
                      alt="example"
                      src={data?.thumbnail}
                      // width="360px"
                      height={'190px'}
                      className={`thumbnail ${
                        data?.is_default_image === 1 ? 'objectFit' : ''
                      }`}
                    />
                    <div className="imgtag">
                      {component == 'subRecipe' ||
                      component == 'productionRecipe' ||
                      component == 'recipe' ||
                      component == 'menu' ||
                      component == 'manageMenu' ||
                      component == 'combos'
                        ? data?.menu_category
                        : data?.category_name}
                    </div>
                    {/* {component === 'manageMenu' ? (
                      <div className="toggle">
                        <Switch
                        // className={Class[index]}
                        // onChange={(checked) => onChange(checked, index)}
                        />
                      </div>
                    ) : null} */}
                    {component !== 'manageMenu' && component !== 'combos' ? (
                      <div className={hide === index ? 'button' : 'buttonHide'}>
                        <span
                          className="buttonText"
                          onClick={() => handleOpen(data)}
                        >
                          View
                        </span>
                      </div>
                    ) : null}
                  </div>
                }
              >
                <div className="head">
                  <div className="col1Text font">
                    {component === 'combos' ? (
                      <Tooltip
                        title={
                          <div>
                            <div className="tooltipHead">Name</div>
                            <div className="tootipText">{data?.combo_name}</div>
                          </div>
                        }
                      >
                        {data?.combo_name?.length > 20
                          ? data?.combo_name?.substring(0, 20) + '...'
                          : data?.combo_name}
                      </Tooltip>
                    ) : (
                      <div>
                        {component === 'menu' ? (
                          <div
                            onClick={() => handleDialog(true, data)}
                            style={{ cursor: 'pointer' }}
                          >
                            {data?.recipe_name?.length > 20
                              ? data?.recipe_name?.substring(0, 20) + '...'
                              : data?.recipe_name}
                          </div>
                        ) : (
                          <Tooltip
                            title={
                              <div>
                                <div className="tooltipHead">Name</div>
                                <div className="tooltipText">
                                  {data?.recipe_name}
                                </div>
                              </div>
                            }
                          >
                            <div>
                              {data?.recipe_name?.length > 20
                                ? data?.recipe_name?.substring(0, 20) + '...'
                                : data?.recipe_name}
                            </div>
                          </Tooltip>
                        )}
                      </div>
                    )}
                  </div>
                  {component !== 'menu' &&
                  component !== 'manageMenu' &&
                  component !== 'combos' &&
                  component !== 'recipe' ? (
                    <Dropdown
                      onOpenChange={() => setSelectedMenu(data)}
                      menu={
                        component == 'recipeBuilder' &&
                        data?.status_type === 'Pending'
                          ? DropDownMenuWithoutApprovalProps
                          : DropDownmenuProps
                      }
                      trigger={['click']}
                      placement="bottomRight"
                      arrow={{ pointAtCenter: true }}
                    >
                      <a onClick={(e) => e.preventDefault()}>
                        <Space>
                          <div className="dots">
                            <img src={More} />
                          </div>
                        </Space>
                      </a>
                    </Dropdown>
                  ) : null}
                  {component == 'recipe' || component == 'combos' ? (
                    <Dropdown
                      onOpenChange={() => setSelectedMenu(data)}
                      menu={
                        data?.is_variation == 'no'
                          ? menuPropsNotVariation
                          : DropDownmenuProps
                      }
                      trigger={['click']}
                      placement="bottomRight"
                      arrow={{ pointAtCenter: true }}
                      getPopupContainer={(
                        triggerNode: HTMLElement
                      ): HTMLElement => {
                        const container = document.querySelector(
                          '.grid-view'
                        ) as HTMLElement | null;
                        return container
                          ? container
                          : (document.body as HTMLElement);
                      }}
                    >
                      <a onClick={(e) => e.preventDefault()}>
                        <Space>
                          <div className="dots">
                            <img src={More} />
                          </div>
                        </Space>
                      </a>
                    </Dropdown>
                  ) : null}
                </div>
                {component == 'recipe' ||
                component === 'menu' ||
                component === 'manageMenu' ? (
                  <div className="variationTag">
                    <div className="variationText">Variation :</div>

                    {/* {data?.variation_list?.length !== 0 ? ( */}
                    <div className="variationOption">
                      <Select
                        onChange={(e, obj: any) => {
                          handleChange(
                            e,
                            index,
                            data?.variation_list,
                            +obj?.key
                          );
                        }}
                        defaultValue={
                          data?.variation_list[0]?.variation
                            ? data?.variation_list[0]?.variation
                            : '-'
                        }
                        style={{ width: 120, marginTop: -5 }}
                        bordered={false}
                        disabled={data?.is_variation === 'no'}
                        // value={
                        //   data?.is_variation === 'yes' &&
                        //   data?.variation_list[data?.variationIndex ?? 0]
                        //     ?.variation
                        // }
                      >
                        {data?.variation_list?.map(
                          (data: any, innerindex: number) => (
                            <Select.Option
                              value={data?.variation_id}
                              key={innerindex}
                            >
                              {data.variation}
                            </Select.Option>
                          )
                        )}
                      </Select>
                    </div>
                  </div>
                ) : null}
                {component === 'recipeBuilder' ? (
                  <div className="recipeBuilder-grid-view-calorie">
                    <div className="subTextCol1 font">
                      <DonutChart
                        popUp={popUp}
                        setPopUp={setPopUp}
                        index={index}
                        data={data}
                      >
                        {data?.is_verified ? (
                          <img
                            src={Fire}
                            width={15}
                            alt=""
                            className=" iconFireWhite IconsAlignment"
                          />
                        ) : (
                          <img
                            src={Fire}
                            width={15}
                            alt=""
                            className="subCol1Icon IconsAlignment"
                          />
                        )}
                      </DonutChart>
                      <span className="col1Text font">
                        {handleFloat(data?.calorie)}
                      </span>
                      <span className="calories">kcal</span>
                    </div>
                    <div
                      className={`requestStatusContainer ${
                        data?.status_type?.toLowerCase() == 'pending'
                          ? 'inventoryTransfer-request-pending'
                          : data?.status_type?.toLowerCase() == 'approved'
                          ? 'inventoryTransfer-request-approve'
                          : 'inventoryTransfer-request-reject'
                      } `}
                    >
                      {/* {data?.status == 0 ? (
                        <img className="rd-approveIcon" src={pending} />
                      ) : data?.status == 1 ? (
                        <img className="rd-rejectIcon" src={rejected} />
                      ) : (
                        <img className="rd-approveIcon" src={approved} />
                      )} */}
                      {data?.status_type}
                    </div>
                  </div>
                ) : (
                  <div className="subTextCol1 font">
                    <DonutChart
                      popUp={popUp}
                      setPopUp={setPopUp}
                      index={index}
                      data={data}
                      component={'gridView'}
                    >
                      {data?.is_verified ? (
                        <img
                          src={Fire}
                          width={15}
                          alt=""
                          className=" iconFireWhite IconsAlignment"
                        />
                      ) : (
                        <img
                          src={Fire}
                          width={15}
                          alt=""
                          className="subCol1Icon IconsAlignment"
                        />
                      )}
                    </DonutChart>

                    <span className="col1Text font">
                      {component === 'combos'
                        ? handleFloat(data?.calorie)
                        : handleFloat(data?.calorie)}
                    </span>
                    <span className="calories">kcal</span>
                  </div>
                )}
                {component === 'recipeBuilder' ||
                component === 'menu' ||
                component === 'manageMenu' ||
                component === 'combos' ? (
                  <div className="bottom lineHeight gap">
                    <div className="bottom">
                      <Tooltip
                        title={
                          <div>
                            <div className="tooltipHead">Servings</div>
                            <div className="tootipText">
                              {data?.servings} person serving
                            </div>
                          </div>
                        }
                      >
                        <img src={Serving} alt="" className="icons" />
                      </Tooltip>

                      <span className="itemGap">{data?.servings}</span>
                    </div>
                    <img src={Circle} alt="" />
                    <div className="bottom">
                      <Tooltip
                        title={
                          <div>
                            <div className="tooltipHead">Sub recipe</div>
                            <div className="tootipText">
                              Total{' '}
                              {component === 'combos'
                                ? data?.sub_recipe_count
                                : component === 'recipeBuilder'
                                ? data?.sub_recipe_items
                                : data?.sub_recipe_count}{' '}
                              sub recipes
                            </div>
                          </div>
                        }
                      >
                        {component === 'combos' ? (
                          <img src={ComboSubMenu} alt="" className="icons" />
                        ) : (
                          <img
                            src={SubMenu}
                            width={15}
                            alt=""
                            className="icons"
                          />
                        )}{' '}
                        {/* <img
                          src={SubMenu}
                          width={15}
                          alt=""
                          className="icons"
                        /> */}
                      </Tooltip>
                      <span className="itemGap">
                        {component === 'combos'
                          ? handleFloat(data?.sub_recipe_count)
                          : component === 'recipeBuilder'
                          ? data?.sub_recipe_items
                          : data?.sub_recipe_count}
                      </span>
                    </div>
                    <img src={Circle} alt="" />
                    <div className="bottom">
                      <Tooltip
                        title={
                          <div className="tootipText">
                            {component === 'recipeBuilder'
                              ? moment(UTCtoLocal(data?.created_at)).format(
                                  'DD-MM-YYYY HH:mm:ss'
                                )
                              : moment(UTCtoLocal(data?.created_at)).format(
                                  'DD-MM-YYYY HH:mm:ss'
                                )}

                            {/* Created at Nov 23, 2022 12:23pm */}
                          </div>
                        }
                      >
                        <img src={Clock} alt="" className="icons" />
                      </Tooltip>
                      {moment(UTCtoLocal(data?.created_at)).format(
                        'DD-MM-YYYY'
                      )}
                      {/* {moment(data?.created_at).fromNow()} */}
                    </div>
                    {component === 'menu' && <img src={Circle} alt="" />}
                    {component === 'menu' && (
                      <div className="bottom">
                        <Tooltip
                          title={
                            <div>
                              <div className="tooltipHead">
                                Allergens Present:
                              </div>
                              <div className="tootipText">
                                {data?.allergen || ''}
                              </div>
                            </div>
                          }
                        >
                          <img
                            src={Peanuts}
                            alt=""
                            className="icons"
                            width={20}
                          />
                          {data?.allergen?.split(',')?.length || 0}
                        </Tooltip>
                      </div>
                    )}
                  </div>
                ) : null}
                {component === 'recipe' ? (
                  <div className="bottom lineHeight gap">
                    <div className="bottom">
                      <Tooltip
                        title={
                          <div>
                            <div className="tooltipHead">Servings</div>
                            <div className="tootipText">
                              {data?.servings} person serving
                            </div>
                          </div>
                        }
                      >
                        <img src={Serving} alt="" className="icons" />
                      </Tooltip>

                      <span className="itemGap">{data?.servings}</span>
                    </div>
                    <img src={Circle} alt="" />
                    <div className="bottom">
                      <Tooltip
                        title={
                          <div>
                            <div className="tooltipHead">Sub recipe</div>
                            <div className="tootipText">
                              Total {data?.subrecipe_count} sub recipe
                            </div>
                          </div>
                        }
                      >
                        <img
                          src={SubMenu}
                          width={15}
                          alt=""
                          className="icons"
                        />
                      </Tooltip>
                      <span className="itemGap">{data?.subrecipe_count}</span>
                      {/* <span className="itemGap">sub recipe</span> */}
                    </div>
                    <img src={Circle} alt="" />
                    <div className="bottom">
                      <Tooltip
                        title={
                          <div className="tootipText">
                            {moment(data?.created_at).format(
                              'DD-MM-YYYY HH:mm:ss'
                            )}

                            {/* Created at Nov 23, 2022 12:23pm */}
                          </div>
                        }
                      >
                        <img src={Clock} alt="" className="icons" />
                      </Tooltip>
                      {moment(UTCtoLocal(data?.created_at)).format(
                        'DD-MM-YYYY'
                      )}
                      {/* {moment(data?.created_at).fromNow()} */}
                    </div>
                  </div>
                ) : null}

                {component === 'subRecipe' ||
                component == 'productionRecipe' ? (
                  <div className="bottom lineHeight gap">
                    <div className="bottom">
                      <Tooltip
                        title={
                          <div>
                            <div className="tooltipHead">Sub recipe</div>
                            <div className="tootipText">
                              Total{' '}
                              {component === 'subRecipe'
                                ? data?.total_ingredients
                                : data?.total_sub_recipe}{' '}
                              ingredients
                            </div>
                          </div>
                        }
                      >
                        <img
                          src={SubMenu}
                          width={15}
                          alt=""
                          className="icons"
                        />
                      </Tooltip>
                      <span className="itemGap">
                        {component === 'subRecipe'
                          ? data?.total_ingredients
                          : data?.total_sub_recipe}{' '}
                        ingredients
                      </span>
                    </div>
                    <img src={Circle} alt="" />
                    <div className="bottom">
                      <Tooltip
                        title={
                          <div className="tootipText">
                            {component === 'productionRecipe'
                              ? 'Production Recipe unit is' + ' ' + data?.unit
                              : ''}
                            {component === 'subRecipe'
                              ? 'Sub Recipe unit is' + ' ' + data?.unit
                              : ''}
                          </div>
                        }
                      >
                        <img
                          src={gram}
                          width={15}
                          alt=""
                          className="icons"
                          style={{ marginRight: '2px' }}
                        />
                      </Tooltip>
                      {data?.unit}
                    </div>
                  </div>
                ) : null}
                {/* {component === 'recipe' ? (
                  <div className="bottomForRecipe lineHeight gap">
                    <div className="bottom"></div>
                    <div className="bottom">
                      <span className="itemGap rupeesTag">
                        {brand?.currency_name}
                      </span>
                      <span className="itemGap rupeesTag">
                        {data?.final_price}
                      </span>
                    </div>
                  </div>
                ) : null} */}
                {component === 'productionRecipe' ||
                // component === 'menu' ||
                component === 'manageMenu' ||
                component === 'combos' ? (
                  <div className="bottomForRecipe grid-menu-price-line-height gap">
                    <div className="bottom"></div>
                    <div className="bottom">
                      <span className="itemGap rupeesTag">
                        {brand?.currency_name}{' '}
                        {component === 'combos'
                          ? handleFloat(data?.total_cost)
                          : component == 'manageMenu' &&
                            data?.is_variation === 'yes' &&
                            data?.variationIndex
                          ? handleFloat(
                              data?.variation_list[data?.variationIndex ?? 0]
                                ?.final_price
                            )
                          : handleFloat(data?.final_price)}
                      </span>
                    </div>
                  </div>
                ) : null}
                {component === 'menu' ? (
                  <div className="bottomForRecipe grid-menu-price-line-height gap">
                    <div className="bottom"></div>

                    <div className="bottom actual-price-color-list">
                      {/* {brand?.currency_name + ' ' + record?.final_price} */}

                      <Tooltip
                        title={
                          <div>
                            {/* <div className="tooltipHead">
                                  Profitability %:
                                </div> */}
                            <div className="tootipText">
                              Suggested Price : {data?.propose_price}
                            </div>
                          </div>
                        }
                      >
                        <div className="percentagePrice">
                          ({data?.propose_price})
                        </div>
                      </Tooltip>
                      <Tooltip
                        title={
                          <div>
                            {/* <div className="tooltipHead">
                                  Profitability %:
                                </div> */}
                            <div className="tootipText">
                              Selling Price : {handleFloat(data?.final_price)}
                            </div>
                          </div>
                        }
                      >
                        <div className="rupeesTag cursor">
                          {`${brand?.currency_name}  ${handleFloat(
                            data?.final_price
                          )}`}
                        </div>
                      </Tooltip>
                      <Tooltip
                        title={
                          <div>
                            {/* <div className="tooltipHead">
                                  Profitability %:
                                </div> */}
                            <div className="tootipText">
                              Profitability % : {data?.profitability}
                            </div>
                          </div>
                        }
                      >
                        <div className="percentagePrice">
                          ({data?.profitability}%)
                        </div>
                      </Tooltip>
                    </div>
                  </div>
                ) : null}
                {component === 'manageMenu' ? (
                  perm_status === 2 ? (
                    <Divider type="horizontal" />
                  ) : null
                ) : (
                  ''
                )}

                {component === 'manageMenu' ? (
                  perm_status === 2 ? (
                    <div className="edit" onClick={() => handleEdit(data)}>
                      <img src={Edit} width={15} />
                      <span> Edit</span>
                    </div>
                  ) : null
                ) : null}
              </Card>
              {component === 'manageMenu' ? (
                perm_status === 2 ? (
                  <div className="toggle">
                    {processId === data?.id ? (
                      <Spin spinning={true} className="recipeLoader" />
                    ) : (
                      <Switch
                        className={data?.status === 1 ? 'green' : 'red'}
                        checked={data?.status === 1}
                        onChange={(checked) => {
                          handleSwitch(checked, index, data);
                        }}
                        defaultChecked={data?.status === 1}
                      />
                    )}
                    {/* <Checkbox
                    checked={data.status === 1 ? true : false}
                    onChange={(checked) => handleSwitch(checked, index, data)}
                  /> */}
                  </div>
                ) : null
              ) : (
                ''
              )}
            </Col>
          );
        })}
      </Row>
    </div>
  );
}

export default GridView;
