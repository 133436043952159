/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Row,
  Col,
  PaginationProps,
  Spin,
  Tooltip,
  Select,
  Dropdown,
  Space,
} from 'antd';
import './menu.scss';
import type { MenuProps } from 'antd';
import {
  Page,
  GridView,
  ListView,
  Pagination,
  Breadcrumb,
  // Dialog,
  // SubDialog,
  ListCount,
  SearchBox,
  MenuBar,
  MenuModal,
  ResponseDialog,
  IconSwitcher,
  DonutChart,
  // DeleteConfirmation,
} from '../../components';
import MenuActions from '../../redux/middleware/menu';
import Download from '../../assets/images/Download.svg';
import Delete from '../../assets/images/Delete.svg';
import Edit from '../../assets/images/Edit.svg';
import Union from '../../assets/images/Union.png';
import Fire from '../../assets/images/Fire.svg';
import Clock from '../../assets/images/Clock.png';
import Serving from '../../assets/images/Serving.svg';
import SubMenu from '../../assets/images/SubMenu.svg';
import FireWhite from '../../assets/images/FireWhite.png';
import Peanuts from '../../assets/images/peanuts.png';
import DownRed from '../../assets/images/DownRed.png';
import DownGreen from '../../assets/images/DownGreen.png';
import { useSelector } from 'react-redux';
import Utils from '../../redux/utils';
import moment from 'moment';
import { checkAuthentication } from '../../helper/checkAuthentication';
import { handleFloat, sortNumber, sortedData } from '../../helper/sortData';
import UpDownArrow from '../../assets/images/UpDownArrow.png';

const items: MenuProps['items'] = [
  {
    key: '1',
    label: <div className="dotOption">Add Variation</div>,
    icon: <img src={Union} className=" duplicateIcon" />,
  },
  {
    key: '2',
    label: <div className="dotOption">Download</div>,
    icon: <img src={Download} width={15} />,
  },
  {
    key: '3',
    label: <div className="dotOption">Edit</div>,
    icon: <img src={Edit} width={15} />,
  },
  {
    type: 'divider',
  },
  {
    key: '4',
    label: <div className="dotOption deleteText">Delete</div>,
    icon: <img src={Delete} width={15} />,
  },
];

const ManageMenu = () => {
  const user = Utils.getCurrentUser();
  const brand = Utils.getCurrentBrand();
  const company = Utils.getCurrentCompany();
  const [menuCount, setMenuCount] = useState(0);
  const [ingrdientCount, setIngredientCount] = useState(0);
  const [design, setDesign] = useState(true);
  const [ascend, setAscend] = useState(true);
  const [popUp, setPopUp] = useState<any>(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [modalData, setModalData] = useState({});
  const [openSubDialog, setOpenSubDialog] = useState(false);
  const [current, setCurrent] = useState(1);
  const [sortData, setSortData] = useState<any>([]);
  const [manageMenu, setMangeMenu] = useState([{}]);
  const [dataLength, setDataLength] = useState(50);
  const [dialogData] = useState({});
  const [total, setTotal] = useState(0);
  const [recipeLoader, setRecipeLoader] = useState(false);
  const [selectedRecipeId, setSelectedRecipeId] = useState('');
  const [recipeTypes, setRecipeTypes] = useState('');
  const PageDataLength = [6, 10, 20, 50];
  const [permissionStatus, setPermissionStatus] = useState(0);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [search, setSearch] = useState('');
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [selectedMenuFilter, setMenuFilter] = useState('');
  const toggleRef = React.useRef(null as any);
  toggleRef.current = false;

  const [processId, setProcessId] = useState<any>(null);

  const scrollPosition = React.useRef(0);

  const columns = [
    {
      title: () => (
        <div className="tableTitle menu-col">
          <span className="menu-col">MENU TITLE</span>
          <img
            src={UpDownArrow}
            className="titleArrow"
            onClick={() => {
              handleSort('recipe_name', 'string');
              setAscend(!ascend);
            }}
          />
        </div>
      ),
      dataIndex: 'recipe_name',
      key: 'recipe_name',
      responsive: ['md'],
      render: (data: any, record: any, index: number) => {
        return (
          <div className="font menu-col">
            <img
              className="listView-image-align"
              src={record?.thumbnail}
              width="52px"
              height="52px"
            />
            <div>
              <div className="subCol1 titleContainer font">
                <span
                  className="table-title1-hover cursor"
                  onClick={() => {
                    handleingredient();
                    handleDialog(true, record);
                  }}

                  // onClick={() => {
                  //   setDialogData(record);
                  //   setOpenDialog(true);
                  // }}
                >
                  {record?.recipe_name?.length > 22 ? (
                    <Tooltip
                      title={
                        <div>
                          <div className="tootipText">
                            {record?.recipe_name}
                          </div>
                        </div>
                      }
                    >
                      {record?.recipe_name?.substring(0, 22)}
                      {record?.recipe_name?.length > 22 ? (
                        <span>...</span>
                      ) : null}
                    </Tooltip>
                  ) : (
                    record?.recipe_name
                  )}
                </span>
                <span>
                  <div className="categoryPill">
                    <div className="pillText">{record?.menu_category}</div>
                  </div>
                </span>
              </div>
              <div className="col3 cellSpacing  common-flex-center calorie-serving-subrecipe-gap">
                <div className="common-flex-center IconsAlignment">
                  <DonutChart
                    popUp={popUp}
                    setPopUp={setPopUp}
                    index={index}
                    data={record}
                    component={'listView'}
                  >
                    {record.is_verified ? (
                      <img
                        src={FireWhite}
                        className="subCol1Icon iconFireWhite"
                      />
                    ) : (
                      <img src={Fire} width={15} className="subCol1Icon" />
                    )}
                  </DonutChart>
                  {!record?.calorie && record?.is_variation == 'yes'
                    ? handleFloat(record?.variation_list[0]?.calorie)
                    : handleFloat(record?.calorie)}
                </div>
                <div className="tableCol col3 common-flex-center">
                  <Tooltip
                    title={
                      <div>
                        <div className="tooltipHead">Servings</div>
                        <div className="tootipText">
                          {record?.servings} person serving
                        </div>
                      </div>
                    }
                  >
                    <img src={Serving} alt="" className="icons" />
                  </Tooltip>
                  {record?.servings}
                </div>
                <div className="tableCol col3 common-flex-center">
                  <Tooltip
                    title={
                      <div>
                        <div className="tooltipHead">Sub recipe</div>
                        <div className="tootipText">
                          Total {record?.sub_recipe_count} sub recipes
                        </div>
                      </div>
                    }
                  >
                    <img src={SubMenu} width={15} alt="" className="icons" />
                  </Tooltip>
                  {record?.sub_recipe_count}
                </div>
                <div className="tableCol col3 common-flex-center">
                  <Tooltip
                    title={
                      <div>
                        {moment(UTCtoLocal(record?.created_at)).format(
                          'DD-MM-YYYY HH:mm:ss'
                        )}
                      </div>
                    }
                    className="common-flex-center"
                  >
                    <img src={Clock} alt="" />
                  </Tooltip>
                </div>
                <div className="tableCol col3 common-flex-center">
                  <Tooltip
                    title={
                      <div>
                        <div className="tooltipHead">Allergens Present:</div>
                        <div className="tootipText">
                          {record?.allergen || ''}
                        </div>
                      </div>
                    }
                    className="common-flex-center"
                  >
                    <img src={Peanuts} alt="" className="icons" width={20} />
                    {record?.allergen?.split(',')?.length || 0}
                  </Tooltip>
                </div>
                {record?.variation_list?.length !== 0 ? (
                  <Select
                    onChange={(e) => {
                      handleChange(e, index, record?.variation_list);
                    }}
                    defaultValue={record?.variation_list?.[0].variation}
                    style={{}}
                    bordered={false}
                  >
                    {record?.variation_list?.map(
                      (data: any, innerindex: number) => (
                        <Select.Option
                          value={data?.variation_id}
                          key={innerindex}
                        >
                          {data.variation}
                        </Select.Option>
                      )
                    )}
                  </Select>
                ) : (
                  ''
                )}
              </div>
            </div>
          </div>
        );
      },
    },
    {
      title: () => (
        <div className="tableTitle">
          <span>SUGGESTED PRICE</span>
          <img
            src={UpDownArrow}
            className="titleArrow"
            onClick={() => {
              handleSort('propose_price', 'number');
              setAscend(!ascend);
            }}
          />
        </div>
      ),
      dataIndex: 'propose_price',
      key: 'propose_price',
      responsive: ['md'],
      render: (data: any, record: any) => {
        return (
          <Tooltip
            title={
              <div>
                <div className="tootipText">
                  Suggested Price : {record?.propose_price}
                </div>
              </div>
            }
          >
            <div className="col3">{record?.propose_price}</div>
          </Tooltip>
        );
      },
    },
    {
      title: () => (
        <div className="tableTitle">
          <span> SELLING PRICE</span>

          <img
            src={UpDownArrow}
            className="titleArrow"
            onClick={() => {
              handleSort('final_price', 'number');
              setAscend(!ascend);
            }}
          />
        </div>
      ),
      dataIndex: 'final_price',
      key: 'final_price',
      responsive: ['md'],
      render: (data: any, record: any) => {
        return (
          <div className="col3 actual-price-color-list">
            <Tooltip
              title={
                <div>
                  <div className="tootipText">
                    Selling Price : {handleFloat(record?.final_price)}
                  </div>
                </div>
              }
            >
              <div className="rupeesTag cursor selling-price">
                {brand?.currency_name} {handleFloat(record?.final_price)}
              </div>
            </Tooltip>
          </div>
        );
      },
    },
    {
      title: () => (
        <div className="tableTitle">
          <span>PROFITABILITY %</span>
          <img
            src={UpDownArrow}
            className="titleArrow"
            onClick={() => {
              handleSort('profitability', 'number');
              setAscend(!ascend);
            }}
          />
        </div>
      ),
      dataIndex: 'profitability',
      key: 'profitability',
      responsive: ['md'],
      render: (data: any, record: any) => {
        return (
          <Tooltip
            title={
              <div>
                <div className="tootipText">
                  Profitability % : {record?.profitability}
                </div>
              </div>
            }
          >
            <div className="col3">{record?.profitability}%</div>
          </Tooltip>
        );
      },
    },
    {
      title: () => (
        <div className="tableTitle">
          <span>STATUS</span>
          <img
            src={UpDownArrow}
            className="titleArrow"
            onClick={() => {
              handleSort('status', 'number');
              setAscend(!ascend);
            }}
          />
        </div>
      ),
      dataIndex: 'status',
      key: 'status',
      responsive: ['md'],
      render: (data: any, record: any) => (
        <Dropdown
          overlayStyle={{ height: '100px' }}
          menu={menuDropdownProps}
          trigger={['click']}
          placement="bottomRight"
          arrow={{ pointAtCenter: true }}
        >
          <a
            onClick={() => {
              setSelectedRecipeId(record?.id);
              setRecipeTypes(record?.item_type);
            }}
          >
            <Space>
              <div
                className={
                  record?.status === 1
                    ? 'statusContainerActive'
                    : 'statusContainerNonActive'
                }
              >
                <div className="status">
                  {record?.status == 1 ? 'Active' : 'Non-active'}
                </div>
                <img src={record?.status === 1 ? DownGreen : DownRed} alt="" />
              </div>
            </Space>
          </a>
        </Dropdown>
      ),
    },
  ];

  const items1: MenuProps['items'] = [
    {
      key: '1',
      label: (
        <div
          className="dotOption"
          onClick={async () => {
            const statusChangeResponse = await MenuActions.changeStatusMenu(
              selectedRecipeId,
              1,
              recipeTypes
            );
            const { success, error } = statusChangeResponse;
            if (success) {
              let filterData = sortData;
              setSortData(filterData);
              toggleRef.current = false;
              setProcessId(null);
            } else {
              toggleRef.current = false;
              setProcessId(null);
              const { id = 'Something went wrong, Please try again' } = error;
              const responseDialogData = {
                OnClick: () => {},
                type: 'error',
                messageArray: ['Menu Enabling Failed!', `${id}`],
              };
              ResponseDialog(responseDialogData);
            }
          }}
        >
          {'Active'}
        </div>
      ),
    },
    {
      type: 'divider',
    },
    {
      key: '2',
      label: (
        <div
          className="dotOption deleteText"
          onClick={async () => {
            const statusChangeResponse = await MenuActions.changeStatusMenu(
              selectedRecipeId,
              0,
              recipeTypes
            );
            const { success, error } = statusChangeResponse;
            if (success) {
              let filterData = sortData;
              setSortData(filterData);
              toggleRef.current = false;
              setProcessId(null);
            } else {
              toggleRef.current = false;
              setProcessId(null);
              const { id = 'Something went wrong, Please try again' } = error;
              const responseDialogData = {
                OnClick: () => {},
                type: 'error',
                messageArray: ['Menu Enabling Failed!', `${id}`],
              };
              ResponseDialog(responseDialogData);
            }
          }}
        >
          {'Not Active'}{' '}
        </div>
      ),
    },
  ];

  const handleMenuDropdown: MenuProps['onClick'] = (e: any) => {
    setDataLength(PageDataLength[+e?.key]);
  };

  const menuDropdownProps = {
    items: items1,
    onClick: handleMenuDropdown,
  };

  const handleChange = (data: any, index: number, list: any) => {
    const selectedVariation: any = list.find((val: any) => {
      return val.variation_id === data;
    });
    handledata(selectedVariation, index);
  };

  const handleSort = (column: string, type: string) => {
    let arr =
      type == 'string'
        ? sortedData(ascend, sortData, column)
        : sortNumber(ascend, sortData, column);

    setSortData(() => arr);
  };

  const handleDialog = (checked: boolean, data: any) => {
    scrollPosition.current = window.scrollY;
    setOpen(checked);
    setModalData(data);
  };
  const setSelectedMenu = (recipe: any) => {
    setSelectedRecipeId(recipe.rb_id);
  };
  const { currentBrand, currentShop } = useSelector((state: any) => state.Auth);
  const [allowRequest, setAllowRequest] = useState(false);
  const [keyword, setKeyword] = useState('');
  const [currentBrandId, setCurrentBrandId] = useState(currentBrand?.brand_id);
  useEffect(() => {
    if (currentBrandId !== currentBrand?.brand_id) {
      setKeyword('');
      setAllowRequest(true);
    }
  }, [currentBrand, currentShop]);

  useEffect(() => {
    if (allowRequest) {
      pageData();
      window.scrollTo(0, scrollPosition.current);
      setAllowRequest(false);
    }
  }, [allowRequest]);

  // useEffect(() => {
  //   setCurrent(1);
  // }, []);

  useEffect(() => {
    if (dataLength && current) {
      pageData();
      window.scrollTo(0, scrollPosition.current);
    }
  }, [dataLength, current, currentBrand]);
  useEffect(() => {
    checkAuthentication(user, navigate);
    let permissions = user?.permissions;
    const data = permissions?.filter(
      (permission: any) => permission?.permission === 'Manage Menu'
    );
    data?.length && setPermissionStatus(data[0]?.perm_status_id);
    setCurrent(1);
  }, []);
  const item1: MenuProps['items'] = PageDataLength.map((data, index) => {
    return {
      key: `${index}`,
      label: <div className="font dotOption">{data}</div>,
    };
  });

  const UTCtoLocal = (utcDate: any) => {
    var stillUtc = moment.utc(utcDate).toDate();
    var local = moment(stillUtc).local();
    return local;
  };

  const handleMenuClick: MenuProps['onClick'] = (e: any) => {
    setDataLength(PageDataLength[+e?.key]);
  };

  const handleingredient = async () => {
    setOpenDialog(true);
  };

  const menuProps = {
    items: item1,
    onClick: handleMenuClick,
  };

  const onChange: PaginationProps['onChange'] = (page) => {
    setCurrent(page);
  };

  const handleDropDownClick: MenuProps['onClick'] = async (e: any) => {
    let option = +e.key;
    switch (option) {
      case 3:
        navigate('wizard', { state: { update: true, id: selectedRecipeId } });
        break;

      case 4:
        setOpenDeleteDialog(true);
        break;
      default:
    }
  };

  const DropDownmenuProps = {
    items: items,
    onClick: handleDropDownClick,
  };

  const pageData = async () => {
    const brand = await Utils.getCurrentBrand();
    setRecipeLoader(true);
    const recipeData = await MenuActions.getManageMenu(
      dataLength,
      current,
      currentBrand?.brand_id || brand?.brand_id,
      keyword
    );
    setCurrentBrandId(currentBrand?.brand_id);
    if (recipeData === undefined) {
      setMenuCount(0);
      setIngredientCount(0);
      setTotal(0);
      setSortData([]);
    } else {
      const { items } = recipeData?.data || null;
      if (items?.length > 0) {
        // const filterList = items.filter((x: any) => x.item_type != 'combo');
        setMenuCount(recipeData?.data?.menu_count);
        setIngredientCount(recipeData?.data?.ingredients_count);
        setTotal(recipeData?.data?.pagination?.total);
        setMangeMenu(recipeData?.data?.items);
        setSortData(recipeData?.data?.items);
      }
    }
    window.scrollTo(0, scrollPosition.current);
    setRecipeLoader(false);
  };

  const handleMenuFilter = async (filter: any) => {
    setRecipeLoader(true);
    const recipeData = await MenuActions.manageMenuPillFilter(
      dataLength,
      current,
      filter
    );
    if (recipeData === undefined) {
      setMenuCount(0);
      setIngredientCount(0);
      setTotal(0);
      setSortData([]);
    } else {
      setMenuFilter(filter);
      setMenuCount(recipeData?.data?.menu_count);
      setIngredientCount(recipeData?.data?.ingredients_count);
      setTotal(recipeData?.data?.pagination?.total);
      setSortData(recipeData?.data?.items);
    }
    setRecipeLoader(false);
  };
  const handlemodal = () => {
    window.scrollTo(0, scrollPosition.current);
    setOpen(false);
    // pageData();
  };
  const onSubmit = () => {
    setOpen(false);
    handleMenuFilter(selectedMenuFilter);
    window.scrollTo(0, scrollPosition.current);
  };

  const onSearch = async (keyword: string) => {
    setSearch(keyword);
    setRecipeLoader(true);
    // if (keyword) {
    //   const results = sortData.filter(
    //     (obj: any) =>
    //       obj.recipe_name?.toLowerCase().includes(keyword.toLowerCase()) ||
    //       obj.calorie?.toString().includes(keyword.toString()) ||
    //       obj.propose_price?.toString()?.includes(keyword.toString()) ||
    //       obj.servings?.includes(keyword) ||
    //       obj.sub_recipe_count?.includes(keyword) ||
    //       obj.created_at?.includes(keyword)
    //   );
    //   setSortData(results);
    // } else {
    //   setSortData(manageMenu);
    // }

    const searchResultofRecipes = await MenuActions.searchManageMenu(
      dataLength,
      current,
      keyword
    );
    const { success } = searchResultofRecipes;
    if (!success) {
      setMenuCount(0);
      setIngredientCount(0);
      setTotal(0);
      setSortData([]);
    } else {
      setMenuCount(searchResultofRecipes?.data?.menu_count);
      setIngredientCount(searchResultofRecipes?.data?.ingredients_count);
      setTotal(searchResultofRecipes?.data?.pagination?.total);
      const { items } = searchResultofRecipes?.data || null;
      if (items?.length > 0 && keyword === '') {
        const filterList = items.filter((x: any) => x.item_type != 'combo');
        setSortData(filterList || searchResultofRecipes?.data?.items);
      } else {
        setSortData(searchResultofRecipes?.data?.items);
      }
    }
    setRecipeLoader(false);
  };

  const handledata = (data: any, index: number, variationIndex?: number) => {
    let items: any = sortData;
    items[index].servings = data[0]?.servings;
    items[index].calorie = data[0]?.calorie;
    items[index].carb = data[0]?.carb;
    items[index].fat = data[0]?.fat;
    items[index].created_at = data[0]?.created_at;
    items[index].final_price = data[0]?.final_price;
    items[index].total_cost = data[0]?.total_cost;
    items[index].variationIndex = variationIndex;
    setSortData([...items]);
  };

  const handleToggle = async (checked: any, data: any, index: any) => {
    if (!toggleRef.current) {
      let status = checked ? 1 : 0;
      toggleRef.current = true;
      setProcessId(data?.id);
      const type = data.item_type === 'combo' ? 'combos' : data.item_type;
      const statusChangeResponse = await MenuActions.changeStatusMenu(
        data.id,
        status,
        type
      );

      const { success, error } = statusChangeResponse;

      if (success) {
        let filterData = sortData;
        filterData[index].status = status;
        setSortData(filterData);
        toggleRef.current = false;
        setProcessId(null);
      } else {
        toggleRef.current = false;
        setProcessId(null);
        const { id = 'Something went wrong, Please try again' } = error;
        const responseDialogData = {
          OnClick: () => {},
          type: 'error',
          messageArray: ['Menu Enabling Failed!', `${id}`],
        };
        ResponseDialog(responseDialogData);
      }
    }
  };

  const handleDeleteCancel = () => {
    setOpenDeleteDialog(false);
  };
  const handleDeleteContinue = async () => {
    setOpenDeleteDialog(false);
  };

  return (
    <div className="manageMenu">
      {open ? (
        <MenuModal
          handleDialog={handlemodal}
          open={open}
          modalData={modalData}
          onSubmit={onSubmit}
        />
      ) : null}

      {/* <DeleteConfirmation
        handleDeleteCancel={handleDeleteCancel}
        handleDeleteContinue={handleDeleteContinue}
        open={openDeleteDialog}
        message={'Are you sure want to delete menu?'}
      /> */}

      {/* <Dialog
        open={openDialog}
        handleClose={() => setOpenDialog(false)}
        handleSubDialog={() => {
          setOpenDialog(false);
          setOpenSubDialog(true);
        }}
        data={dialogData}
        component={'recipe'}
      />
      <SubDialog
        open={openSubDialog}
        data={dialogData}
        handleSubDialog={() => {
          setOpenSubDialog(false);
          setOpenDialog(true);
        }}
        handleCloseAll={() => {
          setOpenSubDialog(false);
          setOpenDialog(false);
        }}
      /> */}

      <Breadcrumb
        heading="Manage Menu"
        breadcrumbIcon
        iconAction={() => {
          navigate('/app/Dashboard');
        }}
      />
      <Page title="Dashboard">
        <Row className="listingTopFilter">
          <Col lg={10}>
            <ListCount
              count1={menuCount}
              title1="Total Menu"
              // count2={ingrdientCount}
              // title2="Total Ingredients"
            />
          </Col>
          <Col lg={14} md={24}>
            <div className="search-wrapper">
              <div className="search-box">
                <SearchBox
                  keyword={keyword}
                  setKeyword={setKeyword}
                  action={onSearch}
                  placeholderText="Search..."
                />
              </div>
              <IconSwitcher
                component="manageMenu"
                listViewLayout={design}
                setListView={setDesign}
              />
            </div>
          </Col>
        </Row>

        <Row className="">
          <Col lg={24} md={16}>
            <MenuBar
              component="manageMenu"
              handleSelectedPill={handleMenuFilter}
              allowMorePills={design}
              pillRender={currentBrandId}
            />
            {!design && !recipeLoader ? (
              <GridView
                handledata={handledata}
                DropDownmenuProps={DropDownmenuProps}
                setSelectedMenu={setSelectedMenu}
                dataSource={sortData}
                handleToggle={handleToggle}
                component={'manageMenu'}
                perm_status={permissionStatus}
                handleDialog={handleDialog}
                handleOpen={() => {
                  handleingredient();
                }}
                UTCtoLocal={UTCtoLocal}
                processId={processId}
              />
            ) : (
              !design && (
                <div className="loader-wrapper">
                  <Spin spinning={recipeLoader} className="recipeLoader" />
                </div>
              )
            )}

            {design && !recipeLoader ? (
              <ListView
                limit={dataLength}
                items={items}
                dataSource={[...sortData]}
                columns={columns}
                component={'manageMenu'}
              />
            ) : (
              design && (
                <div className="loader-wrapper">
                  <Spin spinning={recipeLoader} className="recipeLoader" />
                </div>
              )
            )}

            <Pagination
              total={total}
              menuProps={menuProps}
              onChange={onChange}
              current={current}
              dataLength={dataLength >= total ? total : dataLength}
              handleLength={(data: number) => setDataLength(data)}
            />
          </Col>
        </Row>
      </Page>
    </div>
  );
};
export default ManageMenu;
