import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { usePostHog } from 'posthog-js/react';

const PostHogPageviewTracker = () => {
  const location = useLocation();
  const posthog = usePostHog();
  useEffect(() => {
    if (posthog) {
      posthog.capture('$pageleave', {
        url: window.location.href,
        timestamp: new Date().toISOString(),
      });
    }
  }, [location, posthog]);

  return null;
};

export default PostHogPageviewTracker;
