/* eslint-disable no-unused-vars */
import React from 'react';
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
} from 'recharts';

interface AreaChartProps {
  isMulti?: boolean;
  data: any[];
  source?: any;
  brandcount?: number;
}

export default function App(props: AreaChartProps) {
  const { isMulti = false, data, source, brandcount } = props;

  return data && data.length > 0 ? (
    <AreaChart
      width={560}
      height={200}
      data={data}
      margin={{
        top: 10,
        right: 30,
        left: 0,
        bottom: 0,
      }}
    >
      <defs>
        <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
          <stop offset="0%" stopColor="#FEBD2F" stopOpacity={0.1} />
          <stop offset="90%" stopColor="white" stopOpacity={0.8} />
        </linearGradient>
      </defs>
      <CartesianGrid horizontal={true} vertical={false} stroke="#E0E0E0" />
      <XAxis
        dataKey="month"
        // tickFormatter={formatNumberWithEllipsis}
        tick={{ fill: '#4B465C' }}
      />
      <YAxis tick={{ fill: '#4B465C' }} stroke="transparent" />
      <Tooltip />
      <Area
        type="monotone"
        dataKey="turnoverRate"
        stroke="#4973A4"
        strokeWidth={3}
        fill="url(#colorUv)"
        fillOpacity={1}
      />
    </AreaChart>
  ) : (
    <div className="no-data-graph">No data to display</div>
  );
}
