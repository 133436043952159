/* eslint-disable no-unused-vars */
// StripeProvider.tsx
import React, { useEffect, useState } from 'react';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import SubscriptionApi from '../../../redux/middleware/subscription';
import { stripe_integration_key } from '../../../config/constant';

const stripePromise = loadStripe(stripe_integration_key);

const StripeProvider: React.FC<
  React.PropsWithChildren<{ selectedPlan: any }>
> = ({ children, selectedPlan }) => {
  const [clientSecret, setClientSecret] = useState<string | null>(null);

  useEffect(() => {
    if (selectedPlan) getClientIntent(selectedPlan);
  }, [selectedPlan]);

  const getClientIntent = async (plan: any) => {
    const { amount, currency } = plan;
    const params = {
      amount,
      currency,
    };
    const response = await SubscriptionApi.createSubscriptionIntent(params);
    const { data } = response;

    setClientSecret(data?.clientSecret);
  };

  if (!clientSecret) {
    return null;
  }

  const options = {
    clientSecret,
  };

  return (
    <Elements stripe={stripePromise} options={options}>
      {children}
    </Elements>
  );
};

export default StripeProvider;
