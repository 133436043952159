/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { PlusOutlined } from '@ant-design/icons';
import {
  Row,
  Col,
  PaginationProps,
  Dropdown,
  Space,
  Spin,
  Tooltip,
} from 'antd';
import type { MenuProps } from 'antd';
import {
  Page,
  GridView,
  Pagination,
  Breadcrumb,
  Button,
  Dialog,
  SubDialog,
  ListCount,
  SearchBox,
  IconSwitcher,
  DeleteConfirmation,
  DonutChart,
  ListViewWithoutExpand,
  ResponseDialog,
} from '../../components';
import RecipeActions from '../../redux/middleware/recipe';
import ComboActions from '../../redux/middleware/combo';
import {
  handleFloat,
  sortNumber,
  sortedData,
  formatNumberFloatValue,
} from '../../helper/sortData';
import Serving from '../../assets/images/Serving.svg';
import SubMenu from '../../assets/images/SubMenu.svg';
import Fire from '../../assets/images/Fire.svg';
import More from '../../assets/images/More.png';
import Delete from '../../assets/images/Delete.svg';
import Edit from '../../assets/images/Edit.svg';
import FireWhite from '../../assets/images/FireWhite.png';
import UpDownArrow from '../../assets/images/UpDownArrow.png';
import moment from 'moment';
import { useSelector } from 'react-redux';
import './styles.scss';
import Utils from '../../redux/utils';
import { checkAuthentication } from '../../helper/checkAuthentication';
const items: MenuProps['items'] = [
  {
    key: '1',
    label: <div className="dotOption dropdownLabels">Edit</div>,
    icon: <img src={Edit} width={15} />,
  },
  {
    type: 'divider',
  },
  {
    key: '2',
    label: <div className="dotOption deleteText dropdownLabels">Delete</div>,
    icon: <img src={Delete} width={15} />,
  },
];

const PageDataLength = [6, 10, 20, 50];

const Combos = () => {
  const user = Utils.getCurrentUser();
  const brand = Utils.getCurrentBrand();
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [design, setDesign] = useState(true);
  const [openDialog, setOpenDialog] = useState(false);
  const [openSubDialog, setOpenSubDialog] = useState(false);
  const [current, setCurrent] = useState(1);
  const [sortData, setSortData] = useState([{}]);
  const [combos, setCombos] = useState([{}]);
  const [ascend, setAscend] = useState(true);
  const [dataLength, setDataLength] = useState(50);
  const [dialogData, setDialogData] = useState({});
  const [total, setTotal] = useState(0);
  const [recipeLoader, setRecipeLoader] = useState(false);
  const [recipeCount, setRecipeCount] = useState(0);
  const [ingrdientCount, setIngredientCount] = useState(0);
  const [permissionStatus, setPermissionStatus] = useState(0);
  const [search, setSearch] = useState('');
  const [popUp, setPopUp] = useState<any>(null);
  const [selectedRecipeId, setSelectedRecipeId] = useState('');
  const [selectedComboStatus, setSelectedComboStatus] = useState(0);
  const navigate = useNavigate();
  const { currentBrand, currentShop } = useSelector((state: any) => state.Auth);
  const [allowRequest, setAllowRequest] = useState(false);
  const [keyword, setKeyword] = useState('');
  const [currentBrandId] = useState(currentBrand?.brand_id);
  useEffect(() => {
    if (currentBrandId !== currentBrand?.brand_id) {
      setKeyword('');
      setAllowRequest(true);
    }
  }, [currentBrand, currentShop]);

  useEffect(() => {
    if (allowRequest) {
      pageData();
      setAllowRequest(false);
    }
  }, [allowRequest]);

  const handleSort = (column: string, type: string) => {
    let arr =
      type == 'string'
        ? sortedData(ascend, sortData, column)
        : sortNumber(ascend, sortData, column);

    setSortData(() => arr);
  };
  const setSelectedMenu = (combo: any) => {
    setSelectedRecipeId(combo?.id);
    setSelectedComboStatus(combo?.status);
  };

  const UTCtoLocal = (utcDate: any) => {
    var stillUtc = moment.utc(utcDate).toDate();
    var local = moment(stillUtc).local();
    return local;
  };

  const columns = [
    {
      title: () => (
        <div className="tableTitle listView-first-column-margin">
          <span>COMBO NAME</span>
          <img
            src={UpDownArrow}
            className="titleArrow"
            onClick={() => {
              handleSort('combo_name', 'string');
              setAscend(!ascend);
            }}
          />
        </div>
      ),
      dataIndex: 'combo_name',
      key: 'combo_name',
      responsive: ['md'],
      render: (data: any, record: any, index: number) => {
        return (
          <div className="col1 font listView-first-column-margin">
            <img
              className="listView-image-align"
              src={record?.thumbnail}
              width="52px"
              height="52px"
            />
            <div>
              <div className="subCol1 titleContainer font">
                <span
                  className="title-without-hover"
                  onClick={() => {
                    // setDialogData(record);
                    // setOpenDialog(true);
                  }}
                >
                  {/* {record?.combo_name} */}
                  <Tooltip
                    title={
                      <div>
                        <div className="tooltipHead">Name</div>
                        <div className="tootipText">{record?.combo_name}</div>
                      </div>
                    }
                  >
                    {record?.combo_name?.length > 20
                      ? record?.combo_name?.substring(0, 20) + '...'
                      : record?.combo_name}
                  </Tooltip>
                </span>
                <span>
                  <div className="categoryPill">
                    <div className="pillText">{record?.menu_category}</div>
                  </div>
                </span>
                {/* <div className="subTextCol1 font">
              {record.is_verified ? (
                <img
                  src={FireWhite}
                  alt=""
                  className="subCol1Icon iconFireWhite"
                />
              ) : (
                <img src={Fire} alt="" className="subCol1Icon" />
              )}

              <span className="col1Text font">{record?.calorie}</span>
              <span className="calories font ">kcal</span>
            </div> */}
              </div>
              <div className="col3 cellSpacing  common-flex-center calorie-serving-subrecipe-gap">
                <div className="common-flex-center IconsAlignment">
                  {' '}
                  <DonutChart
                    popUp={popUp}
                    setPopUp={setPopUp}
                    index={index}
                    data={record}
                    component={'listView'}
                  >
                    {record.is_verified ? (
                      <img
                        src={FireWhite}
                        className="subCol1Icon iconFireWhite"
                      />
                    ) : (
                      <img src={Fire} width={15} className="subCol1Icon" />
                    )}
                  </DonutChart>
                  {handleFloat(record?.calorie)}
                </div>
                <div className="tableCol col3 common-flex-center">
                  <Tooltip
                    title={
                      <div>
                        <div className="tooltipHead">Servings</div>
                        <div className="tootipText">
                          {record?.servings} person serving
                        </div>
                      </div>
                    }
                  >
                    <img src={Serving} alt="" className="icons" />
                  </Tooltip>
                  {record?.servings}
                </div>
                <div className="tableCol col3 common-flex-center">
                  <Tooltip
                    title={
                      <div>
                        <div className="tooltipHead">Sub recipe</div>
                        <div className="tootipText">
                          Total {record?.sub_recipe_count ?? 0} sub recipes
                        </div>
                      </div>
                    }
                  >
                    <img src={SubMenu} width={15} alt="" className="icons" />
                  </Tooltip>
                  {record?.sub_recipe_count ?? 0}
                </div>
              </div>
              {/* <div className="subCol1 textContainer">
                {record?.description?.substring(0, 20)}...
                <Tooltip
                  title={
                    <div>
                      <div className="tooltipHead">Description</div>
                      <div className="tootipText">{record?.description}</div>
                    </div>
                  }
                >
                  <span className="readMoreBtn">read more</span>
                </Tooltip>
              </div> */}
            </div>
          </div>
        );
      },
    },
    // {
    //   title: () => (
    //     <div className="tableTitle">
    //       <span>CALORIES</span>

    //       <img
    //         src={UpDownArrow}
    //         className="titleArrow"
    //         onClick={() => {
    //           handleSort('calories', 'number');
    //           setAscend(!ascend);
    //         }}
    //       />
    //     </div>
    //   ),
    //   dataIndex: 'calories',
    //   key: 'calories',
    //   responsive: ['md'],
    //   render: (data: any, record: any) => (
    //     <div className="col3 cellSpacing">
    //       {record.is_verified ? (
    //         <img src={FireWhite} className="subCol1Icon iconFireWhite" />
    //       ) : (
    //         <img src={Fire} width={15} className="subCol1Icon" />
    //       )}{' '}
    //       {handleFloat(record?.calories) + ' kcal'}
    //     </div>
    //   ),
    // },
    // {
    //   title: () => (
    //     <div className="tableTitle">
    //       <span>MENU ITEM</span>

    //       <img
    //         src={UpDownArrow}
    //         className="titleArrow"
    //         onClick={() => {
    //           handleSort('recipes_count', 'number');
    //           setAscend(!ascend);
    //         }}
    //       />
    //     </div>
    //   ),
    //   dataIndex: 'recipes_count',
    //   key: 'recipes_count',
    //   responsive: ['md'],
    //   render: (data: any, record: any) => (
    //     <div className="tableCol col3 ">
    //       <Tooltip
    //         title={
    //           <div>
    //             <div className="tooltipHead">Menu Items</div>
    //             <div className="tootipText">
    //               {'Total ' + record?.recipes_count + ' Menu Item'}
    //             </div>
    //           </div>
    //         }
    //       >
    //         <img src={SubMenu} width={15} alt="" className="icons" />
    //       </Tooltip>

    //       {record.recipes_count}
    //     </div>
    //   ),
    // },
    // {
    //   title: () => (
    //     <div className="tableTitle">
    //       <span>SERVING</span>

    //       <img
    //         src={UpDownArrow}
    //         className="titleArrow"
    //         onClick={() => {
    //           handleSort('servings', 'number');
    //           setAscend(!ascend);
    //         }}
    //       />
    //     </div>
    //   ),
    //   dataIndex: 'servings',
    //   key: 'servings',
    //   responsive: ['md'],
    //   render: (data: any, record: any) => (
    //     <div className="tableCol col3">
    //       <Tooltip
    //         title={
    //           <div>
    //             <div className="tooltipHead">Servings</div>
    //             <div className="tootipText">
    //               {record?.servings + ' Persons Serving'}
    //             </div>
    //           </div>
    //         }
    //       >
    //         <img src={Serving} alt="" className="icons" />
    //       </Tooltip>

    //       {record.servings}
    //     </div>
    //   ),
    // },
    {
      title: () => (
        <div className="tableTitle">
          <span>PRICE</span>

          <img
            src={UpDownArrow}
            className="titleArrow"
            onClick={() => {
              handleSort('total_cost', 'string');
              setAscend(!ascend);
            }}
          />
        </div>
      ),
      dataIndex: 'total_cost',
      key: 'total_cost',
      responsive: ['md'],
      render: (data: any, record: any) => (
        <div className="col3">
          <span className="gridViewItemGap rupeesTag">
            {formatNumberFloatValue(record?.total_cost)}{' '}
            <span className="currency-font">{brand?.currency_name}</span>
          </span>
          {/* <span className="percentagePrice">(20%)</span> */}
        </div>
      ),
    },
    {
      title: () => (
        <div className="tableTitle">
          <span>CREATED</span>

          <img
            src={UpDownArrow}
            className="titleArrow"
            onClick={() => {
              handleSort('created_at', 'string');
              setAscend(!ascend);
            }}
          />
        </div>
      ),
      dataIndex: 'created_at',
      key: 'created_at',
      responsive: ['md'],
      render: (data: any, record: any) => (
        <Tooltip
          title={
            <div>
              <div className="tooltipHead">Created At</div>
              <div className="tootipText">
                {moment(UTCtoLocal(record?.created_at)).format(
                  'DD-MM-YYYY HH:mm:ss'
                )}
              </div>
            </div>
          }
        >
          <div className="col3">
            {moment(UTCtoLocal(record?.created_at)).format('DD-MM-YYYY')}
          </div>
        </Tooltip>
      ),
    },
    {
      title: '',
      dataIndex: 'more',
      key: 'more',
      responsive: ['md'],
      render: (data: any, record: any) => (
        <Dropdown
          menu={DropDownmenuProps}
          trigger={['click']}
          placement="bottomRight"
          arrow={{ pointAtCenter: true }}
        >
          <a
            onClick={() => {
              setSelectedMenu(record);
            }}
          >
            <Space>
              <div className="dots">
                <img src={More} />
              </div>
            </Space>
          </a>
        </Dropdown>
      ),
    },
  ];
  useEffect(() => {
    checkAuthentication(user, navigate);
    let permissions = user?.permissions;
    const data = permissions?.filter(
      (permission: any) => permission?.permission === 'Combos'
    );
    data?.length && setPermissionStatus(data[0]?.perm_status_id);
    setCurrent(1);
  }, []);

  useEffect(() => {
    if (dataLength && current) {
      pageData();
    }
  }, [dataLength, current, currentBrand]);

  const item1: MenuProps['items'] = PageDataLength.map((data, index) => {
    return {
      key: `${index}`,
      label: <div className="font dotOption">{data}</div>,
    };
  });

  const handleMenuClick: MenuProps['onClick'] = (e: any) => {
    setDataLength(PageDataLength[+e?.key]);
  };

  const handleingredient = async (data: any) => {
    const details = await RecipeActions.getDetailRecipe(data?.rb_id);
    setDialogData(details?.data[0]);
    setOpenDialog(true);
  };

  const menuProps = {
    items: item1,
    onClick: handleMenuClick,
  };

  const onChange: PaginationProps['onChange'] = (page) => {
    setCurrent(page);
  };

  const handleDropDownClick: MenuProps['onClick'] = async (e: any) => {
    let option = +e.key;

    switch (option) {
      case 1:
        navigate('wizard', {
          state: {
            update: true,
            id: selectedRecipeId,
            status: selectedComboStatus,
          },
        });
        break;

      case 2:
        setOpenDeleteDialog(true);
        break;
      default:
    }
  };

  const DropDownmenuProps = {
    items: permissionStatus && permissionStatus === 2 ? items : [],
    onClick: handleDropDownClick,
  };

  const pageData = async () => {
    setRecipeLoader(true);
    const recipeData = await ComboActions.getCombos(
      dataLength,
      current,
      keyword
    );
    if (recipeData === undefined || !recipeData?.success) {
      setRecipeCount(0);
      // setIngredientCount(recipeData?.data?.ingredients_count);
      setTotal(0);
      setSortData([]);
    } else {
      setRecipeCount(recipeData?.data?.combo_count);
      // setIngredientCount(recipeData?.data?.ingredients_count);
      setTotal(recipeData?.data?.pagination?.total);
      setCombos(recipeData?.data?.items);
      setSortData(recipeData?.data?.items);
    }
    setRecipeLoader(false);
  };

  // const pageDataFilter = async (filters: any) => {
  //   setRecipeLoader(true);
  //   const recipeData = await RecipeActions.recipesFilters(
  //     dataLength,
  //     current,
  //     filters
  //   );
  //   setRecipeCount(recipeData?.data?.recipe_count);
  //   // setIngredientCount(recipeData?.data?.ingredients_count);
  //   setTotal(recipeData?.data?.pagination?.total);
  //   setSortData(recipeData?.data?.items);
  //   setRecipeLoader(false);
  // };

  const actionButton = (
    <Button
      text="Add New"
      className="theme-button recipeBtn"
      icon={<PlusOutlined />}
      handleButtonClick={() => {
        navigate('wizard', { state: { update: false, id: '' } });
      }}
      shape="round"
      size="middle"
    />
  );

  const onSearch = async (keyword: string) => {
    setSearch(keyword);
    setRecipeLoader(true);
    const searchResultofRecipes = await ComboActions.searchCombo(
      dataLength,
      keyword
    );
    if (
      searchResultofRecipes === undefined ||
      !searchResultofRecipes?.success
    ) {
      setRecipeCount(0);
      setIngredientCount(0);
      setTotal(0);
      setSortData([]);
    } else {
      setRecipeCount(searchResultofRecipes?.data?.combo_count);
      setIngredientCount(searchResultofRecipes?.data?.ingredients_count);
      setTotal(searchResultofRecipes?.data?.pagination?.total);
      setSortData(searchResultofRecipes?.data?.items);
    }
    // if (keyword) {
    //   const results = sortData.filter(
    //     (obj: any) =>
    //       obj.combo_name?.toLowerCase().includes(keyword.toLowerCase()) ||
    //       obj.calories?.toString().includes(keyword.toString()) ||
    //       obj.recipes_count?.toString()?.includes(keyword.toString()) ||
    //       obj.servings?.toString()?.includes(keyword.toString()) ||
    //       obj.costing?.final_price?.toString()?.includes(keyword?.toString()) ||
    //       obj.created_at?.includes(keyword)
    //   );
    //   setSortData(results);
    // } else {
    //   setSortData(combos);
    // }

    setRecipeLoader(false);
  };

  const handleDeleteCancel = () => {
    setOpenDeleteDialog(false);
  };
  const handleDeleteContinue = async () => {
    setRecipeLoader(true);
    setOpenDeleteDialog(false);
    const response = await ComboActions.deleteCombo(selectedRecipeId);
    if (response?.success === true) {
      pageData();
    } else if (response?.success === false) {
      setRecipeLoader(false);
      let responseDialogData = {
        OnClick: () => {},
        type: 'error',
        messageArray: ['Error!', `${response?.message}`],
      };
      ResponseDialog(responseDialogData);
    }
  };

  return (
    <div className="comboPage">
      <Dialog
        open={openDialog}
        handleClose={() => setOpenDialog(false)}
        handleSubDialog={() => {
          setOpenDialog(false);
          setOpenSubDialog(true);
        }}
        data={dialogData}
        component={'RecipeBuilder'}
      />
      <SubDialog
        open={openSubDialog}
        data={dialogData}
        handleSubDialog={() => {
          setOpenSubDialog(false);
          setOpenDialog(true);
        }}
        handleCloseAll={() => {
          setOpenSubDialog(false);
          setOpenDialog(false);
        }}
      />
      <DeleteConfirmation
        handleDeleteCancel={handleDeleteCancel}
        handleDeleteContinue={handleDeleteContinue}
        open={openDeleteDialog}
        message={'Are you sure want to delete combo?'}
      />

      <Breadcrumb
        heading="Combos"
        breadcrumbIcon
        iconAction={() => {
          navigate('/app/Dashboard');
        }}
        button={actionButton}
        perm_status={permissionStatus}
      />
      <Page title="Combos">
        <Row className="listingTopFilter">
          <Col lg={5}>
            <ListCount
              count1={recipeCount}
              title1="Total Combos"
              // count2={ingrdientCount}
              // title2="Total Ingredients"
            />
          </Col>
          <Col lg={8} />
          <Col lg={11}>
            <div className="search-wrapper">
              <SearchBox
                keyword={keyword}
                setKeyword={setKeyword}
                action={onSearch}
                placeholderText="Search..."
              />
              <IconSwitcher listViewLayout={design} setListView={setDesign} />
            </div>
          </Col>
        </Row>

        <Row gutter={12}>
          {/* <Col lg={6} md={8}>
            <Filter
              action={pageDataFilter}
              apiCall={RecipeActions.getRecipesFilters}
            />
          </Col> */}
          <Col lg={24} md={16}>
            {!design && !recipeLoader ? (
              <GridView
                handledata={''}
                DropDownmenuProps={DropDownmenuProps}
                setSelectedMenu={setSelectedMenu}
                dataSource={sortData}
                component={'combos'}
                handleDialog={''}
                UTCtoLocal={UTCtoLocal}
                handleOpen={(data: any) => {
                  handleingredient(data);
                }}
              />
            ) : (
              !design && (
                <div className="loader-wrapper">
                  <Spin spinning={recipeLoader} className="recipeLoader" />
                </div>
              )
            )}

            {design && !recipeLoader ? (
              <ListViewWithoutExpand
                limit={dataLength}
                items={items}
                dataSource={sortData ? [...sortData] : []}
                columns={columns}
              />
            ) : (
              design && (
                <div className="loader-wrapper">
                  <Spin spinning={recipeLoader} className="recipeLoader" />
                </div>
              )
            )}
            <Pagination
              total={total}
              menuProps={menuProps}
              onChange={onChange}
              current={current}
              dataLength={dataLength >= total ? total : dataLength}
              handleLength={(data: number) => setDataLength(data)}
            />
          </Col>
        </Row>
      </Page>
    </div>
  );
};

export default Combos;
