/* eslint-disable no-unused-vars */
import {
  BarChart,
  Rectangle,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  LabelList,
} from 'recharts';
import { formatNumberWithEllipsis } from '../../../helper/sortData';

interface ChartBarHorizontalProps {
  isMulti?: boolean;
  data: any[];
  source?: any;
  brandcount?: number;
  currency?: string;
}

const ChartBarHorizontal = (props: ChartBarHorizontalProps) => {
  const { isMulti = false, data, source, brandcount, currency } = props;

  const formatYAxisLabel = (value: number) => {
    if (value >= 1000) {
      const formattedValue = value / 1000;
      if (formattedValue % 1 === 0) {
        return `${formattedValue}k`;
      } else {
        return `${formattedValue.toFixed(1)}k`;
      }
    }
    return value.toString();
  };

  const chartWidth = source === 'COGS' ? 1140 : 560;
  const chartHeight = source === 'COGS' ? 200 : 240;

  if (!data || data.length === 0) {
    return <div className="no-data-graph">No data to display</div>;
  }

  const transformedData =
    source === 'COGS'
      ? data.map((item) => ({
          name: item.category,
          value: parseFloat(item.cogs) || 0,
        }))
      : data;

  const maxValue = Math.max(...transformedData.map((item) => item.value));

  // const bars = transformedData.map((item, index) => (
  //   <Bar
  //     key={index}
  //     dataKey="value"
  //     fill={item.value == maxValue ? '#F9AB01' : '#F5F3EF'}
  //     activeBar={
  //       <Rectangle
  //         fill={item.value == maxValue ? '#F9AB01' : '#F5F3EF'}
  //         stroke="#a5a5a5"
  //       />
  //     }
  //   >
  //     <LabelList
  //       dataKey="value"
  //       position="top"
  //       formatter={(value: number) => {
  //         return source === 'ModifiersPerformance'
  //           ? `${value.toLocaleString()} ${currency}`
  //           : formatYAxisLabel(value);
  //       }}
  //       fill="#4B465C"
  //       fontSize={12}
  //       offset={8}
  //     />
  //   </Bar>
  // ));

  return (
    <BarChart
      width={chartWidth}
      height={chartHeight}
      data={transformedData}
      margin={{
        top: 20,
        right: 30,
        left: 0,
        bottom: 0,
      }}
    >
      {source === 'ModifiersPerformance' && (
        <CartesianGrid horizontal={true} vertical={true} stroke="#E0E0E0" />
      )}
      <XAxis
        dataKey={source === 'ModifiersPerformance' ? 'modifier_name' : 'name'}
        tickFormatter={
          source === 'ModifiersPerformance'
            ? formatNumberWithEllipsis
            : undefined
        }
        tick={{ fill: '#4B465C' }}
      />
      <YAxis
        tick={{ fill: '#4B465C' }}
        stroke="transparent"
        tickFormatter={formatYAxisLabel}
      />
      <Tooltip
        content={({ payload }) => {
          if (payload && payload.length > 0) {
            const xAxisLabel =
              payload[0].payload.name || payload[0].payload.modifier_name;
            return (
              <div
                style={{
                  backgroundColor: '#fff',
                  padding: '5px',
                  border: '1px solid #ccc',
                }}
              >
                <p>
                  {source === 'ModifiersPerformance'
                    ? 'Modifier Name:'
                    : 'Category:'}{' '}
                  {xAxisLabel}
                </p>
              </div>
            );
          }
          return null;
        }}
      />
      <Legend />
      <Bar
        // key={index}
        dataKey="value"
        shape={(props: any) => {
          const { x, y, width, height, value } = props;
          const numericValue = parseFloat(value);
          const barColor = numericValue === maxValue ? '#F9AB01' : '#F5F3EF';
          return (
            <Rectangle
              x={x}
              y={y}
              width={width}
              height={height}
              fill={barColor}
            />
          );
        }}
        // fill={maxValue ? '#F9AB01' : '#F5F3EF'}
        // activeBar={
        //   <Rectangle fill={maxValue ? '#F9AB01' : '#F5F3EF'} stroke="#a5a5a5" />
        // }
      >
        <LabelList
          dataKey="value"
          position="top"
          formatter={(value: number) => {
            return source === 'ModifiersPerformance'
              ? `${value.toLocaleString()} ${currency}`
              : formatYAxisLabel(value);
          }}
          fill="#4B465C"
          fontSize={12}
          offset={8}
        />
      </Bar>
      {/* {bars} */}
    </BarChart>
  );
};

export default ChartBarHorizontal;
