/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { useNavigate, Navigate } from 'react-router-dom';
import { Spin, Tooltip, Button } from 'antd';
import { Page } from '../../components';
import Utils from '../../redux/utils';
import styles from './Page.module.scss';

const SubscriptionCheck = () => {
  const user = Utils.getCurrentUser();
  const subscription = Utils.getUserSubscriptions();
  const { company_profile_status } = user;
  const [loader, setLoader] = useState(false);
  const navigate = useNavigate();

  const logoutUser = () => {
    localStorage.clear();
    Utils.setCurrentUser();
    Utils.setCurrentToken();
    const location = window.location;
    window.location.href = location.origin;
  };

  useEffect(() => {
    setLoader(false);
    if (subscription?.status == 'canceled') {
      logoutUser();
    } else {
      navigate('/app/brands', { replace: true });
    }
  }, [navigate]);

  return (
    <div className="loader-wrapper">
      <Spin spinning={loader} className="recipeLoader">
        <Page title="Subcription Check">
          <>
            {company_profile_status === 1 &&
              subscription?.status === 'past_due' && (
                <div className={styles.profileCompletionBox}>
                  <p>{'Please add your payment info'}</p>
                  <Tooltip
                    title={'Add your payment info to continue using ChefAdmin'}
                  >
                    <Button
                      onClick={() => {
                        if (subscription?.status === 'past_due') {
                          window.location.href =
                            user?.subscription?.billing_url;
                        }
                      }}
                      className="theme-button"
                    >
                      {'Add Payment Info'}
                    </Button>
                  </Tooltip>
                </div>
              )}
          </>
        </Page>
      </Spin>
    </div>
  );
};

export default SubscriptionCheck;
