/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import { Row, Spin, Col, Card, Tooltip } from 'antd';
import moment, { Moment } from 'moment';
import { useNavigate } from 'react-router-dom';
import DashBoardActions from '../../../../redux/middleware/dashboard';
import ReportsApi from '../../../../redux/middleware/reports';
import Utils from '../../../../redux/utils';
import '../../style.scss';
import ChartBarHorizontal from '../../../../components/charts/barHorizontal';
import ChartArea from '../../../../components/charts/area';
import ChartLine from '../../../../components/charts/line';
import ChartMultiArea from '../../../../components/charts/multiArea';
import ChartBarVertical from '../../../../components/charts/barVertical';
import ChartComposed from '../../../../components/charts/composedChart';
import StackedBarHorizontal from '../../../../components/charts/stackedBarHorizontal';
import StackedBarVertical from '../../../../components/charts/stackedBarVertical';
import ChartScatterAndLine from '../../../../components/charts/scatterAndLine';
import ChartScatter from '../../../../components/charts/scatter';
import ChartBubble from '../../../../components/charts/bubble';
import ProgressBar from '../../../../components/charts/progress';
import ChartPie from '../../../../components/charts/pie';
import Eye from '../../../../assets/images/Eye.svg';
import ArrowUp from '../../../../assets/images/UpArrow.svg';
import ArrowDown from '../../../../assets/images/DownArrowGreen.png';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import {
  handleFloat,
  handleFloatForSpecialCase,
} from '../../../../helper/sortData';
import { Table } from 'antd';
import { current } from '@reduxjs/toolkit';

interface dashboardResponse {
  currency: string;
  sales: any;
  cogs: any;
  stockOnHand: any;
  variance: any;
  wastePercentage: any;
  menu: any;
  inventory: any;
  recipe: any;
}
interface BranchViewProps {
  start_date?: Moment;
  end_date?: Moment;
  activeTab?: any;
  brand_count?: number;
  selectedBrands?: any;
  selectedBranches?: any;
  tag?: string;
}
interface DataType {
  key: React.Key;
  name: string;
  age: number;
  address: string;
}

type DataObject = { [key: string]: any };
type DataArray = DataObject[];
type Data = DataArray | DataObject | number | null | undefined;

const BranchView: React.FC<BranchViewProps> = ({
  start_date,
  end_date,
  activeTab,
  brand_count,
  selectedBrands,
  selectedBranches,
  tag,
}) => {
  const navigate = useNavigate();
  const user = Utils.getCurrentUser();
  const [info, setInfo] = React.useState({} as dashboardResponse);
  const [preparationTime, setPreparationTime] = useState<any>([]);
  const [modifierPerformance, setModifierPerformance] = useState<any>([]);
  const [recipeCosting, setRecipeCosting] = useState<any>([]);
  const [categoryWiseCogs, setCategoryWiseCogs] = useState<any>([]);
  const [inventoryTurnover, setInventoryTurnover] = useState<any>([]);
  const [ingredientsUtilizationRate, setIngredientsUtilizationRate] =
    useState<any>([]);
  const [wastage, setWastage] = useState<any>([]);
  const [salesMixAnalysis, setSalesMixAnalysis] = useState<any>([]);
  const [foodCostPercentage, setFoodCostPercentage] = useState<any>([]);
  const [menuItemProfitability, setMenuItemProfitability] = useState<any>([]);
  const [menuEngineeringMatrix, setMenuEngineeringMatrix] = useState<any>([]);
  const [Loader, setLoader] = useState(false);
  const startDate = start_date ?? moment();
  const endDate = end_date ?? moment();
  const tags = tag === 'brand' ? 'brand' : tag === 'shop' ? 'shop' : 'brand';

  const replaceNegativeValues = (data: Data, limit?: number): Data => {
    if (Array.isArray(data)) {
      const processedData = data.map((item) => replaceNegativeValues(item));
      return limit ? processedData.slice(0, limit) : processedData;
    } else if (typeof data === 'object' && data !== null) {
      return Object.fromEntries(
        Object.entries(data).map(([key, value]) => [
          key,
          replaceNegativeValues(value),
        ])
      );
    } else if (typeof data === 'number') {
      return data < 0 ? 0 : data;
    } else {
      return data;
    }
  };

  const fetchData = async () => {
    setLoader(true);
    const response: any = await DashBoardActions.fetchDashboardInfo(
      selectedBrands,
      selectedBranches,
      startDate?.format('YYYY-MM-DD'),
      endDate?.format('YYYY-MM-DD'),
      'date',
      tags
    );
    const { success } = response;
    if (success) {
      const data: dashboardResponse = response?.data;
      setInfo(data);
      setInventoryTurnover(data?.inventory?.inventoryTurnover);
      setCategoryWiseCogs(data?.inventory?.categoryWiseCogs);
      setWastage(data?.inventory?.wastePercentage);
      setIngredientsUtilizationRate(
        replaceNegativeValues(data?.recipe?.ingredientsUtilizationRate || [], 7)
      );
      setPreparationTime(
        replaceNegativeValues(data?.recipe?.preparationTime || [], 5)
      );
      setRecipeCosting(
        replaceNegativeValues(data?.recipe?.recipeCosting || [], 5)
      );
      setMenuEngineeringMatrix(data?.menu?.menuEngineeringMatrix);
      setMenuItemProfitability(
        replaceNegativeValues(data?.menu?.menuItemProfitability || [], 5)
      );
      setFoodCostPercentage(data?.menu?.foodCostPercentage);
      setSalesMixAnalysis(
        replaceNegativeValues(data?.menu?.salesMixAnalysis || [], 5)
      );
      setModifierPerformance(data?.menu?.modifierPerformance);
    }
    setLoader(false);
  };

  // const handleReportFilter = async (filter: any) => {
  //   setLoader(true);
  //   // console.log(tabPills, selectedCategory, tabsValue);
  //   const response = await ReportsApi.getReports(
  //     selectedBrands,
  //     selectedBranches,
  //     tags,
  //     filter,
  //     1000,
  //     'date',
  //     start_date?.format('YYYY-MM-DD'),
  //     end_date?.format('YYYY-MM-DD')
  //   );
  //   setLoader(false);
  // };

  React.useEffect(() => {
    if (selectedBrands && selectedBranches) {
      fetchData();
    }
  }, [startDate, endDate, tags, selectedBrands, selectedBranches]);

  function formatNumberWithEllipsis(number: any) {
    const numberStr = number.toString();
    if (numberStr.length > 9) {
      return numberStr.substring(0, 9) + '...';
    }
    return numberStr;
  }

  return (
    <div className="Dashboard">
      {!Loader ? (
        <>
          <div className="dashboard-container">
            {/* Row 1 */}
            <Row>
              <Col xs={24} sm={12} md={12} lg={6}>
                <Card className="centered-card">
                  <div className="card-section">
                    <div className="card-title">Sales</div>
                  </div>
                  <div className="sales-amount">
                    {/* <span className="small-text">{info?.currency}</span> */}
                    <span className="large-text">
                      {info?.currency} {handleFloat(info?.sales?.value)}
                    </span>
                  </div>
                  <div className="sales-amount">
                    {/* <span className="large-text">
                      {info?.sales?.weekPercentage}
                    </span> */}
                    {/* <span className="small-text small-text-week">
                      {info?.sales?.weekPercentage} This week
                    </span> */}
                  </div>
                </Card>
              </Col>
              <Col xs={24} sm={12} md={12} lg={6}>
                <Card className="centered-card">
                  <div className="card-section">
                    <div className="card-title uppercase">COGS</div>
                  </div>
                  <div className="sales-amount">
                    <span className="large-text">
                      {handleFloat(info?.cogs?.percentage)}%
                    </span>
                  </div>
                  <div className="sales-amount">
                    {/* <span className="large-text">
                      {info?.cogs?.weekPercentage}
                    </span> */}
                    {/* <span className="small-text small-text-week">
                      {info?.cogs?.weekPercentage} This week
                    </span> */}
                  </div>
                </Card>
              </Col>
              <Col xs={24} sm={12} md={12} lg={6}>
                <Card className="centered-card">
                  <div className="card-section">
                    <div className="card-title">Stock On Hand</div>
                  </div>
                  <div className="sales-amount">
                    {/* <span className="small-text">{info?.currency}</span> */}
                    <span className="large-text">
                      {info?.currency} {handleFloat(info?.stockOnHand?.value)}
                    </span>
                  </div>
                  <div className="sales-amount">
                    {/* <span className="large-text">
                      {info?.stockOnHand?.weekPercentage}
                    </span> */}
                    {/* <span className="small-text small-text-week">
                      {info?.stockOnHand?.weekPercentage} This week
                    </span> */}
                  </div>
                </Card>
              </Col>
              <Col xs={24} sm={12} md={12} lg={6}>
                <Card className="centered-card">
                  <div className="card-section">
                    <div className="card-title">Variance</div>
                  </div>
                  <div className="sales-amount">
                    <span className="large-text">
                      {handleFloat(info?.variance?.percentage)}%
                    </span>
                  </div>
                  <div className="sales-amount">
                    {/* <span className="large-text">
                      {info?.wastePercentage?.value}
                    </span> */}
                    {/* <span className="small-text small-text-week">
                      This week
                    </span> */}
                    <span className="small-text small-text-week">
                      {info?.variance?.value}
                    </span>
                  </div>
                </Card>
              </Col>
            </Row>
            {activeTab === '2' ? (
              <>
                {/* Row 2 */}
                <Row className="recipe-row">
                  <Col xs={24} sm={12} md={12} lg={12}>
                    <Card className="graph-card row2-card">
                      {/* <Tooltip
                        title={
                          <div>
                            <div className="tooltipHead">See Report</div>
                            <div className="tootipText">
                              Inventory Turnover Rate Over Time
                            </div>
                          </div>
                        }
                      >
                        <div
                          className="eye-icon-container"
                          onClick={() => {
                            navigate('/app/reports', {
                              state: {
                                from: 'Dashboard',
                                selectedFilter:
                                  brand_count && brand_count > 1
                                    ? tag === 'shop'
                                      ? 'branch_wastage'
                                      : tag === 'brand'
                                      ? 'brand_wastage'
                                      : 'revenue_break_up'
                                    : 'daywise_wastage',
                              },
                            });
                          }}
                        >
                          <div className="report-text">See Report</div>
                        </div>
                      </Tooltip> */}
                      <div className="card-section">
                        <div className="card-title-graph">
                          Inventory Turnover Rate Over Time
                          <Tooltip
                            title={
                              <div>
                                <div className="tooltipHead">Graph Formula</div>
                                <div className="tootipText dashboardTooltip">
                                  Inventory Turnover Rate Over Time = COGS /
                                  Stock on Hand
                                </div>
                              </div>
                            }
                          >
                            <ExclamationCircleOutlined className="dashboard-icon" />
                          </Tooltip>
                        </div>
                        <div className="graph-info-div">
                          X-axis: Time (Months) &nbsp;|&nbsp; Y-axis : Turnover
                          Rate
                        </div>
                      </div>
                      {tags === 'shop' ? (
                        <ChartArea data={inventoryTurnover} />
                      ) : (
                        <ChartArea data={inventoryTurnover} />
                      )}
                    </Card>
                  </Col>
                  <Col xs={24} sm={12} md={12} lg={12}>
                    <Card className="graph-card row2-card">
                      {/* <Tooltip
                        title={
                          <div>
                            <div className="tooltipHead">See Report</div>
                            <div className="tootipText">Waste Percentage </div>
                          </div>
                        }
                      >
                        <div
                          className="eye-icon-container"
                          onClick={() => {
                            navigate('/app/reports', {
                              state: {
                                from: 'Dashboard',
                                selectedFilter:
                                  brand_count && brand_count > 1
                                    ? tag === 'shop'
                                      ? 'branch_wastage'
                                      : tag === 'brand'
                                      ? 'brand_wastage'
                                      : 'revenue_break_up'
                                    : 'daywise_wastage',
                              },
                            });
                          }}
                        >
                          <div className="report-text">See Report</div>
                        </div>
                      </Tooltip> */}
                      <div className="card-section">
                        <div className="card-title-graph">
                          Waste Percentage
                          <Tooltip
                            title={
                              <div>
                                <div className="tooltipHead">Graph Formula</div>
                                <div className="tootipText dashboardTooltip">
                                  Waste Percentage = (Total Waste / Stock On
                                  Hand) * 100
                                </div>
                              </div>
                            }
                          >
                            <ExclamationCircleOutlined className="dashboard-icon" />
                          </Tooltip>
                        </div>
                      </div>
                      {tags === 'shop' ? (
                        <ProgressBar data={wastage} />
                      ) : (
                        <ProgressBar data={wastage} />
                      )}
                    </Card>
                  </Col>
                </Row>
                {/* Row 3 */}
                <Row>
                  <Col xs={24} sm={12} md={12} lg={24}>
                    <Card className="graph-card cog-card">
                      {/* <Tooltip
                        title={
                          <div>
                            <div className="tooltipHead">See Report</div>
                            <div className="tootipText">
                              Cost of Goods Sold (COGS)
                            </div>
                          </div>
                        }
                      >
                        <div
                          className="eye-icon-container"
                          onClick={() => {
                            navigate('/app/reports', {
                              state: {
                                from: 'Dashboard',
                                selectedFilter: 'daywise_wastage',
                              },
                            });
                          }}
                        >
                          <div className="report-text">See Report</div>
                        </div>
                      </Tooltip> */}
                      <div className="card-section">
                        <div className="card-title-graph">
                          Cost of Goods Sold (COGS)
                          <Tooltip
                            title={
                              <div>
                                <div className="tooltipHead">Graph Formula</div>
                                <div className="tootipText dashboardTooltip">
                                  Cost of Goods Sold (COGS) = Total Cost of Sale
                                  Items * Quantity of Sale Items
                                </div>
                              </div>
                            }
                          >
                            <ExclamationCircleOutlined className="dashboard-icon" />
                          </Tooltip>
                        </div>
                        <div className="graph-info-div">
                          X-axis: Categories &nbsp;|&nbsp; Y-axis : COGS (In{' '}
                          {info?.currency})
                        </div>
                      </div>
                      <ChartBarHorizontal
                        data={categoryWiseCogs}
                        source="COGS"
                      />
                    </Card>
                  </Col>
                </Row>
              </>
            ) : activeTab === '3' ? (
              <>
                {/* Row 1 */}
                <Row className="recipe-row">
                  <Col xs={24} sm={12} md={12} lg={12}>
                    <Card className="graph-card row2-card">
                      {/* <Tooltip
                        title={
                          <div>
                            <div className="tooltipHead">See Report</div>
                            <div className="tootipText">Recipe Costing </div>
                          </div>
                        }
                      >
                        <div
                          className="eye-icon-container"
                          onClick={() => {
                            navigate('/app/reports', {
                              state: {
                                from: 'Dashboard',
                                selectedFilter:
                                  brand_count && brand_count > 1
                                    ? tag === 'shop'
                                      ? 'branch_wastage'
                                      : tag === 'brand'
                                      ? 'brand_wastage'
                                      : 'revenue_break_up'
                                    : 'daywise_wastage',
                              },
                            });
                          }}
                        >
                          <div className="report-text">See Report</div>
                        </div>
                      </Tooltip> */}
                      <div className="card-section">
                        <div className="card-title-graph">
                          Recipe Costing
                          <Tooltip
                            title={
                              <div>
                                <div className="tooltipHead">Graph Formula</div>
                                <div className="tootipText dashboardTooltip">
                                  Recipe Costing = ∑(Ingredient Cost *
                                  Ingredient Quantity)
                                </div>
                              </div>
                            }
                          >
                            <ExclamationCircleOutlined className="dashboard-icon" />
                          </Tooltip>
                        </div>
                        <div className="graph-info-div">
                          X-axis: Recipes &nbsp;|&nbsp; Y-axis : Cost (In{' '}
                          {info?.currency})
                        </div>
                      </div>
                      <StackedBarVertical
                        data={recipeCosting}
                        source="recipeCosting"
                        brandcount={brand_count}
                        currency={info?.currency}
                      />
                    </Card>
                  </Col>
                  <Col xs={24} sm={12} md={12} lg={12}>
                    <Card className="graph-card row2-card">
                      {/* <Tooltip
                        title={
                          <div>
                            <div className="tooltipHead">See Report</div>
                            <div className="tootipText">Preparation Time </div>
                          </div>
                        }
                      >
                        <div
                          className="eye-icon-container"
                          onClick={() => {
                            navigate('/app/reports', {
                              state: {
                                from: 'Dashboard',
                                selectedFilter:
                                  brand_count && brand_count > 1
                                    ? tag === 'shop'
                                      ? 'branch_wastage'
                                      : tag === 'brand'
                                      ? 'brand_wastage'
                                      : 'revenue_break_up'
                                    : 'daywise_wastage',
                              },
                            });
                          }}
                        >
                          <div className="report-text">See Report</div>
                        </div>
                      </Tooltip> */}
                      <div className="card-section">
                        <div className="card-title-graph">
                          Preparation Time
                          <Tooltip
                            title={
                              <div>
                                <div className="tooltipHead">Graph Formula</div>
                                <div className="tootipText dashboardTooltip">
                                  Preparation Time = Preparation Time of all
                                  recipes (minutes)
                                </div>
                              </div>
                            }
                          >
                            <ExclamationCircleOutlined className="dashboard-icon" />
                          </Tooltip>
                        </div>
                        <div className="graph-info-div">
                          X-axis: Time to Prepare &nbsp;|&nbsp; Y-axis : Recipes
                        </div>
                      </div>
                      <ChartBarVertical
                        data={preparationTime}
                        dataKey1="prep_time"
                        dataKey2="recipe_name"
                      />
                    </Card>
                  </Col>
                </Row>
                {/* Row 2 */}
                <Row>
                  {/* <Col xs={24} sm={12} md={12} lg={12}>
                    <Card className="graph-card row2-card">
                      <Tooltip
                        title={
                          <div>
                            <div className="tooltipHead">See Report</div>
                            <div className="tootipText">
                              Consistency and Yield
                            </div>
                          </div>
                        }
                      >
                        <div
                          className="eye-icon-container"
                          onClick={() => {
                            navigate('/app/reports', {
                              state: {
                                from: 'Dashboard',
                                selectedFilter:
                                  brand_count && brand_count > 1
                                    ? tag === 'shop'
                                      ? 'branch_wastage'
                                      : tag === 'brand'
                                      ? 'brand_wastage'
                                      : 'revenue_break_up'
                                    : 'daywise_wastage',
                              },
                            });
                          }}
                        >
                          <div className="report-text">See Report</div>
                        </div>
                      </Tooltip>
                      <div className="card-section">
                        <div className="card-title-graph">
                          Consistency and Yield
                          <Tooltip
                            title={
                              <div>
                                <div className="tooltipHead">
                                  Consistency and Yield
                                </div>
                                <div className="tootipText">
                                  Consistency and Yield
                                </div>
                              </div>
                            }
                          >
                            <ExclamationCircleOutlined className="dashboard-icon" />
                          </Tooltip>
                        </div>
                      </div>
                      <ChartMultiArea />
                    </Card>
                  </Col> */}
                  <Col xs={24} sm={12} md={12} lg={24}>
                    <Card className="graph-card">
                      {/* <Tooltip
                        title={
                          <div>
                            <div className="tooltipHead">See Report</div>
                            <div className="tootipText">
                              Ingredient Utilisation Rate
                            </div>
                          </div>
                        }
                      >
                        <div
                          className="eye-icon-container"
                          onClick={() => {
                            navigate('/app/reports', {
                              state: {
                                from: 'Dashboard',
                                selectedFilter:
                                  brand_count && brand_count > 1
                                    ? tag === 'shop'
                                      ? 'branch_wastage'
                                      : tag === 'brand'
                                      ? 'brand_wastage'
                                      : 'revenue_break_up'
                                    : 'daywise_wastage',
                              },
                            });
                          }}
                        >
                          <div className="report-text">See Report</div>
                        </div>
                      </Tooltip> */}
                      <div className="card-section">
                        <div className="card-title-graph">
                          Ingredient Utilisation Rate
                          <Tooltip
                            title={
                              <div>
                                <div className="tooltipHead">
                                  Graph Formulas
                                </div>
                                <div className="tooltipText dashboardTooltip">
                                  <ul>
                                    <li>
                                      <strong>
                                        Ingredient Utilisation Rate (Usage)
                                      </strong>{' '}
                                      = Stock Consumed - Wastage
                                    </li>
                                    <li>
                                      <strong>
                                        Ingredient Utilisation Rate (Wastage)
                                      </strong>{' '}
                                      = Stock Wastage
                                    </li>
                                    <li>
                                      <strong>
                                        Ingredient Utilisation Rate (Total)
                                      </strong>{' '}
                                      = Waste Percentage + Usage Percentage
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            }
                          >
                            <ExclamationCircleOutlined className="dashboard-icon" />
                          </Tooltip>
                        </div>
                        <div className="graph-info-div">
                          X-axis: Ingredient &nbsp;|&nbsp; Y-axis : Percentage
                        </div>
                      </div>
                      <StackedBarHorizontal
                        data={ingredientsUtilizationRate}
                        source="ingredientUtilisationRate"
                        brandcount={brand_count}
                      />
                    </Card>
                  </Col>
                </Row>
              </>
            ) : activeTab === '4' ? (
              <>
                {/* Row 1 Menu Management */}
                <Row className="recipe-row">
                  <Col xs={24} sm={12} md={12} lg={12}>
                    <Card className="graph-card menu-row1-card">
                      {/* <Tooltip
                        title={
                          <div>
                            <div className="tooltipHead">See Report</div>
                            <div className="tootipText">
                              Sales Mix Analysis{' '}
                            </div>
                          </div>
                        }
                      >
                        <div
                          className="eye-icon-container"
                          onClick={() => {
                            navigate('/app/reports', {
                              state: {
                                from: 'Dashboard',
                                selectedFilter:
                                  brand_count && brand_count > 1
                                    ? tag === 'shop'
                                      ? 'branch_wastage'
                                      : tag === 'brand'
                                      ? 'brand_wastage'
                                      : 'revenue_break_up'
                                    : 'daywise_wastage',
                              },
                            });
                          }}
                        >
                          <div className="report-text">See Report</div>
                        </div>
                      </Tooltip> */}
                      <div className="card-section">
                        <div className="card-title-graph">
                          Sales Mix Analysis
                          <Tooltip
                            title={
                              <div>
                                <div className="tooltipHead">Graph Formula</div>
                                <div className="tootipText dashboardTooltip">
                                  Sales Mix Analysis = (Item Sales / Total
                                  Sales) * 100
                                </div>
                              </div>
                            }
                          >
                            <ExclamationCircleOutlined className="dashboard-icon" />
                          </Tooltip>
                        </div>
                        <div className="graph-info-div">
                          X-axis: Recipes &nbsp;|&nbsp; Y-axis : Sales
                        </div>
                      </div>
                      <ChartComposed data={salesMixAnalysis} />
                    </Card>
                  </Col>
                  <Col xs={24} sm={12} md={12} lg={12}>
                    <Card className="graph-card menu-row1-card">
                      {/* <Tooltip
                        title={
                          <div>
                            <div className="tooltipHead">See Report</div>
                            <div className="tootipText">
                              Menu Engineering Matrix
                            </div>
                          </div>
                        }
                      >
                        <div
                          className="eye-icon-container"
                          onClick={() => {
                            navigate('/app/reports', {
                              state: {
                                from: 'Dashboard',
                                selectedFilter:
                                  brand_count && brand_count > 1
                                    ? tag === 'shop'
                                      ? 'branch_wastage'
                                      : tag === 'brand'
                                      ? 'brand_wastage'
                                      : 'revenue_break_up'
                                    : 'daywise_wastage',
                              },
                            });
                          }}
                        >
                          <div className="report-text">See Report</div>
                        </div>
                      </Tooltip> */}
                      <div className="card-section">
                        <div className="card-title-graph">
                          Menu Engineering Matrix
                          <Tooltip
                            title={
                              <div>
                                <div className="tooltipHead">
                                  Graph Formulas
                                </div>
                                <div className="tooltipText dashboardTooltip">
                                  Categorises Items Based on Popularity and
                                  Profitability into 4 quadrants: Stars, Plough
                                  Horses, Puzzles, and Dogs.
                                  {/* <ul>
                                    <li>
                                      <strong>
                                        Menu Engineering Matrix (Popularity) ={' '}
                                        {`salesPercentage < popularityBenchmark ? 'Low' : 'High';`}
                                      </strong>
                                    </li>
                                    <li>
                                      <strong>
                                        Menu Engineering Matrix (Profitability)
                                        ={' '}
                                        {`itemCostRatio > brandfcr ? 'Low' : 'High';`}
                                      </strong>
                                    </li>
                                  </ul> */}
                                </div>
                              </div>
                            }
                          >
                            <ExclamationCircleOutlined className="dashboard-icon" />
                          </Tooltip>
                        </div>
                      </div>
                      <ChartScatter
                        data={menuEngineeringMatrix}
                        currency={info?.currency}
                      />
                    </Card>
                  </Col>
                  {/* <Col xs={24} sm={12} md={12} lg={12}>
                    <Card className="graph-card menu-row1-card">
                      <Tooltip
                        title={
                          <div>
                            <div className="tooltipHead">See Report</div>
                            <div className="tootipText">
                              Average Check Size{' '}
                            </div>
                          </div>
                        }
                      >
                        <div
                          className="eye-icon-container"
                          onClick={() => {
                            navigate('/app/reports', {
                              state: {
                                from: 'Dashboard',
                                selectedFilter:
                                  brand_count && brand_count > 1
                                    ? tag === 'shop'
                                      ? 'branch_wastage'
                                      : tag === 'brand'
                                      ? 'brand_wastage'
                                      : 'revenue_break_up'
                                    : 'daywise_wastage',
                              },
                            });
                          }}
                        >
                          <div className="report-text">See Report</div>
                        </div>
                      </Tooltip>
                      <div className="card-section">
                        <div className="card-title-graph">
                          Average Check Size
                          <Tooltip
                            title={
                              <div>
                                <div className="tooltipHead">
                                  Average Check Size
                                </div>
                                <div className="tootipText">
                                  Average Check Size
                                </div>
                              </div>
                            }
                          >
                            <ExclamationCircleOutlined className="dashboard-icon" />
                          </Tooltip>
                        </div>
                      </div>
                      <ChartScatterAndLine />
                    </Card>
                  </Col> */}
                </Row>
                {/* Row 2 Menu Management */}
                <Row className="recipe-row">
                  <Col xs={24} sm={12} md={12} lg={24}>
                    <Card className="graph-card">
                      {/* <Tooltip
                        title={
                          <div>
                            <div className="tooltipHead">See Report</div>
                            <div className="tootipText">
                              Menu Item Profitability
                            </div>
                          </div>
                        }
                      >
                        <div
                          className="eye-icon-container"
                          onClick={() => {
                            navigate('/app/reports', {
                              state: {
                                from: 'Dashboard',
                                selectedFilter:
                                  brand_count && brand_count > 1
                                    ? tag === 'shop'
                                      ? 'branch_wastage'
                                      : tag === 'brand'
                                      ? 'brand_wastage'
                                      : 'revenue_break_up'
                                    : 'daywise_wastage',
                              },
                            });
                          }}
                        >
                          <div className="report-text">See Report</div>
                        </div>
                      </Tooltip> */}
                      <div className="card-section">
                        <div className="card-title-graph">
                          Menu Item Profitability
                          <Tooltip
                            title={
                              <div>
                                <div className="tooltipHead">Graph Formula</div>
                                <div className="tootipText dashboardTooltip">
                                  Menu Item Profitability = Sales - (COGS +
                                  Wastage)
                                </div>
                              </div>
                            }
                          >
                            <ExclamationCircleOutlined className="dashboard-icon" />
                          </Tooltip>
                        </div>
                        <div className="graph-info-div">
                          X-axis: Profit in Month &nbsp;|&nbsp; Y-axis : Menu
                          Item
                        </div>
                      </div>
                      <ChartBubble
                        data={menuItemProfitability}
                        currency={info?.currency}
                      />
                    </Card>
                  </Col>
                </Row>
                {/* Row 3 Menu Management */}
                <Row className="recipe-row">
                  <Col xs={24} sm={12} md={12} lg={12}>
                    <Card className="graph-card menu-row1-card">
                      {/* <Tooltip
                        title={
                          <div>
                            <div className="tooltipHead">See Report</div>
                            <div className="tootipText">
                              Modifiers Performance
                            </div>
                          </div>
                        }
                      >
                        <div
                          className="eye-icon-container"
                          onClick={() => {
                            navigate('/app/reports', {
                              state: {
                                from: 'Dashboard',
                                selectedFilter:
                                  brand_count && brand_count > 1
                                    ? tag === 'shop'
                                      ? 'branch_wastage'
                                      : tag === 'brand'
                                      ? 'brand_wastage'
                                      : 'revenue_break_up'
                                    : 'daywise_wastage',
                              },
                            });
                          }}
                        >
                          <div className="report-text">See Report</div>
                        </div>
                      </Tooltip> */}
                      <div className="card-section">
                        <div className="card-title-graph">
                          Modifiers Performance
                          <Tooltip
                            title={
                              <div>
                                <div className="tooltipHead">Graph Formula</div>
                                <div className="tootipText dashboardTooltip">
                                  Modifiers Performance = Sum of Sales of
                                  Modifiers
                                </div>
                              </div>
                            }
                          >
                            <ExclamationCircleOutlined className="dashboard-icon" />
                          </Tooltip>
                        </div>
                        <div className="graph-info-div">
                          X-axis: Menu Items &nbsp;|&nbsp; Y-axis : Sales Volume
                        </div>
                      </div>
                      <ChartBarHorizontal
                        data={modifierPerformance}
                        source="ModifiersPerformance"
                        currency={info?.currency}
                      />
                    </Card>
                  </Col>
                  <Col xs={24} sm={12} md={12} lg={12}>
                    <Card className="graph-card menu-row1-card">
                      {/* <Tooltip
                        title={
                          <div>
                            <div className="tooltipHead">See Report</div>
                            <div className="tootipText">
                              Food Cost Percentage
                            </div>
                          </div>
                        }
                      >
                        <div
                          className="eye-icon-container"
                          onClick={() => {
                            navigate('/app/reports', {
                              state: {
                                from: 'Dashboard',
                                selectedFilter:
                                  brand_count && brand_count > 1
                                    ? tag === 'shop'
                                      ? 'branch_wastage'
                                      : tag === 'brand'
                                      ? 'brand_wastage'
                                      : 'revenue_break_up'
                                    : 'daywise_wastage',
                              },
                            });
                          }}
                        >
                          <div className="report-text">See Report</div>
                        </div>
                      </Tooltip> */}
                      <div className="card-title-graph">
                        Food Cost Percentage
                        <Tooltip
                          title={
                            <div>
                              <div className="tooltipHead">Graph Formula</div>
                              <div className="tootipText dashboardTooltip">
                                Food Cost Percentage = Category Wise Sum of
                                Sales Item Cost per Item
                              </div>
                            </div>
                          }
                        >
                          <ExclamationCircleOutlined className="dashboard-icon" />
                        </Tooltip>
                      </div>
                      <div className="card-content">
                        <div className="chart-section">
                          <ChartPie
                            data={foodCostPercentage}
                            currency={info?.currency}
                            source="foodCostPercentage"
                          />
                        </div>
                        <div className="table-section custom-table-section">
                          <Table
                            size="small"
                            dataSource={foodCostPercentage.map(
                              (item: any, index: any) => ({
                                key: index,
                                col1: item.name,
                                col2: item.value,
                                col3: `${item.percentage}%`,
                              })
                            )}
                            columns={[
                              {
                                title: 'Food',
                                dataIndex: 'col1',
                                key: 'col1',
                                width: '30%',
                              },
                              {
                                title: `Food Cost (${info?.currency})`,
                                dataIndex: 'col2',
                                key: 'col2',
                                width: '40%',
                              },
                              {
                                title: 'Sales Revenue',
                                dataIndex: 'col3',
                                key: 'col3',
                                width: '40%',
                              },
                            ]}
                            pagination={false}
                            bordered
                            rowClassName={(record, index) =>
                              index === 0 ? 'first-row-highlight' : ''
                            }
                          />
                        </div>
                      </div>
                    </Card>
                  </Col>
                </Row>
              </>
            ) : (
              ''
            )}
          </div>
        </>
      ) : (
        <div className="loader-wrapper">
          <Spin spinning={Loader} className="Loader" />
        </div>
      )}
    </div>
  );
};

export default BranchView;
