/* eslint-disable no-unused-vars */
import React from 'react';
import './styles.scss';
import { Col, Modal, Row, Button, Tooltip } from 'antd';
import { DownloadOutlined } from '@ant-design/icons';
import { Input } from 'antd';
import locompany from '../../assets/images/locompany.png';
import Utils from '../../redux/utils';
import Buttons from '../button';
import { handleFloat, handleFloatForSpecialCase } from '../../helper/sortData';
import * as Filesaver from 'file-saver';
import Excel from 'exceljs';

type Props = {
  open: boolean;
  handleClose: any;
  data: any;
  component: string;
  acceptPO?: Boolean;
  handleAcceptPO?: any;
  handleEditPO?: any;
  handleChangeData?: any;
  handleResponse?: any;
  onSaveClick?: any;
};
const DetailDialog = (props: Props) => {
  let {
    open,
    handleClose,
    data,
    component,
    acceptPO,
    handleAcceptPO,
    handleEditPO,
    handleChangeData,
    handleResponse,
    onSaveClick,
  } = props;

  const exportToExcel = async () => {
    const workbook = new Excel.Workbook();
    const worksheet = workbook.addWorksheet('My Sheet');
    worksheet.getCell('A1').value = 'Brand';
    worksheet.getCell('B1').value = 'LilChick Brand';
    worksheet.getCell('A2').value = 'Created At';
    worksheet.getCell('B2').value = '23-06-2023';
    worksheet.getCell('A3').value = 'Stock Transfer ID';
    worksheet.getCell('B3').value = data?.id;

    worksheet.getRow(5).values = [
      'Ingredient Name',
      'Current Balance',
      'Unit',
      'Transfer Quantity',
    ];
    worksheet.columns = [
      { key: 'ingredient_name', width: 32 },
      { key: 'current_balance', width: 15 },
      { key: 'unit', width: 10 },
      { key: 'updated_quatity', width: 15 },
    ];

    data?.ingredients?.map((ingredient: any) => {
      worksheet.addRow({
        ingredient_name: ingredient?.ingredient_name,
        current_balance: ingredient?.current_balance,
        unit: ingredient?.storage_unit,
        updated_quatity: ingredient?.transfer_quantity || '',
      });
    });

    worksheet.eachRow(function (row, rowNumber) {
      row.eachCell(function (cell: any, colNumber) {
        // console.log(rowNumber, cell['_address'], colNumber);
        worksheet.getCell(cell['_address']).border = {
          top: { style: 'thin', color: { argb: '00000000' } },
          left: { style: 'thin', color: { argb: '00000000' } },
          bottom: { style: 'thin', color: { argb: '00000000' } },
          right: { style: 'thin', color: { argb: '00000000' } },
        };
        worksheet.getCell(cell['_address']).alignment = {
          vertical: 'middle',
          horizontal: 'center',
        };
      });
    });

    workbook.xlsx
      .writeBuffer()
      .then((buffer) =>
        Filesaver.saveAs(new Blob([buffer]), `Stock Taking ${data?.id}.xls`)
      )
      .catch((err) => console.log('Error writing excel export', err));
  };

  const renderSupplier = () => {
    return (
      <ul className="SupplierDetailContainer">
        <li>
          {/* <div className="title">User Role</div> */}
          <div className="content">
            <div className="content-text">
              <p>Supplier Name</p>
              <p>{data?.supplier_name}</p>
            </div>
            <div className="content-text">
              <p>Company</p>
              <p>
                <img src={locompany} alt="Locompany" />
                {data?.contact_name}
              </p>
            </div>
            <div className="content-text">
              <p>Country</p>
              <p>{data?.country_name}</p>
            </div>
            <div className="content-text">
              <p>Email</p>
              <p>{data?.email}</p>
            </div>
            {/* <div className="content-text">
        <p>Designation</p>
        <p>
          {data?.designation
            ?.map((data: any) => data?.designation)
            .toString()}
        </p>
      </div> */}
            <div className="content-text">
              <p>Phone</p>
              <p>{data?.phone}</p>
            </div>
            <div className="content-text">
              <p>Address</p>
              <p>{data?.address}</p>
            </div>
          </div>
        </li>
        {/* <li>
    <div className="title">User Information</div>
    <div className="content">
      <div className="content-text">
        <p>Name</p>
        <p>{data?.name}</p>
      </div>
      <div className="content-text">
        <p>Phone number</p>
        <p>{data?.phone}</p>
      </div>
      <div className="content-text">
        <p>Email</p>
        <p>{data?.email}</p>
      </div>
    </div>
  </li> */}
        {/* <li>
    <div className="title">User Access (Permitted Screen Access)</div>
    <ul className="content">
      <li className="content-list">
        <Row>
          <Col md={8}>
            <p>Module</p>
          </Col>

          <Col md={8} className="LimitedAccessCol supplier-table-TickCrossIcons">
            <p>Full Access</p>
          </Col>
          <Col md={8} className="LimitedAccessCol supplier-table-TickCrossIcons">
            <p>
              Limited Access{' '}
              <div className="readOnlyTextSize">(Read Only)</div>
            </p>
          </Col>
        </Row>
      </li>
      {data.permissions?.map((data: any, index: number) => (
        <li className="content-list" key={index}>
          <Row>
            <Col md={8}>
              <p>{data?.permission}</p>
            </Col>

            <Col md={8} className="supplier-table-TickCrossIcons">
              <p>
                {data?.perm_status_id == 2 ? (
                  data?.perm_create == 0 ? (
                    <img src={tickIcon} alt="" />
                  ) : (
                    <p>
                      <p>
                        <img src={tickIcon} alt="" />
                      </p>
                      <div className="readOnlyFontSize">Read Only</div>
                    </p>
                  )
                ) : (
                  <img src={Subtract} alt="" />
                )}
              </p>
            </Col>
            <Col md={8} className="supplier-table-TickCrossIcons">
              <p>
                {data?.perm_status_id == 1 ? (
                  <img src={tickIcon} alt="" />
                ) : (
                  <img src={Subtract} alt="" />
                )}
              </p>
            </Col>
          </Row>
        </li>
      ))}
    </ul>
  </li> */}
      </ul>
    );
  };

  const renderRequisition = () => {
    return (
      <ul className="SupplierDetailContainer">
        <li>
          {/* <div className="title">User Role</div> */}
          <div className="content">
            <div className="content-text">
              <p className="marginDown">Request Type</p>
              <p>{data?.request_type}</p>
            </div>
            <div className="content-text">
              <p>Request Status</p>
              <p>
                {/* <img src={locompany} alt="Locompany" /> */}
                <div
                // className={`suply-modal-status-container status-container-margin-override ${
                //   data?.request_status?.toLowerCase() == 'pending'
                //     ? ''
                //     : data?.request_status?.toLowerCase() == 'approved'
                //     ? 'inventoryTransfer-request-approve'
                //     : 'inventoryTransfer-request-reject'
                // } `}
                >
                  {data?.request_status}
                </div>
              </p>
            </div>
            <div className="content-text">
              <p>Brand</p>
              <p>{data?.brand_name}</p>
            </div>
            <div className="content-text">
              <p>Branch</p>
              <p>{data?.shop_name}</p>
            </div>
          </div>
        </li>
        <li>
          {/* <div className="title">Ingredients</div> */}
          {/* <div className="content">
      <div className="content-text">
        <p>Ingredient</p>
        <p>{data?.name}</p>
      </div>
      <div className="content-text">
        <p>Request Quantity</p>
        <p>{data?.phone}</p>
      </div>
      <div className="content-text">
        <p>Email</p>
        <p>{data?.email}</p>
      </div>
    </div> */}
        </li>
        <li>
          <div className="title">Ingredients</div>
          <ul className="content">
            <li className="content-list">
              <Row>
                <Col md={6}>
                  <p>Ingredient</p>
                </Col>
                <Col md={6}>
                  <p>Unit</p>
                </Col>
                <Col
                  md={6}
                  className="LimitedAccessCol supplier-table-TickCrossIcons"
                >
                  <p>Request Quantity</p>
                </Col>
                <Col
                  md={6}
                  className="LimitedAccessCol supplier-table-TickCrossIcons"
                >
                  <p>
                    Transfer Quantity
                    {/* <div className="readOnlyTextSize">(Read Only)</div> */}
                  </p>
                </Col>
              </Row>
            </li>
            {data.ingredients?.map((ingredient: any, index: number) => (
              <li className="content-list" key={index}>
                <Row>
                  <Col md={6}>
                    <p>{ingredient?.ingredient_name}</p>
                  </Col>
                  <Col md={6}>
                    <p>{ingredient?.storage_unit}</p>
                  </Col>
                  <Col md={6} className="supplier-table-TickCrossIcons">
                    <p>{ingredient?.request_quantity.toFixed(2)}</p>
                  </Col>
                  <Col md={6} className="supplier-table-TickCrossIcons">
                    <p>{(ingredient?.transfer_quantity ?? 0).toFixed(2)}</p>
                  </Col>
                </Row>
              </li>
            ))}
          </ul>
        </li>
      </ul>
    );
  };

  const renderPurchaseOrder = () => {
    return (
      <ul className="SupplierDetailContainer">
        <li>
          {/* <div className="title">User Role</div> */}
          <div className="content">
            <div className="content-text">
              <p>Request Type</p>
              <p>{data?.request_type}</p>
            </div>
            <div className="content-text">
              <p>Request Status</p>
              <p>
                {/* <img src={locompany} alt="Locompany" /> */}
                <div
                  className={`suply-modal-status-container ${
                    data?.request_status?.toLowerCase() == 'pending'
                      ? 'inventoryTransfer-request-pending'
                      : data?.request_status?.toLowerCase() == 'approved'
                      ? 'inventoryTransfer-request-approve'
                      : 'inventoryTransfer-request-reject'
                  } `}
                >
                  {data?.request_status}
                </div>
              </p>
            </div>
            <div className="content-text">
              <p>Supplier Name</p>
              <p>{data?.supplier_name}</p>
            </div>
            <div className="content-text">
              <p>PO FROM</p>
              <p>{data?.po_from_name}</p>
            </div>
            {data?.request_status?.toLowerCase() == 'pending' && acceptPO ? (
              <div className="stylingAcceptButton edit-po">
                <Button
                  className="theme-button recipeBtn"
                  shape="round"
                  size="middle"
                  onClick={handleEditPO}
                >
                  Edit
                </Button>
                {/* <Button
                  className="theme-button recipeBtn"
                  shape="round"
                  size="middle"
                  onClick={handleAcceptPO}
                >
                  Receive
                </Button> */}
              </div>
            ) : null}
          </div>
        </li>

        <li>
          <div className="title">Ingredients</div>
          <ul className="content">
            <li className="content-list">
              <Row>
                <Col md={6}>
                  <p>Ingredient</p>
                </Col>
                <Col md={6}>
                  <p>Unit</p>
                </Col>
                <Col
                  md={6}
                  className="LimitedAccessCol supplier-table-TickCrossIcons"
                >
                  <p>Quantity</p>
                </Col>
                <Col
                  md={6}
                  className="LimitedAccessCol supplier-table-TickCrossIcons"
                >
                  <p>
                    Unit Cost
                    {/* <div className="readOnlyTextSize">(Read Only)</div> */}
                  </p>
                </Col>
              </Row>
            </li>
            {data.ingredients?.map((ingredient: any, index: number) => (
              <li className="content-list" key={index}>
                <Row>
                  <Col md={6}>
                    <p>{ingredient?.ingredient_name}</p>
                  </Col>
                  <Col md={6}>
                    <p>{ingredient?.storage_unit}</p>
                  </Col>
                  <Col md={6} className="supplier-table-TickCrossIcons">
                    <p>{handleFloat(ingredient?.request_quantity)}</p>
                  </Col>
                  <Col md={6} className="supplier-table-TickCrossIcons">
                    <p>{handleFloat(ingredient?.unit_cost) ?? 0}</p>
                  </Col>
                </Row>
              </li>
            ))}
          </ul>
        </li>
      </ul>
    );
  };

  const renderCashPurchase = () => {
    return (
      <ul className="SupplierDetailContainer">
        <li>
          {/* <div className="title">User Role</div> */}
          <div className="content">
            <div className="content-text">
              <p>Request Type</p>
              <p>{data?.request_type}</p>
            </div>
            {/* <div className="content-text">
              <p>Request Status</p>
              <p>
                 <img src={locompany} alt="Locompany" /> 
                <div
                  className={`suply-modal-status-container ${
                    data?.request_status?.toLowerCase() == 'pending'
                      ? 'inventoryTransfer-request-pending'
                      : data?.request_status?.toLowerCase() == 'approved'
                      ? 'inventoryTransfer-request-approve'
                      : 'inventoryTransfer-request-reject'
                  } `}
                >
                  {data?.request_status}
                </div>
              </p>
            </div> */}
            <div className="content-text">
              <p>Brand</p>
              <p>{Utils.getCurrentBrand()?.brand_name}</p>
            </div>
            <div className="content-text">
              <p>Branch</p>
              <p>{Utils.getCurrentShop()?.shop_name}</p>
            </div>
            <div className="content-text">
              <p>Source</p>
              <p>
                {data?.cp_from
                  ? data.cp_from.charAt(0).toUpperCase() + data.cp_from.slice(1)
                  : null}
              </p>
            </div>
            <div className="content-text">
              <p>Vendor Name</p>
              <p>
                {data?.vendor_name
                  ? data.vendor_name.charAt(0).toUpperCase() +
                    data.vendor_name.slice(1)
                  : null}
              </p>
            </div>
          </div>
        </li>
        <li>
          {/* <div className="title">Ingredients</div> */}
          {/* <div className="content">
      <div className="content-text">
        <p>Ingredient</p>
        <p>{data?.name}</p>
      </div>
      <div className="content-text">
        <p>Request Quantity</p>
        <p>{data?.phone}</p>
      </div>
      <div className="content-text">
        <p>Email</p>
        <p>{data?.email}</p>
      </div>
    </div> */}
        </li>
        <li>
          <div className="title">Ingredients</div>
          <ul className="content">
            <li className="content-list">
              <Row>
                <Col md={6}>
                  <p>Ingredient</p>
                </Col>

                <Col
                  md={6}
                  className="LimitedAccessCol supplier-table-TickCrossIcons"
                >
                  <p>Quantity</p>
                </Col>
                <Col
                  md={6}
                  className="LimitedAccessCol supplier-table-TickCrossIcons"
                >
                  <p>
                    Net Cost
                    {/* <div className="readOnlyTextSize">(Read Only)</div> */}
                  </p>
                </Col>
                <Col
                  md={6}
                  className="LimitedAccessCol supplier-table-TickCrossIcons"
                >
                  <p>
                    Value
                    {/* <div className="readOnlyTextSize">(Read Only)</div> */}
                  </p>
                </Col>
              </Row>
            </li>
            {data.ingredients?.map((ingredient: any, index: number) => (
              <li className="content-list" key={index}>
                <Row>
                  <Col md={6}>
                    <p>{ingredient?.ingredient_name}</p>
                  </Col>

                  <Col md={6} className="supplier-table-TickCrossIcons">
                    <p>{handleFloat(ingredient?.request_quantity)}</p>
                  </Col>
                  <Col md={6} className="supplier-table-TickCrossIcons">
                    <p>{handleFloat(ingredient?.unit_cost) ?? 0}</p>
                  </Col>
                  <Col md={6} className="supplier-table-TickCrossIcons">
                    <p>{handleFloat(ingredient?.total_cost) ?? 0}</p>
                  </Col>
                </Row>
              </li>
            ))}
          </ul>
        </li>
      </ul>
    );
  };

  const renderModifier = () => {
    return (
      <ul className="SupplierDetailContainer">
        <li>
          {/* <div className="title">User Role</div> */}
          <div className="content">
            <div className="content-text mod-text">
              <p>Modifier Group Name</p>
              <p className="mod-name">{data?.modifier_name}</p>
            </div>
            {/* <div className="content-text">
              <p>Request Status</p>
              <p>
                 <img src={locompany} alt="Locompany" /> 
                <div
                  className={`suply-modal-status-container ${
                    data?.request_status?.toLowerCase() == 'pending'
                      ? 'inventoryTransfer-request-pending'
                      : data?.request_status?.toLowerCase() == 'approved'
                      ? 'inventoryTransfer-request-approve'
                      : 'inventoryTransfer-request-reject'
                  } `}
                >
                  {data?.request_status}
                </div>
              </p>
            </div> */}
            {/* <div className="content-text">
              <p className="mod-category">Category</p>
              {data?.menu_category?.map((item: any) => (
                <div key={item?.menu_category}>
                  <p>
                    {item?.menu_category}
                    <span className="modifier-category">
                      <Tooltip
                        title={
                          <div>
                            <div className="tooltipText">Minimum Quantity</div>
                          </div>
                        }
                      >
                        {item?.min_quantity}
                        <sub className="modifier-sub">min</sub>
                      </Tooltip>
                    </span>{' '}
                    <span>
                      <Tooltip
                        title={
                          <div>
                            <div className="tooltipText">Maximum Quantity</div>
                          </div>
                        }
                      >
                        {item?.max_quantity}
                        <sub className="modifier-sub">max</sub>
                      </Tooltip>
                    </span>
                  </p>
                </div>
              ))}
            </div> */}
          </div>
        </li>
        <div className="block3">
          <div className="ingredient mod-dialog">CATEGORY</div>
          <div className="ingredient dialog-ingredient-quantity mod-dialog">
            QUANTITY
          </div>
        </div>
        {data?.menu_category?.map((item: any) => (
          <div className="block4" key={item?.menu_category}>
            <div className={`block4-text font category-text`}>
              {item?.menu_category}
            </div>
            <div className="block4-weight">
              <span className="modifier-category">
                <Tooltip
                  title={
                    <div>
                      <div className="tooltipText">Minimum Quantity</div>
                    </div>
                  }
                >
                  {item?.min_quantity + ' ' + 'min'}
                </Tooltip>
              </span>
              <span className="modifier-max">
                <Tooltip
                  title={
                    <div>
                      <div className="tooltipText">Maximum Quantity</div>
                    </div>
                  }
                >
                  {item?.max_quantity + ' ' + 'max'}
                </Tooltip>
              </span>
            </div>
          </div>
        ))}
        {/* <li>
          <div className="title">Ingredients</div> */}
        {/* <div className="content">
      <div className="content-text">
        <p>Ingredient</p>
        <p>{data?.name}</p>
      </div>
      <div className="content-text">
        <p>Request Quantity</p>
        <p>{data?.phone}</p>
      </div>
      <div className="content-text">
        <p>Email</p>
        <p>{data?.email}</p>
      </div>
    </div> 
        </li> */}
        <li className="ing-table">
          <div className="title mod-ing">Ingredients</div>
          <ul className="content">
            <li className="content-list">
              <Row>
                <Col md={6}>
                  <p>Ingredient</p>
                </Col>

                <Col
                  md={6}
                  className="LimitedAccessCol supplier-table-TickCrossIcons"
                >
                  <p>Quantity</p>
                </Col>
                <Col
                  md={6}
                  className="LimitedAccessCol supplier-table-TickCrossIcons"
                >
                  <p>
                    Unit
                    {/* <div className="readOnlyTextSize">(Read Only)</div> */}
                  </p>
                </Col>
                <Col
                  md={6}
                  className="LimitedAccessCol supplier-table-TickCrossIcons"
                >
                  <p>
                    Menu Price
                    {/* <div className="readOnlyTextSize">(Read Only)</div> */}
                  </p>
                </Col>
                {/* <Col
                  md={4}
                  className="LimitedAccessCol supplier-table-TickCrossIcons"
                >
                  <p>Min</p>
                </Col>
                <Col
                  md={4}
                  className="LimitedAccessCol supplier-table-TickCrossIcons"
                >
                  <p>Max</p>
                </Col> */}
              </Row>
            </li>
            {data.ingredients?.map((ingredient: any, index: number) => (
              <li className="content-list" key={index}>
                <Row>
                  <Col md={6}>
                    <p>{ingredient?.ingredient_name}</p>
                  </Col>

                  <Col md={6} className="supplier-table-TickCrossIcons">
                    <p>{handleFloat(ingredient?.quantity)}</p>
                  </Col>
                  <Col md={6} className="supplier-table-TickCrossIcons">
                    <p>{ingredient?.unit_name}</p>
                  </Col>
                  <Col md={6} className="supplier-table-TickCrossIcons">
                    <p>
                      {handleFloatForSpecialCase(ingredient?.net_price) ?? 0}
                    </p>
                  </Col>
                  {/* <Col md={4} className="supplier-table-TickCrossIcons">
                    <p>{ingredient?.min_quantity ?? 0}</p>
                  </Col>
                  <Col md={4} className="supplier-table-TickCrossIcons">
                    <p>{ingredient?.max_quantity ?? 0}</p>
                  </Col> */}
                </Row>
              </li>
            ))}
          </ul>
        </li>
      </ul>
    );
  };

  const renderStockCount = () => {
    return (
      <ul className="SupplierDetailContainer">
        <li>
          <div className="stock-taking-heading">
            <div className="title">Ingredients</div>
            <div
              onClick={() => exportToExcel()}
              className="stock-taking-export"
            >
              <span>
                <DownloadOutlined />
              </span>
              Count Sheet
            </div>
          </div>
          <ul className="content">
            <li className="content-list-headings">
              <Row>
                <Col md={4}>
                  <p>Ingredient Name</p>
                </Col>

                <Col
                  md={4}
                  className="LimitedAccessCol supplier-table-TickCrossIcons"
                >
                  <p>Current Balance</p>
                </Col>
                <Col
                  md={4}
                  className="LimitedAccessCol supplier-table-TickCrossIcons"
                >
                  <p>
                    Unit
                    {/* <div className="readOnlyTextSize">(Read Only)</div> */}
                  </p>
                </Col>
                <Col
                  md={4}
                  className="LimitedAccessCol supplier-table-TickCrossIcons"
                >
                  <p>
                    Updated Quantity
                    {/* <div className="readOnlyTextSize">(Read Only)</div> */}
                  </p>
                </Col>
                <Col
                  md={4}
                  className="LimitedAccessCol supplier-table-TickCrossIcons"
                >
                  <p>
                    Deficit
                    {/* <div className="readOnlyTextSize">(Read Only)</div> */}
                  </p>
                </Col>
                <Col
                  md={4}
                  className="LimitedAccessCol supplier-table-TickCrossIcons"
                >
                  <p>
                    Cash Value
                    {/* <div className="readOnlyTextSize">(Read Only)</div> */}
                  </p>
                </Col>
              </Row>
            </li>
            {data?.ingredients?.map((ingredient: any, index: number) => (
              <li className="content-list" key={index}>
                <Row>
                  <Col md={4}>
                    <p>{ingredient?.ingredient_name}</p>
                  </Col>

                  <Col md={4} className="supplier-table-TickCrossIcons">
                    <p>
                      {ingredient?.current_balance
                        ? handleFloat(+ingredient?.current_balance)
                        : 0}
                    </p>
                  </Col>
                  <Col md={4} className="supplier-table-TickCrossIcons">
                    <p>{ingredient?.storage_unit}</p>
                  </Col>
                  <Col
                    md={4}
                    className="supplier-table-TickCrossIcons font-input-field"
                  >
                    <p>
                      <Input
                        type="number"
                        placeholder="0"
                        defaultValue={
                          ingredient?.transfer_quantity
                            ? handleFloat(+ingredient?.transfer_quantity)
                            : ingredient?.request_quantity
                        }
                        onChange={(e) => {
                          if (e.target.value.toString().includes('-'))
                            return false;
                          else {
                            if (ingredient?.current_balance >= e.target.value) {
                              handleChangeData(e.target.value, index);
                            }
                          }
                        }}
                      />
                    </p>
                  </Col>
                  <Col md={4} className="supplier-table-TickCrossIcons">
                    <p>{handleFloat(+ingredient?.deficit)}</p>
                  </Col>
                  <Col md={4} className="supplier-table-TickCrossIcons">
                    <p>
                      {ingredient?.cash_impact
                        ? handleFloat(ingredient?.cash_impact)
                        : ingredient?.cash_impact}
                    </p>
                  </Col>
                </Row>
              </li>
            ))}
          </ul>
        </li>
        <div className="stock-count-submit-button">
          <Buttons
            text="Save"
            className="theme-button recipeBtn saveBtn"
            handleButtonClick={() => {
              onSaveClick();
            }}
            shape="round"
            size="middle"
          />
          <Buttons
            text="Submit Count"
            className="theme-button recipeBtn"
            // icon={<PlusOutlined />}
            handleButtonClick={() => {
              // navigate('wizard', { state: { update: false, id: '' } });
              // setCheck(true);
              // handleClose();
              handleResponse(true);
            }}
            shape="round"
            size="middle"
          />
        </div>
      </ul>
    );
  };
  return (
    <div className="DetailDialog">
      <Modal
        title={
          <div className="title font">
            {component == 'supplier'
              ? 'Supplier Detail'
              : component == 'cash_purchase'
              ? 'Cash Purchase Detail'
              : component == 'purchase_order'
              ? 'Purchase Order Detail'
              : component == 'stock_count'
              ? 'Count Stock'
              : component == 'modifier'
              ? 'Modifier Detail'
              : 'Requisition Detail'}
          </div>
        }
        centered
        open={open}
        onCancel={handleClose}
        width={608}
        footer={null}
        className={
          component == 'stock_count'
            ? 'stock-count-modal-container'
            : `DetailDialog ${
                component == 'purchase_order'
                  ? 'supply-purchaseOrder-container'
                  : ''
              }`
        }
      >
        {component == 'supplier'
          ? renderSupplier()
          : component == 'cash_purchase'
          ? renderCashPurchase()
          : component == 'purchase_order'
          ? renderPurchaseOrder()
          : component == 'stock_count'
          ? renderStockCount()
          : component == 'modifier'
          ? renderModifier()
          : renderRequisition()}
      </Modal>
    </div>
  );
};

export default DetailDialog;
