/* eslint-disable no-unused-vars */
import React from 'react';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
} from 'recharts';

interface stackedBarHorizontalProps {
  data: any[];
  source?: string;
  isMulti?: boolean;
  brandcount?: number;
}

const StackedBarHorizontal = (props: stackedBarHorizontalProps) => {
  const { data, source, brandcount } = props;

  function formatNumberWithEllipsis(number: any) {
    const numberStr = number.toString();
    if (numberStr.length > 5) {
      return numberStr.substring(0, 5) + '...';
    }
    return numberStr;
  }

  const chartWidth = 1120; // width changed from 960
  const chartHeight = 290;

  if (!data || data.length === 0) {
    return <div className="no-data-graph">No data to display</div>;
  }

  return (
    <BarChart
      width={chartWidth}
      height={chartHeight}
      data={data}
      layout="horizontal"
      margin={{ top: 20, right: 30, left: 0, bottom: 0 }}
    >
      <XAxis
        dataKey="name"
        type="category"
        // tickFormatter={formatNumberWithEllipsis}
        textAnchor="middle"
        tick={{ fill: '#4B465C', fontSize: 13 }}
      />
      <YAxis
        type="number"
        domain={[0, 100]}
        ticks={[0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100]}
        tickFormatter={(value) => `${value}%`}
        tick={{ fill: '#4B465C' }}
        stroke="transparent"
      />
      <Tooltip formatter={(value: number) => `${value.toFixed(2)}%`} />
      <Legend />
      {/* <Bar dataKey="total" stackId="stack" fill="#F9AB01" name="Utilisation" /> */}
      <Bar dataKey="usage" stackId="stack" fill="#4973A4" name="Usage" />
      <Bar dataKey="wastage" stackId="stack" fill="#F9AB01" name="Wastage" />
    </BarChart>
  );
};

export default StackedBarHorizontal;
