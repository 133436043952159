/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import { Col, Row, DatePicker, Tabs, Select } from 'antd';
import moment, { Moment } from 'moment';
import { RangeValue } from 'rc-picker/lib/interface';
import DashBoardActions from '../../../redux/middleware/dashboard';
import UsersApi from '../../../redux/middleware/users';
import BranchView from './subSections/branchView';
import Utils from '../../../redux/utils';
import { AnyAsyncThunk } from '@reduxjs/toolkit/dist/matchers';

const { RangePicker } = DatePicker;
const { TabPane } = Tabs;

interface Brand {
  id: string;
  brand_id?: string;
  brand_name: string;
  has_shops?: {
    id: any;
    shop_name: string;
  }[];
}

interface Branch {
  id: string;
  shop_id?: string;
  shop_name: string;
  has_brands?: { id: any; brand_name: string }[];
}

interface ShopBrands {
  id?: string;
  brand_id?: string;
  shop_id?: string;
  shop_name?: string;
  brand_name?: string;
  user_brand_status?: boolean;
  user_shop_status?: boolean;
}
interface RenderFilterBarProps {
  activeTab: string;
  onDateRangeChange: (start: Moment, end: Moment) => void;
  brands: Brand[];
  branches: Branch[];
  onBrandChange: (value: string[]) => void;
  onBranchChange: (value: string[]) => void;
  selectedBrands: string[];
  selectedBranches: string[];
  start_date: Moment;
  end_date: Moment;
  tag?: string;
  onTagChange?: any;
}

const RenderFilterBar: React.FC<RenderFilterBarProps> = ({
  activeTab,
  onDateRangeChange,
  brands,
  branches,
  onBrandChange,
  onBranchChange,
  selectedBrands,
  selectedBranches,
  start_date,
  end_date,
  tag,
  onTagChange,
}) => {
  const user = Utils.getCurrentUser();
  const allBrandIDs = brands.map((brand) => brand.id);
  const allBranchIDs = branches.map((branch) => branch.id);

  const isAllSelected = (selectedValues: string[], allValues: string[]) =>
    selectedValues.length === allValues.length;

  const handleRangeChange = (
    values: RangeValue<Moment>,
    formatString: [string, string]
  ) => {
    const start = values?.[0] || moment();
    const end = values?.[1] || moment();
    onDateRangeChange(start, end);
  };

  // const handleTagChange = (value: string) => {
  //   const tag = value;
  //   handleTagChange(tag);
  // };

  const handleTagChange = (value: string) => {
    onTagChange(value);
  };

  const renderBrandSelect = () => {
    const getBrandKeyValue = (brand: any) => {
      return user?.roles[0]?.id === 2 ? brand.id : brand.brand_id;
    };
    return (
      <Select
        size="large"
        placeholder="Brand"
        style={{ width: 150, marginRight: '5px' }}
        mode="multiple"
        onChange={(value) => {
          if (value.includes('ALL')) {
            onBrandChange(allBrandIDs);
          } else {
            onBrandChange(value);
          }
        }}
        value={
          isAllSelected(selectedBrands, allBrandIDs) ? ['ALL'] : selectedBrands
        }
      >
        <Select.Option key="ALL" value="ALL">
          ALL Brands
        </Select.Option>
        {brands.map((brand) => (
          <Select.Option
            key={getBrandKeyValue(brand)}
            value={getBrandKeyValue(brand)}
          >
            {brand.brand_name}
          </Select.Option>
        ))}
      </Select>
    );
  };

  const renderBranchSelect = () => {
    const getBranchKeyValue = (branch: any) => {
      return user?.roles[0]?.id === 2 ? branch.id : branch.shop_id;
    };
    return (
      <Select
        size="large"
        placeholder="Branch"
        style={{ width: 150, marginRight: '5px' }}
        mode="multiple"
        onChange={(value) => {
          if (value.includes('ALL')) {
            onBranchChange(allBranchIDs);
          } else {
            onBranchChange(value);
          }
        }}
        value={
          isAllSelected(selectedBranches, allBranchIDs)
            ? ['ALL']
            : selectedBranches
        }
      >
        <Select.Option key="ALL" value="ALL">
          ALL Branches
        </Select.Option>
        {branches.map((branch) => (
          <Select.Option
            key={getBranchKeyValue(branch)}
            value={getBranchKeyValue(branch)}
          >
            {branch.shop_name}
          </Select.Option>
        ))}
      </Select>
    );
  };

  return (
    <div className="top-bar-container">
      <Row className="dashboard-top-bar">
        <Col lg={16} className="brand-filter-container">
          <Select
            style={{ width: '30%' }}
            placeholder="Select option"
            maxTagCount="responsive"
            value={tag}
            onChange={(value: any) => {
              handleTagChange(value);
            }}
            optionFilterProp="children"
          >
            <Select.Option value="brand">Brand</Select.Option>
            <Select.Option value="shop">Branch</Select.Option>
          </Select>
          {activeTab === '2' ? renderBrandSelect() : renderBranchSelect()}
          {activeTab === '2' ? renderBranchSelect() : renderBrandSelect()}
        </Col>
        <Col lg={8} className="range-picker">
          <RangePicker
            ranges={{
              Today: [moment(), moment()],
              'This Month': [
                moment().startOf('month'),
                moment().endOf('month'),
              ],
            }}
            style={{ width: 350, borderRadius: '10px', height: '40px' }}
            format="YYYY/MM/DD"
            defaultValue={[start_date, end_date]}
            onChange={handleRangeChange}
            className="dashboardDatePicker"
          />
        </Col>
      </Row>
    </div>
  );
};

const DashboardTabs: React.FC = () => {
  const user = Utils.getCurrentUser();
  const company = Utils.getCurrentCompany();
  const [activeTab, setActiveTab] = useState('2');
  const startOfMonth = moment().startOf('month');
  const endOfMonth = moment().endOf('month');
  const [start_date, setStart_date] = useState<Moment>(startOfMonth);
  const [end_date, setEnd_date] = useState<Moment>(endOfMonth);
  const [tag, setTag] = useState<any>('brand');
  const [brands, setBrands] = useState<Brand[]>([]);
  const [branches, setBranches] = useState<Branch[]>([]);
  const [shopBrands, setShopBrands] = useState<ShopBrands[]>([]);
  const [selectedBrands, setSelectedBrands] = useState<string[]>(['ALL']);
  const [selectedBranches, setSelectedBranches] = useState<string[]>(['ALL']);

  const getBrandBranch = async () => {
    try {
      const response = await UsersApi.getShopHasBrands(
        selectedBrands[0] !== 'ALL' ? selectedBrands : allBrandIDs
      );
      if (response?.success) {
        const { data } = response;
        setShopBrands(data);
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const fetchCompanyDetails = async () => {
    const response = await DashBoardActions.fetchCompanyDetails();
    if (response?.success) {
      const data = response.data;
      setBrands(data?.brands || []);
      setBranches(data?.shops || []);
    }
  };

  useEffect(() => {
    setSelectedBrands(['ALL']);
    setSelectedBranches(['ALL']);
    if (user?.roles[0]?.id === 2) {
      fetchCompanyDetails();
    } else {
      setBrands(company?.brands);
      setBranches(company?.shops);
      getBrandBranch();
    }
  }, [activeTab]);

  const allBrandIDs =
    user?.roles[0]?.id === 2
      ? brands.map((brand) => brand?.id)
      : company?.brands.map((brand: any) => brand?.brand_id);

  const allBranchIDs =
    user?.roles[0]?.id === 2
      ? branches.map((branch) => branch?.id)
      : company?.shops.map((shop: any) => shop?.shop_id);

  const handleDateRangeChange = (start: Moment, end: Moment) => {
    setStart_date(start);
    setEnd_date(end);
  };

  const handleTagChange = (tag: string) => {
    setTag(tag);
  };

  const getBrandIdentifier = (brand: any) => {
    return user?.roles[0]?.id === 2 ? brand.id : brand.brand_id;
  };

  const getShopIdentifier = (shop: any) => {
    return user?.roles[0]?.id === 2 ? shop.id : shop.shop_id;
  };

  const handleBrandChange = (brandIDs: string[]) => {
    setSelectedBrands(brandIDs.includes('ALL') ? ['ALL'] : brandIDs);
    let filteredShops: { id: string; shop_name: string }[] = [];
    if (brandIDs.includes('ALL')) {
      const allBranches = branches.map((branch) => ({
        id: branch.id || '',
        shop_name: branch.shop_name || '',
      }));
      filteredShops = [{ id: 'ALL', shop_name: 'All Shops' }, ...allBranches];
    } else {
      const matchingBrands = brands.filter((brand) =>
        brandIDs.includes(getBrandIdentifier(brand))
      );
      const allShops = matchingBrands.flatMap((brand) =>
        Array.isArray(brand?.has_shops) ? brand.has_shops : []
      );
      const uniqueShopIDs = new Set(allShops.map((shop) => shop.id));
      if (user?.roles[0]?.id === 2) {
        filteredShops = Array.from(uniqueShopIDs).map((shopId) => {
          const shop = allShops.find((s) => s.id === shopId);
          return {
            id: shop?.id || `unknown-${shopId}`,
            shop_name: shop?.shop_name || '',
          };
        });
      } else {
        const uniqueShopIDs = new Set<string>();
        filteredShops = shopBrands
          .map((shopBrand) => {
            const matchingBrand = matchingBrands.find(
              (brand) => brand.brand_id === shopBrand.brand_id
            );
            if (
              matchingBrand &&
              shopBrand.shop_id &&
              shopBrand.user_brand_status === true &&
              shopBrand.user_shop_status === true
            ) {
              if (!uniqueShopIDs.has(shopBrand.shop_id)) {
                uniqueShopIDs.add(shopBrand.shop_id);
                return {
                  id: shopBrand.shop_id,
                  shop_name: shopBrand.shop_name || '',
                };
              }
            }
            return null;
          })
          .filter(
            (shop): shop is { id: string; shop_name: string } => shop !== null
          );
      }
    }
    setBranches(filteredShops);
  };

  const handleBranchChange = (branchIDs: string[]) => {
    setSelectedBranches(branchIDs.includes('ALL') ? ['ALL'] : branchIDs);
    let filteredBrands: Brand[] = [];
    if (branchIDs.includes('ALL')) {
      const allBrands = brands.map((brand) => ({
        id: brand.id,
        brand_name: brand.brand_name,
      }));
      filteredBrands = [{ id: 'ALL', brand_name: 'All Brands' }, ...allBrands];
    } else {
      const matchingBranches = branches.filter((shop) =>
        branchIDs.includes(getShopIdentifier(shop))
      );
      const allBrands = matchingBranches.flatMap((shop) =>
        Array.isArray(shop?.has_brands) ? shop.has_brands : []
      );
      const uniqueBrandIDs = new Set(allBrands.map((brand: any) => brand.id));
      if (user?.roles[0]?.id === 2) {
        filteredBrands = Array.from(uniqueBrandIDs).map((brandId) => {
          const brand = allBrands.find((s: any) => s.id === brandId);
          return {
            id: brand?.id || `unknown-${brandId}`,
            brand_name: brand?.brand_name || '',
          };
        });
      } else {
        const uniqueBrandIDs = new Set<string>();
        filteredBrands = shopBrands
          .map((shopBrand) => {
            const matchingBranch = matchingBranches.find(
              (shop) => shop.shop_id === shopBrand.shop_id
            );
            if (matchingBranch && shopBrand.id) {
              if (!uniqueBrandIDs.has(shopBrand.id)) {
                uniqueBrandIDs.add(shopBrand.id);
                return {
                  id: shopBrand.brand_id,
                  brand_name: shopBrand.brand_name || '',
                };
              }
            }
            return null;
          })
          .filter(
            (shop): shop is { id: string; brand_name: string } => shop !== null
          );
      }
      setBrands(filteredBrands);
    }
  };

  const resolvedBrandIDs = selectedBrands.includes('ALL')
    ? allBrandIDs
    : selectedBrands;

  const resolvedBranchIDs = selectedBranches.includes('ALL')
    ? allBranchIDs
    : selectedBranches;

  return (
    <Tabs defaultActiveKey="2" onChange={(key) => setActiveTab(key)}>
      {/* <TabPane key="2" tab="Brand">
        <RenderFilterBar
          activeTab={activeTab}
          onDateRangeChange={handleDateRangeChange}
          brands={brands}
          branches={branches}
          onBrandChange={handleBrandChange}
          onBranchChange={handleBranchChange}
          selectedBrands={selectedBrands}
          selectedBranches={selectedBranches}
          start_date={start_date}
          end_date={end_date}
        />
        <BranchView
          start_date={start_date}
          end_date={end_date}
          activeTab={activeTab}
          brand_count={brands?.length}
          selectedBrands={resolvedBrandIDs}
          selectedBranches={resolvedBranchIDs}
        />
      </TabPane>
      <TabPane key="3" tab="Branch">
        <RenderFilterBar
          activeTab={activeTab}
          onDateRangeChange={handleDateRangeChange}
          brands={brands}
          branches={branches}
          onBrandChange={handleBrandChange}
          onBranchChange={handleBranchChange}
          selectedBrands={selectedBrands}
          selectedBranches={selectedBranches}
          start_date={start_date}
          end_date={end_date}
        />
        <BranchView
          start_date={start_date}
          end_date={end_date}
          activeTab={activeTab}
          brand_count={brands?.length}
          selectedBrands={resolvedBrandIDs}
          selectedBranches={resolvedBranchIDs}
        />
      </TabPane> */}
      <TabPane key="2" tab="Inventory">
        <RenderFilterBar
          activeTab={activeTab}
          onDateRangeChange={handleDateRangeChange}
          brands={brands}
          branches={branches}
          onBrandChange={handleBrandChange}
          onBranchChange={handleBranchChange}
          selectedBrands={selectedBrands}
          selectedBranches={selectedBranches}
          start_date={start_date}
          end_date={end_date}
          tag={tag}
          onTagChange={handleTagChange}
        />
        <BranchView
          start_date={start_date}
          end_date={end_date}
          activeTab={activeTab}
          brand_count={brands?.length}
          selectedBrands={resolvedBrandIDs}
          selectedBranches={resolvedBranchIDs}
          tag={tag}
        />
      </TabPane>
      <TabPane key="3" tab="Recipe">
        <RenderFilterBar
          activeTab={activeTab}
          onDateRangeChange={handleDateRangeChange}
          brands={brands}
          branches={branches}
          onBrandChange={handleBrandChange}
          onBranchChange={handleBranchChange}
          selectedBrands={selectedBrands}
          selectedBranches={selectedBranches}
          start_date={start_date}
          end_date={end_date}
          tag={tag}
          onTagChange={handleTagChange}
        />
        <BranchView
          start_date={start_date}
          end_date={end_date}
          activeTab={activeTab}
          brand_count={brands?.length}
          selectedBrands={resolvedBrandIDs}
          selectedBranches={resolvedBranchIDs}
          tag={tag}
        />
      </TabPane>
      <TabPane key="4" tab="Menu Management">
        <RenderFilterBar
          activeTab={activeTab}
          onDateRangeChange={handleDateRangeChange}
          brands={brands}
          branches={branches}
          onBrandChange={handleBrandChange}
          onBranchChange={handleBranchChange}
          selectedBrands={selectedBrands}
          selectedBranches={selectedBranches}
          start_date={start_date}
          end_date={end_date}
          tag={tag}
          onTagChange={handleTagChange}
        />
        <BranchView
          start_date={start_date}
          end_date={end_date}
          activeTab={activeTab}
          brand_count={brands?.length}
          selectedBrands={resolvedBrandIDs}
          selectedBranches={resolvedBranchIDs}
          tag={tag}
        />
      </TabPane>
    </Tabs>
  );
};

export default DashboardTabs;
