/* eslint-disable no-unused-vars */
import './theme';
import ReactDOM from 'react-dom';
import { store } from './redux/store';
import { Provider } from 'react-redux';
import App from './routes';
import { I18nextProvider } from 'react-i18next';
import i18n from './i18n/i18n';
import GlobalKeyHandler from '../src/components/GlobalKeyHandler';
import posthog from 'posthog-js';
import { PostHogProvider } from 'posthog-js/react';
// import HelpFloatingIcon from '../src/components/HelpIcon';

posthog.init('phc_5EDycfWjS5mxsA0JlwcAj2jB8jmgAx1B6xwPZA0SZA6', {
  api_host: 'https://us.i.posthog.com',
  capture_pageview: false,
  person_profiles: 'identified_only',
});

// eslint-disable-next-line react/no-deprecated
ReactDOM.render(
  <I18nextProvider i18n={i18n}>
    <Provider store={store}>
      <GlobalKeyHandler>
        <PostHogProvider client={posthog}>
          <App />
          {/* <HelpFloatingIcon /> */}
        </PostHogProvider>
      </GlobalKeyHandler>
    </Provider>
  </I18nextProvider>,
  document.getElementById('root')
);
