/* eslint-disable no-unused-vars */
import React from 'react';
import { Layout } from 'antd';
import SideBar from './sidebar';
import TopHeader from './header';
import './sidebar/adminStyle.scss';
// import SubtractIcon from '../assets/images/subtractIcon.png';
// import { FeedBackDialog } from '../components';
// import feedback from '../pages/feedback';
import HelpFloatingIcon from '../components/HelpIcon';

type Props = {
  children: React.ReactNode;
  containerClassName: string;
};

const AdminLayout = (props: Props) => {
  const { children } = props;
  const { Sider, Content } = Layout;
  // const [feedback, setFeedBack] = useState(false);
  // const feedbackBtn = () => {
  //   setFeedBack(!feedback);
  // };
  return (
    <Layout>
      <Sider>
        <SideBar />
      </Sider>
      <Layout style={{ position: 'relative', overflow: 'hidden' }}>
        <TopHeader />
        {/* <FeedBackDialog /> */}
        <Content> {children}</Content>
        {/* <div className="help-icon">
          <HelpFloatingIcon />
        </div> */}
      </Layout>
    </Layout>
  );
};

export default AdminLayout;
