/* eslint-disable no-unused-vars */
import React from 'react';
import { Progress, Row, Col } from 'antd';

interface progressBarProps {
  data: any;
  source?: string;
  brandcount?: number;
}

function ProgressBar(props: progressBarProps) {
  const { data, source, brandcount } = props;

  return data ? (
    <Row justify="center" align="middle">
      <Col>
        <div style={{ textAlign: 'center' }}>
          <Progress
            width={225}
            type="dashboard"
            percent={data?.wastePercentage + data?.inventoryPercentage}
            strokeColor="#F8F7FA"
            success={{
              percent: data?.wastePercentage,
              strokeColor: '#F9AB01',
            }}
            format={() => (
              <>
                <div className="wastage-progress">{'Total Wastage'}</div>
                <div className="wastage-percentage">{`${data?.wastePercentage}%`}</div>
              </>
            )}
          />
          <div
            className="wastage-progress-tags"
            style={{
              marginTop: 6,
              display: 'flex',
              justifyContent: 'center',
              gap: 12,
            }}
          >
            <div style={{ display: 'flex', alignItems: 'center', gap: 4 }}>
              <span className="wastage-dots"></span>
              <span className="wastage-tooltip">{`Wastage: ${data?.wastePercentage}%`}</span>
            </div>
            <div style={{ display: 'flex', alignItems: 'center', gap: 4 }}>
              <span className="wastage-dots dot2"></span>
              <span className="wastage-tooltip">{`Inventory: ${data?.inventoryPercentage}%`}</span>
            </div>
          </div>
        </div>
      </Col>
    </Row>
  ) : (
    <div className="no-data-graph">No data to display</div>
  );
}

export default ProgressBar;
